import { useSelector } from 'react-redux';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { useEffect } from 'react';
import { getOrganizationUnitsAsyncThunk } from '../slice/thunks';
import { selectUserManagementState } from '../slice/selectors';
import { useDismissibleDialog } from 'pl.curulis/modules/DismissibleDialog';
import { selectAuthenticatedUser } from '../../../Authentication';

export const useOrganizationUnitsFetch = () => {
  const { organizationUnitsStatus } = useSelector(selectUserManagementState);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const { openDialog } = useDismissibleDialog();
  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationUnitsStatus === 'idle') {
      dispatch(getOrganizationUnitsAsyncThunk(authenticatedUser?.organizationGuid ?? ''));
    } else if (organizationUnitsStatus === 'error') {
      openDialog({ title: 'Nie udało się pobrać listy jednostek organizacyjnych.' });
    }
  }, [dispatch, openDialog, authenticatedUser, organizationUnitsStatus]);
};
