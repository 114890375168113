import { ApiError } from './ApiError';

const handleError = (error: any): ApiError => {
  const customError = {
    status: null,
    type: null,
    message: 'Wystąpił nieznany błąd, spróbuj odświeżyć stronę.',
  };

  if (error.response?.status) {
    customError.status = error.response.status;
  }

  const errorHasData = error.response && error.response.data;
  if (errorHasData) {
    const { data } = error.response;
    const dataIsErrorMessage = typeof data.title === 'string' || data.title instanceof String;
    const dataIsType = typeof data.type === 'string' || data.type instanceof String;
    if (dataIsErrorMessage) {
      customError.message = data.title;
    }
    if (dataIsType) {
      customError.type = data.type;
    }
  }

  return customError;
};

export { handleError };
