import { FC } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useAuthMethods } from '../utils/useAuthMethods';

type SessionTokenRevokedDialogProps = {
  open: boolean;
};

export const SessionTokenRevokedDialog: FC<SessionTokenRevokedDialogProps> = ({ open }) => {
  const { logout } = useAuthMethods();

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '300px',
          height: '200px',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: '25px',
          paddingBottom: '25px',
        },
      }}
    >
      <DialogTitle textAlign="center">Twoja sesja wygasła</DialogTitle>
      <DialogActions>
        <Button variant="contained" onClick={logout}>
          Zaloguj się ponownie
        </Button>
      </DialogActions>
    </Dialog>
  );
};
