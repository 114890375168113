import { PublinkPalette, TieredColorPalette } from './types';

const NEUTRAL = {
  0: '#FFFFFF',
  100: '#E1ECFF',
  200: '#D2DCEE',
  300: '#AAB5D1',
  400: '#838EB5',
  500: '#5B6698',
  600: '#343F7C',
  700: '#1A2569',
  800: '#141C4F',
  900: '#0D1336',
};

const PRIMARY: TieredColorPalette = {
  lighter: '#E1ECFF',
  light: '#AAB5D1',
  main: '#5B6698',
  dark: '#1A2569',
  darker: '#0D1336',
};

const SECONDARY: TieredColorPalette = {
  lighter: '#94E7FF',
  light: '#22CEFF',
  main: '#21B9E5',
  dark: '#1DA5CC',
  darker: '#188AAB',
};

// For Error and Success, there are no separate .darker and .lighter colors. For Warning there is no .darker.
// In order for app to work correctly, they repeat .dark and .light colors, but should
// be removed in later stages.
const WARNING: TieredColorPalette = {
  lighter: '#F6F4E6',
  light: '#FFF0A5',
  main: '#FAD82E',
  dark: '#ECC500',
  darker: '#CFAC00',
};

const ERROR: TieredColorPalette = {
  lighter: '#FFB1B5',
  light: '#FF7880',
  main: '#FB414C',
  dark: '#D62C36',
  darker: '#B51620',
};

const SUCCESS: TieredColorPalette = {
  lighter: '#96FBE4',
  light: '#0ADEAD',
  main: '#00B087',
  dark: '#18846B',
  darker: '#0A6550',
};

export const publinkPalette: PublinkPalette = {
  mode: 'light',
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: NEUTRAL[0] },
  secondary: { ...SECONDARY, contrastText: NEUTRAL[0] },
  info: { ...SECONDARY, contrastText: NEUTRAL[0] },
  success: { ...SUCCESS, contrastText: PRIMARY.dark },
  warning: { ...WARNING, contrastText: PRIMARY.dark },
  error: { ...ERROR, contrastText: NEUTRAL[0] },
  statusUnknown: {
    lighter: NEUTRAL[100],
    light: NEUTRAL[200],
    main: NEUTRAL[300],
    dark: NEUTRAL[500],
    darker: NEUTRAL[600],
    contrastText: PRIMARY.dark,
  },
  shadowBase: NEUTRAL[500],
  grey: NEUTRAL,
  divider: NEUTRAL[100],
  action: {
    active: NEUTRAL[500],
    hover: NEUTRAL[300],
    selected: NEUTRAL[200],
    disabled: NEUTRAL[300],
    disabledBackground: NEUTRAL[200],
    focus: NEUTRAL[200],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  tag: {
    variant1: {
      background: NEUTRAL[100],
      text: NEUTRAL[700],
    },
    variant2: {
      background: SECONDARY.darker,
      text: NEUTRAL[0],
    },
    variant3: {
      background: WARNING.light,
      text: PRIMARY.dark,
    },
  },
  text: {
    primary: NEUTRAL[800],
    secondary: NEUTRAL[700],
    disabled: NEUTRAL[400],
    mediumContrast: NEUTRAL[500],
    strongContrast: NEUTRAL[200],
    fullContrast: '#fff',
  },
  background: {
    paper: NEUTRAL[0],
    default: NEUTRAL[0],
    light: NEUTRAL[100],
    neutral: NEUTRAL[200],
    mediumContrast: NEUTRAL[400],
    strongContrast: NEUTRAL[800],
    fullContrast: '#000',
  },
  border: {
    lighter: NEUTRAL[200],
    light: NEUTRAL[300],
    medium: NEUTRAL[400],
    strong: NEUTRAL[500],
    stronger: NEUTRAL[600],
    strongest: NEUTRAL[700],
  },
};
