import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

type UseBlockNavigationParams = {
  shouldBlock: boolean;
  onBlock?: () => unknown;
};

export const useBlockNavigation = ({ shouldBlock, onBlock }: UseBlockNavigationParams) => {
  const { state, proceed, reset } = useBlocker(shouldBlock);

  useEffect(() => {
    if (state === 'blocked') {
      onBlock?.();
    }
  }, [reset, state, onBlock]);

  return { proceed, reset };
};
