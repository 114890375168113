const validateNip = (nip: string): boolean => {
  const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];

  if (nip && nip.length === 10) {
    const sum = weights.reduce(
      (accumulator, currentValue, currentIndex) =>
        accumulator + currentValue * Number(nip.charAt(currentIndex)),
      0
    );

    return sum % 11 === Number(nip.charAt(9));
  }

  return false;
};

export { validateNip };
