import { bolek } from 'pl.curulis/modules/Axios';

type RenameOrgUnitParams = {
  organizationConstId: string;
  constId: string;
  newName: string;
  newShortName?: string;
};

export const renameUnitDepartment = ({
  organizationConstId,
  newName,
  newShortName,
  constId,
}: RenameOrgUnitParams) =>
  bolek.patch(`Units/${organizationConstId}/${constId}`, {
    newName,
    newShortName,
  });
