import { ProductCard } from './ProductCard';
import { SubscriptionSection } from './SubscriptionSection';
import { FC } from 'react';
import { Stack } from '@mui/material';
import { AgreementsSection } from './AgreementsSections';
import { ProductSubscriptionModel } from 'pl.curulis/models/Product/src/ProductSubscriptionModel';
import { Product } from 'pl.curulis/models/Product/src/enums/Product';

type AgreementsProductCardProps = {
  product: Product;
} & {
  subscription: NonNullable<ProductSubscriptionModel['subscription']>;
};

export const AgreementsProductCard: FC<AgreementsProductCardProps> = ({
  product,
  subscription,
}) => {
  const { subscriptionId, planId, start } = subscription;

  return (
    <ProductCard product={product}>
      <Stack gap={2}>
        <SubscriptionSection product={product} shouldShowNextBillingDate={true} />
        <AgreementsSection
          product={product}
          planId={planId}
          start={start}
          subscriptionId={subscriptionId}
        />
      </Stack>
    </ProductCard>
  );
};
