import { CampaignData } from 'pl.curulis/models/CampaignData';
import { useQuery } from '../../../utils/useQuery';

const useCampaignDataFromUrl = () => {
  const query = useQuery();
  const medium = query.get('utm_medium');
  const source = query.get('utm_source');

  return (!!medium && !!source ? { medium, source } : null) as CampaignData;
};

export { useCampaignDataFromUrl };
