import { GridColDef } from '@mui/x-data-grid-premium';
import { OrgUnit } from 'pl.curulis/models/OrganizationUnit';
import { DepartmentTypeCell } from 'pl.curulis/modules/UserManagement/src/components/cells/organizationsTable/DepartmentTypeCell';
import { DepartmentsDisplayCell } from 'pl.curulis/modules/UserManagement/src/components/cells/organizationsTable/DepartmentsDisplayCell';
import { DepartmentsEditCell } from 'pl.curulis/modules/UserManagement/src/components/cells/organizationsTable/DepartmentsEditCell';

type UseOrganizationUnitsColumnsParams = {
  handleRenameOrg: (originalOrg: OrgUnit, updatedOrg: OrgUnit) => Promise<void>;
  editedOrganization: OrgUnit | null;
  setEditedOrganization: React.Dispatch<React.SetStateAction<OrgUnit | null>>;
};

export const useOrganizationUnitsColumns = ({
  handleRenameOrg,
  editedOrganization,
  setEditedOrganization,
}: UseOrganizationUnitsColumnsParams): GridColDef<OrgUnit>[] => [
  {
    flex: 1,
    field: 'type',
    headerName: 'Typ',
    maxWidth: 350,
    sortable: true,
    renderCell: (props) => <DepartmentTypeCell {...props} />,
  },
  {
    flex: 1,
    field: 'name',
    headerName: 'Nazwa',
    sortable: true,
    minWidth: 100,
    renderCell: (props) => (
      <DepartmentsDisplayCell {...props} setEditedOrganization={setEditedOrganization} />
    ),
    renderEditCell: (props) => (
      <DepartmentsEditCell
        {...props}
        handleRenameOrg={handleRenameOrg}
        setEditedOrganization={setEditedOrganization}
        editedOrganization={editedOrganization}
      />
    ),
    editable: true,
  },
];
