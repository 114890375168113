import { Product } from 'pl.curulis/models/enums/Product';
import { checkIsAgreement } from '../api/checkIsAgreement';
import { useEffect, useState, useCallback } from 'react';
import { ProductSubscriptionModel } from 'pl.curulis/models/Product/src/ProductSubscriptionModel';

const NOVEMBER_THIRTEEN_2022 = new Date(2022, 10, 13);
const AGREEMENT_CHANGE_DATE = NOVEMBER_THIRTEEN_2022;

const generateTemplateUrl = (productFamily: Product) =>
  `https://dokumenty.publink.com/${productFamily}_wzor.docx`;

const generateAgreementUrl = (productFamily: Product, subscriptionId: string) =>
  `https://dokumenty.publink.com/${productFamily}/umowa_${subscriptionId}.pdf`;
type useGetAgreementDownloadUrlProps = {
  product: ProductSubscriptionModel['product'];
} & Pick<
  NonNullable<ProductSubscriptionModel['subscription']>,
  'subscriptionId' | 'planId' | 'start'
>;

export const useGetAgreementDownloadUrl = ({
  product,
  subscriptionId,
  planId,
  start,
}: useGetAgreementDownloadUrlProps) => {
  const [existsAgreement, setExistsAgreement] = useState(false);

  const licenseStartDate = new Date(start);
  const isTrial =
    planId === 'trial-14dni' || planId === 'best-trial-14dni' || planId === 'swb-trial';
  const appliesTemplateAgreement = AGREEMENT_CHANGE_DATE > licenseStartDate || isTrial;

  const executeCheckIsAgreement = useCallback(
    async (subscriptionId: string) => {
      try {
        await checkIsAgreement(product, subscriptionId);
        setExistsAgreement(true);
      } catch {
        setExistsAgreement(false);
      }
    },
    [product]
  );

  useEffect(() => {
    executeCheckIsAgreement(subscriptionId);
  }, [executeCheckIsAgreement, subscriptionId]);

  if (appliesTemplateAgreement) {
    return generateTemplateUrl(product);
  } else {
    return existsAgreement
      ? generateAgreementUrl(product, subscriptionId)
      : generateTemplateUrl(product);
  }
};
