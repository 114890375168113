import { useEffect, useRef, useState } from 'react';
import { HubspotChatApi } from '../models/HubspotChatApi';

declare global {
  interface Window {
    HubSpotConversations: HubspotChatApi;
    hsConversationsOnReady: unknown[];
  }
}

const HUBSPOT_ID = 'hubspot-messages-iframe-container';

const setDisplay = (el: HTMLElement, value: string) =>
  el.style.setProperty('display', value, 'important');

export const useHubspotChat = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const elementRef = useRef<HTMLElement>();

  const loadWidget = () => window.HubSpotConversations.widget.load();

  const openChat = () => {
    window.HubSpotConversations.widget.open();
    if (!elementRef.current) return;
    setDisplay(elementRef.current, 'block');
    setIsChatOpen(true);
  };

  const closeChat = () => {
    window.HubSpotConversations.widget.close();
    if (!elementRef.current) return;
    setDisplay(elementRef.current, 'none');
    setIsChatOpen(false);
  };

  const toggleChat = () => {
    const status = window.HubSpotConversations?.widget.status();
    if (status?.loaded) {
      isChatOpen ? closeChat() : openChat();
    }
  };

  useEffect(() => {
    const observer = new MutationObserver((mutationsList, observer) => {
      mutationsList.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof HTMLElement && node.id === HUBSPOT_ID) {
            elementRef.current = node;
            setDisplay(node, 'none');
            observer.disconnect();
          }
        });
      });
    });

    observer.observe(document.body, { childList: true });

    return () => observer.disconnect();
  });

  useEffect(() => {
    if (window.HubSpotConversations) {
      loadWidget();
    } else {
      window.hsConversationsOnReady = [loadWidget];
    }
  }, []);

  useEffect(() => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.on('widgetClosed', closeChat);

      return () => window.HubSpotConversations.off('widgetClosed', closeChat);
    }
  }, [isChatOpen]);

  return { toggleChat };
};
