import { ProductCard, ProductCardProps } from './ProductCard';
import { SubscriptionSection } from './SubscriptionSection';
import { FC } from 'react';
import { Stack, Typography } from '@mui/material';

type WelcomeProductCardProps = Pick<ProductCardProps, 'product'>;

const welcomeTexts = { 
  best: 'Uporządkuje Twoją pracę nad budżetem. Dzięki niej oszczędzisz czas i nerwy.',
  swb: 'Wygeneruje dla Ciebie sprawozdania opisowe z wykonania budżetu w kilka minut.',
  wpf: 'Przygotuje dla Ciebie wszystkie dokumenty Wieloletniej Prognozy Finansowej.',
  plikomat: 'Zsynchronizuje wszystkie Twoje dane z Bestii.',
  umowy: 'Zorganizuj umowy, aneksy i załączniki w jednym, czytelnym rejestrze. Zachowaj porządek i pracuj wygodniej.'
}


export const WelcomeProductCard: FC<WelcomeProductCardProps> = ({ product }) => (
    <ProductCard product={product}>
      <Stack gap={2}>
        <Typography variant="body2">{welcomeTexts[product]}</Typography>
        <SubscriptionSection product={product} />
      </Stack>
    </ProductCard>
  );
