import { createSlice } from '@reduxjs/toolkit';
import {
  getCustomerAddressesAsyncThunk,
  getDocumentSettingsAsyncThunk,
  getNotificationsAsyncThunk,
  getUnitDeclensionAsyncThunk,
  postDocumentSettingsAsyncThunk,
  postNotificationsAsyncThunk,
  postUnitDeclensionAsyncThunk,
  putCustomerAddressesAsyncThunk,
} from './thunks';
import {
  CustomerAddresses,
  DocumentSettings,
  NotificationsModel,
  UnitDeclension,
} from '../../models';
import { RequestStatus } from 'pl.curulis/utils/api/requestStatus';
import { nullAddressObject, nullBillingObject } from 'pl.curulis/models/Address/src/AddressModel';

export type SettingsState = {
  unitDeclension: UnitDeclension;
  unitDeclensionFetchStatus: RequestStatus;
  documentSettings: DocumentSettings;
  documentSettingsFetchStatus: RequestStatus;
  customerAddresses: CustomerAddresses;
  customerAddressesFetchStatus: RequestStatus;
  notifications: NotificationsModel;
  notificationsFetchStatus: RequestStatus;
};

export const initialState: SettingsState = {
  unitDeclension: {},
  unitDeclensionFetchStatus: 'idle',
  documentSettings: {
    tableTheme: 'Default',
  },
  documentSettingsFetchStatus: 'idle',
  customerAddresses: { billing: nullBillingObject, shipping: nullAddressObject },
  customerAddressesFetchStatus: 'idle',
  notifications: {
    bestEngagementNotificationsEnabled: false,
    bestFinancialPlansNotificationsEnabled: false,
  },
  notificationsFetchStatus: 'idle',
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    invalidateUnitDeclensionFetch: (state) => {
      state.unitDeclensionFetchStatus = 'idle';
    },
    invalidateDocumentSettingsFetch: (state) => {
      state.documentSettingsFetchStatus = 'idle';
    },
    invalidateCustomerAddresses: (state) => {
      state.customerAddressesFetchStatus = 'idle';
    },
    invalidateNotificationsFetch: (state) => {
      state.notificationsFetchStatus = 'idle';
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getUnitDeclensionAsyncThunk.pending, (state) => {
        state.unitDeclensionFetchStatus = 'loading';
      })
      .addCase(getUnitDeclensionAsyncThunk.fulfilled, (state, action) => {
        state.unitDeclension = action.payload;
        state.unitDeclensionFetchStatus = 'success';
      })
      .addCase(getUnitDeclensionAsyncThunk.rejected, (state) => {
        state.unitDeclensionFetchStatus = 'error';
      })
      .addCase(postUnitDeclensionAsyncThunk.pending, (state, action) => {
        state.unitDeclension = action.meta.arg;
      })
      .addCase(postUnitDeclensionAsyncThunk.rejected, (state) => {
        state.unitDeclensionFetchStatus = 'idle';
      })
      .addCase(getDocumentSettingsAsyncThunk.pending, (state) => {
        state.documentSettingsFetchStatus = 'loading';
      })
      .addCase(getDocumentSettingsAsyncThunk.fulfilled, (state, action) => {
        state.documentSettings = action.payload;
        state.documentSettingsFetchStatus = 'success';
      })
      .addCase(getDocumentSettingsAsyncThunk.rejected, (state) => {
        state.documentSettingsFetchStatus = 'error';
      })
      .addCase(postDocumentSettingsAsyncThunk.pending, (state, action) => {
        state.documentSettings = action.meta.arg;
      })
      .addCase(postDocumentSettingsAsyncThunk.rejected, (state) => {
        state.documentSettingsFetchStatus = 'idle';
      })
      .addCase(getCustomerAddressesAsyncThunk.pending, (state) => {
        state.customerAddressesFetchStatus = 'loading';
      })
      .addCase(getCustomerAddressesAsyncThunk.fulfilled, (state, action) => {
        state.customerAddresses = action.payload;
        state.customerAddressesFetchStatus = 'success';
      })
      .addCase(getCustomerAddressesAsyncThunk.rejected, (state) => {
        state.customerAddressesFetchStatus = 'error';
      })
      .addCase(putCustomerAddressesAsyncThunk.pending, (state, action) => {
        state.customerAddresses = action.meta.arg;
      })
      .addCase(putCustomerAddressesAsyncThunk.rejected, (state) => {
        state.customerAddressesFetchStatus = 'idle';
      })
      .addCase(getNotificationsAsyncThunk.pending, (state) => {
        state.notificationsFetchStatus = 'loading';
      })
      .addCase(getNotificationsAsyncThunk.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.notificationsFetchStatus = 'success';
      })
      .addCase(getNotificationsAsyncThunk.rejected, (state) => {
        state.notificationsFetchStatus = 'error';
      })
      .addCase(postNotificationsAsyncThunk.pending, (state, action) => {
        state.notifications = action.meta.arg;
      })
      .addCase(postNotificationsAsyncThunk.rejected, (state) => {
        state.notificationsFetchStatus = 'idle';
      }),
});

export const { actions, reducer: settingsReducer } = slice;
export const {
  invalidateUnitDeclensionFetch,
  invalidateDocumentSettingsFetch,
  invalidateCustomerAddresses,
} = actions;
