import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

type ProductInfoProps = {
  name: string;
  description: string;
};
const ProductInfo: FC<ProductInfoProps> = ({ name, description }) => (
  <Stack>
    <Typography variant="h5">{name}</Typography>
    <Typography>{description}</Typography>
  </Stack>
);

export { ProductInfo };
