import { FC, PropsWithChildren, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthState } from '../slice/selectors';
import { invalidateGetUser } from '../slice/reducer';
import { SessionTokenRevokedDialog } from './SessionTokenRevokedDialog';
import { useNotificationsHub, events } from 'pl.curulis/modules/NotificationsHub';

type SessionTokenRevokedMessage = {
  sid: string;
};

export const AuthListeners: FC<PropsWithChildren> = ({ children }) => {
  const { sid: currentSessionId } = useSelector(selectAuthState);
  const [tokenRevokedDialogOpen, setTokenRevokedDialogOpen] = useState(false);
  const dispatch = useDispatch();

  useNotificationsHub(events.AccessTokenOutdated, () => {
    dispatch(invalidateGetUser());
  });

  useNotificationsHub(events.SessionTokenRevoked, (message: SessionTokenRevokedMessage) => {
    const { sid: revokedSessionId } = message;
    if (currentSessionId === revokedSessionId) {
      setTokenRevokedDialogOpen(true);
    }
  });

  return (
    <>
      {children}
      <SessionTokenRevokedDialog open={tokenRevokedDialogOpen} />
    </>
  );
};
