import { Stack, Button } from '@mui/material';
import { FC } from 'react';

type ButtonSectionProps = {
  disableSubmit: boolean;
  disableDismiss: boolean;
  onDismiss: VoidFunction;
};

export const ButtonSection: FC<ButtonSectionProps> = ({
  disableSubmit,
  disableDismiss,
  onDismiss,
}) => (
  <Stack direction="row" gap={2} marginTop={2}>
    <Button variant="outlined" disabled={disableDismiss} onClick={onDismiss}>
      Anuluj
    </Button>
    <Button type="submit" variant="contained" disabled={disableSubmit}>
      Zapisz
    </Button>
  </Stack>
);
