export const subscriptionPlanDisplayValueDict: Record<string, string> = {
  'best-trial-14dni': 'BEST Asystent - Testowa',
  curulis: 'Curulis',
  'ekonomiczna-1rok': 'Ekonomiczna',
  'ekspert-1rok': 'Ekspert',
  'metropolia-1rok': 'Metropolia',
  'minimalna-1rok': 'Minimalna',
  'premium-1rok': 'Premium',
  best: 'Best Asystent',
  swb: 'SWB Asystent',
  'swb-trial': 'SWB Asystent - Testowa',
  'trial-14dni': 'Testowa',
  Unknown: 'Nieznany',
};
