import { AppsIcon } from 'pl.curulis/assets/AppsIcon';
import { ContractIcon } from 'pl.curulis/assets/ContractIcon';
import { PATH_APP } from 'pl.curulis/routes/paths';
import { NavItem } from 'pl.curulis/components/NavItemComponent';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const ICONS = {
  products: <AppsIcon />,
  agreements: <ContractIcon />,
  users: <PeopleOutlinedIcon />,
  settings: <SettingsOutlinedIcon />,
};

export const getNavConfig = (isAdmin: Boolean): NavItem[] => {
  if (isAdmin) {
    return [
      { title: 'Produkty', path: PATH_APP.products, icon: ICONS.products },
      { title: 'Umowy', path: PATH_APP.agreements, icon: ICONS.agreements },
      { title: 'Użytkownicy', path: PATH_APP.users, icon: ICONS.users },
      { title: 'Ustawienia', path: PATH_APP.settings, icon: ICONS.settings },
    ];
  }

  return [
    { title: 'Produkty', path: PATH_APP.products, icon: ICONS.products },
    { title: 'Ustawienia', path: PATH_APP.settings, icon: ICONS.settings },
  ];
};
