import { styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { AnimatePresence, m } from 'framer-motion';
import { FC, ReactNode } from 'react';

type ErrorBadgeProps = {
  children: ReactNode;
  visible?: boolean;
  message?: string;
  bottomPosition?: 'inner' | 'outer';
};

const Badge = styled(m.div)(({ theme }) => ({
  position: 'absolute',
  transform: 'translate(0%, 100%)',
  right: 10,
  bottom: 15,
  zIndex: 1,
  lineHeight: 0.75,
  backgroundColor: theme.palette.error.main,
  borderRadius: theme.shape.borderRadius,
  paddingTop: 1,
  paddingBottom: 1,
  paddingLeft: 10,
  paddingRight: 10,
}));

const ErrorBadge: FC<ErrorBadgeProps> = ({
  visible,
  message,
  children,
  bottomPosition = 'inner',
}) => (
  <Box sx={{ position: 'relative', width: '100%' }}>
    <AnimatePresence>
      {visible && (
        <Badge
          key="badge"
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'unset' }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          sx={bottomPosition === 'outer' ? { bottom: 12 } : null}
        >
          <Typography variant="caption" color={(theme) => theme.palette.error.contrastText}>
            {message}
          </Typography>
        </Badge>
      )}
    </AnimatePresence>
    {children}
  </Box>
);

export { ErrorBadge };
