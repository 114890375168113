import { tom } from 'pl.curulis/modules/Axios';
import { CustomerAddresses } from '../../models';
import { nullAddressObject, nullBillingObject } from 'pl.curulis/models/Address/src/AddressModel';

export const getCustomerAddresses = async () => {
  const response = await tom.get<CustomerAddresses>('customer');

  return {
    billing: { ...nullBillingObject, ...response.data.billing },
    shipping: { ...nullAddressObject, ...response.data.shipping },
  };
};
