import { CenteringContainer } from 'pl.curulis/components/CenteringContainer';
import { Products } from '../components/Products';
import { Page } from 'pl.curulis/components/Page';

export const ProductsView = () => (
  <Page title="Produkty">
    <CenteringContainer>
      <Products />
    </CenteringContainer>
  </Page>
);
