import { useTheme } from '@mui/material';
import { FC } from 'react';

export const WpfLogoExtended: FC = () => {
  const theme = useTheme();
  const paletteColorDark = theme.palette.primary.dark;
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 484 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M210.827 15.7771L218.303 45.2751H218.808L225.484 15.7771H236.119L242.795 45.2751H243.09L250.566 15.7771H259.664L248.881 54.5483H237.298L230.77 27.463H230.328L224.094 54.5483H212.596L201.603 15.7771H210.848H210.827Z"
        fill={paletteColorDark}
      />
      <path
        d="M262.022 15.777H270.236L271.331 22.8683H271.92C273.963 18.1268 279.249 15.0637 285.546 15.0637C296.097 15.0637 302.625 22.8683 302.625 35.0787C302.625 47.2892 295.739 55.3036 285.483 55.3036C278.744 55.3036 273.753 52.0097 271.92 47.499H271.331V66.7797H262.022V15.777ZM271.331 36.946C271.331 43.1561 275.795 47.5409 282.261 47.5409C289.379 47.5409 293.106 42.4218 293.106 34.9739C293.106 27.0224 288.789 22.8473 282.408 22.8473C276.027 22.8473 271.331 27.2951 271.331 33.4423V36.946Z"
        fill={paletteColorDark}
      />
      <path
        d="M310.523 23.3718H303.046V15.777H310.523V12.1894C310.523 3.7974 315.872 0 324.001 0C325.77 0 327.876 0.209801 329.139 0.587443V7.97244C327.749 7.67872 326.654 7.61578 325.685 7.61578C321.137 7.61578 319.831 9.94458 319.831 12.9447V15.798H329.139V23.3928H319.831V54.5693H310.523V23.3718Z"
        fill={paletteColorDark}
      />
      <path
        d="M218.008 96.3197L228.854 95.5225V93.4035C228.854 88.8088 225.99 87.1934 221.378 87.1934C216.471 87.1934 213.459 89.7529 213.607 93.4664H204.804C204.593 85.3681 211.248 79.9552 221.672 79.9552C232.96 79.9552 238.162 84.4869 238.162 92.6692V110.334C238.162 111.509 238.457 113.041 240.352 113.041C240.794 113.041 241.384 112.978 241.742 112.831V119.335C240.205 119.775 238.436 120.069 236.983 120.069C232.286 120.069 229.506 117.803 229.296 113.209H228.79C226.895 117.363 221.525 120.216 215.523 120.216C207.752 120.216 202.55 115.684 202.55 108.761C202.55 100.809 208.552 97.0121 218.008 96.3407V96.3197ZM218.745 113.041C224.831 113.041 228.854 109.097 228.854 103.558V101.376L219.608 102.173C214.112 102.614 211.922 104.649 211.922 107.859C211.922 111.362 214.702 113.041 218.745 113.041Z"
        fill={paletteColorDark}
      />
      <path
        d="M260.822 113.398C265.582 113.398 268.298 111.509 268.298 108.509C268.298 105.215 265.371 104.628 263.454 104.271L255.536 102.949C247.175 101.565 244.101 97.3268 244.101 92.1447C244.101 84.3401 250.545 79.9552 260.296 79.9552C270.552 79.9552 276.49 84.8436 276.722 91.9978H267.624C267.477 88.3473 264.613 86.6689 260.211 86.6689C255.81 86.6689 253.472 88.4941 253.472 91.2635C253.472 94.7672 257.137 95.2707 259.264 95.6484L266.824 96.8232C274.89 98.145 277.901 102.446 277.901 107.922C277.901 115.579 271.015 120.195 260.822 120.195C249.745 120.195 243.385 114.929 243.385 107.418H252.482C252.546 111.216 255.789 113.398 260.843 113.398H260.822Z"
        fill={paletteColorDark}
      />
      <path
        d="M286.999 80.6895L296.602 108.215H297.108L306.353 80.6895H315.745L300.709 121.58C298.434 127.79 294.265 132.762 285.02 132.762C282.745 132.762 280.766 132.406 279.523 131.965V124.433C280.913 124.937 282.24 125.167 283.545 125.167C288.452 125.167 290.221 122.839 291.169 120.048L292.053 117.573L276.743 80.6895H286.999Z"
        fill={paletteColorDark}
      />
      <path
        d="M332.993 113.398C337.752 113.398 340.469 111.509 340.469 108.509C340.469 105.215 337.542 104.628 335.625 104.271L327.707 102.949C319.346 101.565 316.272 97.3268 316.272 92.1447C316.272 84.3401 322.716 79.9552 332.466 79.9552C342.722 79.9552 348.661 84.8436 348.893 91.9978H339.795C339.648 88.3473 336.784 86.6689 332.382 86.6689C327.981 86.6689 325.643 88.4941 325.643 91.2635C325.643 94.7672 329.308 95.2707 331.435 95.6484L338.995 96.8232C347.061 98.145 350.072 102.446 350.072 107.922C350.072 115.579 343.186 120.195 332.993 120.195C321.916 120.195 315.556 114.929 315.556 107.418H324.653C324.717 111.216 327.96 113.398 333.014 113.398H332.993Z"
        fill={paletteColorDark}
      />
      <path
        d="M357.591 80.6895V69.9477L366.836 68.3322V80.6686H375.786V88.2634H366.836V106.81C366.836 110.964 368.457 112.495 371.827 112.495C373.006 112.495 374.691 112.349 375.786 112.055V119.293C373.449 119.88 371.469 120.027 369.11 120.027C358.854 120.027 357.612 113.23 357.612 107.901V88.2634H350.789V80.6686H357.612L357.591 80.6895Z"
        fill={paletteColorDark}
      />
      <path
        d="M376.207 100.18C376.207 87.9067 384.125 79.9552 395.856 79.9552C407.586 79.9552 414.472 86.9626 414.472 97.4736C414.472 99.152 414.325 100.914 414.03 102.152H385.515C385.368 109.097 389.538 112.957 395.919 112.957C400.973 112.957 404.574 110.628 405.459 107.397H414.704C413.377 114.992 405.985 120.174 396.003 120.174C384.21 120.174 376.207 112.286 376.207 100.159V100.18ZM405.164 95.8791C405.101 90.3194 401.563 87.1095 395.645 87.1095C389.727 87.1095 385.831 90.6131 385.6 95.8791H405.164Z"
        fill={paletteColorDark}
      />
      <path
        d="M426.982 80.6895L428.161 87.8438H428.751C431.383 82.8085 436.227 79.9552 442.229 79.9552C450.8 79.9552 456.296 85.515 456.296 95.6484V119.461H447.051V97.3268C447.051 90.6761 443.829 87.7598 438.543 87.7598C432.099 87.7598 428.14 92.4384 428.14 99.4458V119.461H418.832V80.6895H426.982Z"
        fill={paletteColorDark}
      />
      <path
        d="M464.826 80.6895V69.9477L474.071 68.3322V80.6686H483.021V88.2634H474.071V106.81C474.071 110.964 475.692 112.495 479.062 112.495C480.241 112.495 481.926 112.349 483.021 112.055V119.293C480.683 119.88 478.704 120.027 476.345 120.027C466.089 120.027 464.847 113.23 464.847 107.901V88.2634H458.023V80.6686H464.847L464.826 80.6895Z"
        fill={paletteColorDark}
      />
      <path
        d="M162.895 15.6092C162.895 8.43402 157.062 2.62253 149.86 2.62253H90.8507C86.8073 2.62253 82.806 3.56663 79.2048 5.37092L64.1473 12.8608C60.525 14.6651 56.5448 15.6092 52.5014 15.6092H13.0358C5.83349 15.6092 0 21.4207 0 28.5959V119.482C0 126.657 5.83349 132.468 13.0358 132.468H149.86C157.062 132.468 162.895 126.657 162.895 119.482V15.6092Z"
        fill="#06C89B"
      />
      <path
        d="M128.526 93.7182C135.981 93.7182 140.32 96.5715 140.32 102.614C140.32 108.656 135.981 111.53 128.526 111.53H123.746L126.673 117.384C127.305 118.664 128.632 119.482 130.085 119.482C141.794 119.482 149.86 113.251 149.86 102.635C149.86 92.0188 141.794 85.7877 130.085 85.7877H110.836L113.764 91.6412C114.395 92.9209 115.722 93.7392 117.175 93.7392H128.526V93.7182Z"
        fill="white"
      />
      <path
        d="M106.056 103.558C98.6009 103.558 94.2626 100.705 94.2626 94.6623C94.2626 88.62 98.6009 85.7457 106.056 85.7457H110.836L107.909 79.8923C107.256 78.5915 105.93 77.7943 104.498 77.7943C92.7885 77.7943 84.7227 84.0254 84.7227 94.6413C84.7227 105.257 92.7885 111.488 104.498 111.488H123.746L120.819 105.635C120.187 104.355 118.86 103.537 117.407 103.537H106.056V103.558Z"
        fill="white"
      />
    </svg>
  );
};
