import 'simplebar/src/simplebar.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import App from './App';
import { ConfigProvider } from 'pl.curulis/modules/Configuration';
import { setupStore } from 'pl.curulis/redux/store';
import { Provider as ReduxProvider } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ExternalIntegrations } from 'pl.curulis/externalIntegrations/ExternalIntegrations';
import { LicenseInfo } from 'pl.curulis/modules/DataGrid';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const RE_CAPTCHA_KEY = '6LdIJHgpAAAAAMW4wTgUToTiJefGboKagK5IWItg';

LicenseInfo.setLicenseKey(
  '083e29efae07ac11f000940e78a06998Tz03OTUzMixFPTE3MzI3OTAxNTkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const store = setupStore();

root.render(
  <GoogleReCaptchaProvider reCaptchaKey={RE_CAPTCHA_KEY}>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <ConfigProvider>
          <SettingsProvider>
            <CollapseDrawerProvider>
              <ExternalIntegrations />
              <App />
            </CollapseDrawerProvider>
          </SettingsProvider>
        </ConfigProvider>
      </ReduxProvider>
    </HelmetProvider>
  </GoogleReCaptchaProvider>
);
