import { Route } from 'react-router';
import { PATH_APP } from '../paths';
import { DashboardLayoutWrapper } from './common/DashboardLayoutWrapper';
import { AgreementsView } from 'pl.curulis/pages/Products/src/views';
import { RequireAdmin } from 'pl.curulis/modules/Authentication';

export const agreementRoutes = (
  <Route
    path={PATH_APP.agreements}
    element={
      <RequireAdmin>
        <DashboardLayoutWrapper />
      </RequireAdmin>
    }
  >
    <Route path={PATH_APP.agreements} element={<AgreementsView />} />
  </Route>
);
