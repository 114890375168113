import { AddressModel } from 'pl.curulis/models/Address';
import { BillingModel } from 'pl.curulis/models/Address/src/AddressModel';
import { tom } from 'pl.curulis/modules/Axios';

type PostCheckoutModel = {
  pricePointId: string;
  couponId?: string;
  shippingAddress?: AddressModel;
  billingAddress: BillingModel;
};

const postCheckout = async (body: PostCheckoutModel) => {
  const response = await tom.post('checkout', body);
  return response.data;
};

export { postCheckout };
export type { PostCheckoutModel };
