import { FC } from 'react';
import { useTheme } from '@mui/material';

export const PublinkLogo: FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 85 59"
      fill={theme.palette.primary.dark}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M84.351 35.3346C84.3145 22.2871 73.3543 11.7991 60.0488 11.7991H34.6378L41.1446 21.4773C42.0343 22.8017 43.5447 23.5982 45.1645 23.5982H60.1264C66.7517 23.5982 72.2501 28.784 72.3139 35.2809C72.3778 41.8404 66.9571 47.1963 60.2815 47.1963H49.6909L56.1657 56.8253C57.0783 58.181 58.6251 58.9999 60.2815 58.9999C73.5779 58.9999 84.3875 48.3776 84.351 35.3301V35.3346Z" />
      <path d="M9.21815e-05 23.6697C0.0365956 36.7172 10.9968 47.2053 24.3023 47.2053H49.7132L43.2065 37.5271C42.3167 36.2026 40.8064 35.4062 39.1865 35.4062H24.2247C17.5993 35.4062 12.101 30.2203 12.0371 23.7234C11.9732 17.1639 17.394 11.808 24.0696 11.808H34.6601L28.1853 2.17905C27.2727 0.814347 25.7259 0 24.0696 0C10.7732 0 -0.0364113 10.6223 9.21815e-05 23.6697Z" />
    </svg>
  );
};
