import { Stack, Typography } from '@mui/material';
import { selectProductSubscription } from 'pl.curulis/modules/productsSubscriptions';
import { ProductCardProps } from 'pl.curulis/modules/Product/src/components/ProductCard/ProductCard';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getLicenseStatusDisplayValue } from 'pl.curulis/models/Product';
import { selectPricePoints } from 'pl.curulis/modules/Plans/src/slice/selectors';

type SubscriptionSectionProps = Pick<ProductCardProps, 'product'> & {
  shouldShowNextBillingDate?: boolean;
};
export const SubscriptionSection: FC<SubscriptionSectionProps> = ({
  product,
  shouldShowNextBillingDate = false,
}) => {
  const subscription = useSelector(selectProductSubscription(product));
  const pricePointsDict = useSelector(selectPricePoints);

  if (!subscription || product === 'plikomat') return null;

  const getColor = () => {
    if (subscription.status === 'Active') return 'success.main';
    else if (subscription.status === 'InTrial') return 'error.main';
    else return 'inherit';
  };

  const getLicenseName = () => {
    if (product === 'wpf') {
      if (subscription.status === 'InTrial')
        return getLicenseStatusDisplayValue(subscription.status);

      if (!pricePointsDict.length) return;

      const licensePlanId = pricePointsDict.find(
        (plan) => plan.pricePointId === subscription.planId
      );

      if (!licensePlanId) return subscription.planId;

      return licensePlanId.name;
    }
    return getLicenseStatusDisplayValue(subscription.status);
  };

  const getNextBillingDateSection = () => {
    if (subscription.status === 'InTrial') return null;

    return (
      shouldShowNextBillingDate &&
      subscription.nextBillingDate && (
        <Typography variant="body2" fontWeight={600} component={Stack} direction="row" gap={1}>
          Odnowienie:
          <Typography variant="body2" component={'span'}>
            {new Date(subscription.nextBillingDate).toLocaleDateString()}
          </Typography>
        </Typography>
      )
    );
  };

  return (
    <Stack gap={1}>
      <Typography
        flexWrap="wrap"
        variant="body2"
        fontWeight={600}
        component={Stack}
        direction="row"
        gap={1}
      >
        Subskrypcja:
        <Typography variant="body2" color={getColor()} component={'span'}>
          {getLicenseName()}
        </Typography>
        <Typography variant="body2" component={'span'}>
          do {new Date(subscription.end).toLocaleDateString()}
        </Typography>
      </Typography>
      {getNextBillingDateSection()}
    </Stack>
  );
};
