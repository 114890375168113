import { Link, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useGetAgreementDownloadUrl } from 'pl.curulis/modules/Product/src/utils/useGetAgreementDownloadUrl';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ProductPlanModel } from 'pl.curulis/models/Product';
import { Product } from 'pl.curulis/models/Product/src/enums/Product';

type AgreementsSectionProps = {
  product: Product;
} & Pick<ProductPlanModel, 'planId' | 'start' | 'subscriptionId'>;

export const AgreementsSection: FC<AgreementsSectionProps> = ({
  product,
  planId,
  start,
  subscriptionId,
}) => {
  const agreementUrl = useGetAgreementDownloadUrl({
    product,
    subscriptionId,
    planId,
    start,
  });

  return (
    <Stack gap={1} direction="column">
      <Typography variant="body2" fontWeight={600}>
        Dokumenty:
      </Typography>
      <Stack gap={1}>
        <Typography variant="body2" component={Link} href={agreementUrl} target="_blank">
          <Stack direction="row" gap={1} alignItems="center">
            <FileDownloadIcon fontSize="small" />
            Umowa licencyjna
          </Stack>
        </Typography>
        <Typography
          variant="body2"
          component={Link}
          href={'https://dokumenty.publink.com/zal1_regulamin_licencja.pdf'}
          target="_blank"
        >
          <Stack direction="row" gap={1} alignItems="center">
            <PictureAsPdfIcon fontSize="small" />
            Załącznik nr 1 - Regulamin i licencja
          </Stack>
        </Typography>
        <Typography
          color={'text'}
          variant="body2"
          component={Link}
          href={'https://dokumenty.publink.com/zal2_prywatnosc.pdf'}
          target="_blank"
        >
          <Stack direction="row" gap={1} alignItems="center">
            <PictureAsPdfIcon fontSize="small" />
            Załącznik nr 2 - Prywatność
          </Stack>
        </Typography>
      </Stack>
    </Stack>
  );
};
