import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'pl.curulis/redux/store';
import { Product } from '../../../../models/enums/Product';
import { ProductsDefinitionsModel } from '../../../../models/Config';

export const selectConfigState = createSelector(
  (state: RootState) => state.config,
  (config) => config
);

export const selectConfig = createSelector(selectConfigState, (state) => state.config);

export const selectServicesUrls = createSelector(selectConfig, (config) => {
  const { tom } = config.services;

  return {
    tomUrl: tom.baseUrl,
    bolekUrl: `${tom.baseUrl}/proxy/api-jednostki`,
  };
});

export const selectProductsDefinitions = createSelector(selectConfig, (config) =>
  Object.keys(config.products).map(
    (product) => ({ product, ...config.products[product as Product] } as ProductsDefinitionsModel)
  )
);
