import { Route } from 'react-router';
import { DashboardLayoutWrapper } from './common/DashboardLayoutWrapper';
import { PATH_APP } from '../paths';
import { RequireAdmin } from 'pl.curulis/modules/Authentication';
import { UserManagementView } from 'pl.curulis/modules/UserManagement';

export const userRoutes = (
  <Route path={PATH_APP.users} element={<DashboardLayoutWrapper />}>
    <Route
      path={PATH_APP.users}
      element={
        <RequireAdmin>
          <UserManagementView />
        </RequireAdmin>
      }
    />
    <Route
      path={`${PATH_APP.users}/:usersTab`}
      element={
        <RequireAdmin>
          <UserManagementView />
        </RequireAdmin>
      }
    />
  </Route>
);
