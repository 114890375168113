import { Stack } from '@mui/material';
import { TextfieldWithError } from 'pl.curulis/components/TextFieldWithError';
import { AppSettings } from 'pl.curulis/modules/Settings/models';
import { decisionMakingAuthorityOptions } from 'pl.curulis/modules/Settings/src/consts/decisionMakingAuthorityOptions';
import { executiveAuthorityOptions } from 'pl.curulis/modules/Settings/src/consts/executiveAuhorityOptions';
import { unitNamePrefixOptions } from 'pl.curulis/modules/Settings/src/consts/unitNamePrefixOptions';
import { FC } from 'react';
import { Control, UseFormRegister, FieldErrors } from 'react-hook-form';
import { UnitDeclensionDropdown } from './UnitDeclensionDropdown';

type UnitDeclensionFormProps = {
  control: Control<AppSettings>;
  register: UseFormRegister<AppSettings>;
  errors: FieldErrors<AppSettings>;
};

export const UnitDeclensionForm: FC<UnitDeclensionFormProps> = ({ control, register, errors }) => (
  <Stack gap={4} maxWidth={650}>
    <Stack gap={2}>
      <UnitDeclensionDropdown
        label="Typ jednostki (wymagane)"
        control={control}
        name="unitDeclension.unitNamePrefix"
        options={unitNamePrefixOptions}
      />
      <TextfieldWithError
        {...register('unitDeclension.unitNameGenitive')}
        errorMessage={errors?.unitDeclension?.unitNameGenitive?.message}
        label="Nazwa w dopełniaczu (kogo? czego?)"
        placeholder="Np.: Gminy Czernica"
      />
    </Stack>
    <Stack direction="row" gap={2}>
      <UnitDeclensionDropdown
        label="Organ stanowiący (wymagane)"
        control={control}
        name="unitDeclension.decisionMakingAuthority"
        options={decisionMakingAuthorityOptions}
      />
      <TextfieldWithError
        {...register('unitDeclension.decisionMakingAuthoritySuffix')}
        errorMessage={errors?.unitDeclension?.decisionMakingAuthoritySuffix?.message}
        label="Nazwa w dopełniaczu (kogo? czego?)"
        placeholder="Np.: Czernicy"
      />
    </Stack>
    <Stack direction="row" gap={2}>
      <UnitDeclensionDropdown
        label="Organ wykonawczy (wymagane)"
        control={control}
        name="unitDeclension.executiveAuthority"
        options={executiveAuthorityOptions}
      />
      <TextfieldWithError
        {...register('unitDeclension.executiveAuthoritySuffix')}
        errorMessage={errors?.unitDeclension?.executiveAuthoritySuffix?.message}
        label="Nazwa w dopełniaczu (kogo? czego?)"
        placeholder="Np.: Gminy Czernica"
      />
    </Stack>
  </Stack>
);
