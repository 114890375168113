import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { useCurrentProduct, useProductProfile } from 'pl.curulis/modules/Product';
import { Navigate } from 'react-router-dom';
import { Page } from 'pl.curulis/components/Page';
import { CenteringContainer } from 'pl.curulis/components/CenteringContainer';
import { useProductPricePoints } from 'pl.curulis/modules/Plans';
import { licenseDescriptionsData } from './licenseDescriptions';
import { useSelector } from 'react-redux';
import { selectProductPricePoints } from 'pl.curulis/modules/Plans/src/slice/selectors';
import { PricingCard } from './PricingCard';
import { PATH_APP } from '../../../routes/paths';

export const PricingView: FC = () => {
  const product = useCurrentProduct();
  const { logoExtended } = useProductProfile(product);
  const { fetchStatus } = useProductPricePoints();
  const productLicenseDescriptions = licenseDescriptionsData.filter(
    (description) => description.product === product
  );
  const isReady = fetchStatus === 'success';
  const findDescription = (pricePointID: string) =>
    productLicenseDescriptions.find((description) => description.pricePointId === pricePointID);
  const productPricePoints = useSelector((state) => selectProductPricePoints(state, product));

  if (product === 'Unknown') {
    return <Navigate replace to={PATH_APP.core} />;
  }

  return (
    <Page title="Cennik">
      <CenteringContainer>
        {isReady && (
          <>
            <Box
              sx={{
                height: '40px',
                marginBottom: 1,
                '& svg': {
                  width: 'auto',
                },
              }}
            >
              {logoExtended}
            </Box>
            <Stack
              sx={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 2,
                paddingBottom: 6,
              }}
            >
              {productPricePoints.map((plan) => (
                <PricingCard
                  {...plan}
                  key={plan.pricePointId}
                  product={product}
                  licenseDescription={findDescription(plan.pricePointId)}
                />
              ))}
            </Stack>
          </>
        )}
      </CenteringContainer>
    </Page>
  );
};
