import { Divider } from '@mui/material';

export const MenuDivider = () => (
  <Divider
    sx={{
      border: (theme) => `1px dashed ${theme.palette.border.lighter}`,
      margin: '0 8px',
      marginBottom: '4px',
    }}
  />
);
