import { createSlice } from '@reduxjs/toolkit';
import { nullConfig } from 'pl.curulis/models/Config';
import { fetchConfig } from './actions';
import { configMapper } from './configMapper';

const initialState = {
  config: nullConfig,
  isReady: false,
};

const slice = createSlice({
  initialState,
  name: 'config',
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(fetchConfig.fulfilled, (state, action) => {
      state.config = configMapper(action.payload);
      state.isReady = true;
    }),
});

const { actions, reducer } = slice;
export { reducer, actions };
