import { FC, ReactNode } from 'react';
import { Stack } from '@mui/material';
import {
  MenuListItemIconStyle,
  MenuListItemStyle,
  MenuListItemTextStyle,
} from 'pl.curulis/components/MenuListItemStyle';
import { useLocation, useNavigate } from 'react-router';
import { matchPath } from 'react-router-dom';
import { NavbarVariant } from 'pl.curulis/layouts/Menu/Navbar';

export type NavItem = {
  title: string;
  icon: ReactNode;
  path?: string;
  onClick?: () => void;
};

type NavItemComponentProps = {
  title: NavItem['title'];
  icon: NavItem['icon'];
  active: boolean;
  onClick?: () => void;
};

function isActive(pathname: string, path?: string) {
  return !!path && !!matchPath({ path, end: false }, pathname);
}

const NavItemFlyout: FC<NavItemComponentProps> = ({ title, icon, active, onClick }) => (
  <MenuListItemStyle depth={1} active={active} onClick={onClick}>
    <Stack direction="row" alignItems="center" spacing={0.25}>
      <MenuListItemIconStyle>{icon}</MenuListItemIconStyle>
      <MenuListItemTextStyle
        primary={title}
        primaryTypographyProps={{
          noWrap: true,
          variant: active ? 'subtitle2' : 'body2',
        }}
      />
    </Stack>
  </MenuListItemStyle>
);

const NavItemCompact: FC<NavItemComponentProps> = ({ title, icon, active, onClick }) => (
  <>
    <MenuListItemStyle
      depth={1}
      active={active}
      onClick={onClick}
      sx={{ height: 'fit-content', padding: '8px 0', position: 'relative', margin: '0.5 0' }}
    >
      <Stack direction="column" spacing={0.25} width="100%" alignItems="center">
        <MenuListItemIconStyle sx={{ margin: '0 auto' }}>{icon}</MenuListItemIconStyle>
        <MenuListItemTextStyle
          primary={title}
          primaryTypographyProps={{
            variant: active ? 'subtitle2' : 'body2',
            textAlign: 'center',
            fontSize: '0.7em',
            margin: '0 auto',
            lineHeight: '16px',
          }}
        />
      </Stack>
    </MenuListItemStyle>
  </>
);

export const NavItemComponent: FC<{ item: NavItem; variant: NavbarVariant }> = ({
  variant,
  item,
}) => {
  const { title, icon, path, onClick } = item;
  const { pathname } = useLocation();
  const active = isActive(pathname, path);
  const navigate = useNavigate();
  const handleClick = path ? () => navigate(path) : onClick;

  const NavItemView = variant === 'compact' ? NavItemCompact : NavItemFlyout;

  return <NavItemView title={title} icon={icon} onClick={handleClick} active={active} />;
};
