import { useMemo, ReactNode } from 'react';
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import useSettings from 'hooks/useSettings';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import { publinkTypography } from './publinkTypography';
import { PublinkThemeOptions } from './types';
import { createCustomShadow, createShadow } from './shadows';
import { publinkPalette } from './publinkPalette';

type Props = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { themeDirection } = useSettings();

  const themeOptions: PublinkThemeOptions = useMemo(
    () => ({
      palette: publinkPalette,
      typography: publinkTypography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: createShadow(publinkPalette),
      customShadows: createCustomShadow(publinkPalette),
    }),
    [themeDirection]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
