import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { NavigateWithQuery } from 'pl.curulis/modules/Navigation';
import { selectIsAdmin } from '../slice/selectors';

const RequireAdmin: FC<{ children: ReactNode }> = ({ children }) => {
  const isAdmin = useSelector(selectIsAdmin);

  if (!isAdmin) return <NavigateWithQuery hrefWithoutQuery="/404" replace />;

  return <>{children}</>;
};

export { RequireAdmin };
