import { Box } from '@mui/material';
import { selectIsImpersonating } from 'pl.curulis/modules/Authentication';
import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

export const ImpersonationIndicator: FC<PropsWithChildren> = ({ children }) => {
  const isImpersonating = useSelector(selectIsImpersonating);

  if (!isImpersonating) return <>{children}</>;

  return (
    <Box
      minHeight={'100%'}
      height={'100%'}
      sx={{
        border: (theme) => `solid 6px ${theme.palette.warning.main}`,
      }}
    >
      {children}
    </Box>
  );
};
