import { TextEditCell } from '../components/cells/base/TextEditCell';
import { TextDisplayCell } from '../components/cells/base/TextDisplayCell';
import { MultiAutocompleteCell } from '../components/cells/base/MultiAutocompleteCell';
import { SingleSelectEditCell } from '../components/cells/base/SingleSelectEditCell';
import { NewUserActionsCell } from '../components/cells/usersTable/NewUserActionsCell';
import { ExistingUserActionsCell } from '../components/cells/usersTable/ExistingUserActionsCell';
import { RowModel, NewUserRowModel, isNewUserRow } from '../components/UsersDataGrid';
import { GridColDef } from '@mui/x-data-grid-premium';
import {
  userTypeConsts,
  OrganizationMemberModel,
  userTypeLabels,
} from 'pl.curulis/models/OrganizationMember/src/OrganizationMemberModel';
import { selectAllOrganizationUnits } from '../slice/selectors';
import { useSelector } from 'react-redux';
import { UserTypeDisplayCell } from '../components/cells/usersTable/UserTypeDisplayCell';
import { OrganizationUnitDisplayCell } from '../components/cells/usersTable/OrganizationUnitDisplayCell';
import { selectProductSubscription } from 'pl.curulis/modules/productsSubscriptions';
import { useCallback, useMemo } from 'react';

type ColumnsConfig = GridColDef<RowModel>[];

type UseUsersColumnsOptions = {
  handleNewUserChange<T extends keyof NewUserRowModel>(field: T, value: NewUserRowModel[T]): void;
  handleExistingUserChange<T extends keyof OrganizationMemberModel>(
    user: OrganizationMemberModel,
    field: T,
    value: OrganizationMemberModel[T]
  ): Promise<void>;
  handleSaveNewUser: () => Promise<void>;
  handleAbortAddingUser: () => void;
};

export const useUsersColumns = ({
  handleNewUserChange,
  handleExistingUserChange,
  handleSaveNewUser,
  handleAbortAddingUser,
}: UseUsersColumnsOptions): ColumnsConfig => {
  const hasProductWithOrganizationUnits = !!useSelector(selectProductSubscription('best'));
  const userTypeOptions = useMemo(
    () =>
      userTypeConsts.map((userType) => ({
        id: userType,
        label: userTypeLabels[userType],
      })),
    []
  );
  const organizationUnits = useSelector(selectAllOrganizationUnits);
  const organizationUnitOptions = useMemo(
    () =>
      organizationUnits.map((orgUnit) => ({
        id: orgUnit.constId,
        label: orgUnit.name,
      })),
    [organizationUnits]
  );

  const getUnitsPlaceholder = useCallback(
    (internalValue: string[], isOpen: boolean) => {
      if (isOpen) return 'Wyszukaj';
      if (!internalValue?.length) return 'Wybierz jednostki';

      const selectedOptions = organizationUnitOptions.filter((options) =>
        internalValue.includes(options.id)
      );
      const [firstOption, ...rest] = selectedOptions;
      const counter = rest.length;

      if (counter) return `${firstOption.label} +${counter}`;
      return firstOption.label;
    },
    [organizationUnitOptions]
  );

  const columnsForOrganizationUnits: ColumnsConfig = useMemo(
    () => [
      {
        field: 'userType',
        flex: 2,
        headerName: 'Rola',
        sortable: true,
        minWidth: 250,
        editable: true,
        renderCell: (cell) =>
          isNewUserRow(cell.row) ? (
            <SingleSelectEditCell
              cell={cell}
              onChange={(newVal) => handleNewUserChange('userType', newVal)}
              options={userTypeOptions}
            />
          ) : (
            <UserTypeDisplayCell user={cell.row} />
          ),
        renderEditCell: (cell) => {
          const { row } = cell;

          if (isNewUserRow(row)) {
            return null;
          }

          return (
            <SingleSelectEditCell
              cell={cell}
              onChange={(newVal) => handleExistingUserChange(row, 'userType', newVal)}
              options={userTypeOptions}
              openOnRender={true}
            />
          );
        },
      },
      {
        field: 'organizationUnitIds',
        flex: 2,
        headerName: 'Jednostki organizacyjne',
        sortable: false,
        minWidth: 250,
        editable: true,
        renderCell: (cell) => {
          if (cell.row.userType === 'MainUnitUser') {
            return null;
          }

          if (isNewUserRow(cell.row)) {
            return (
              <MultiAutocompleteCell
                cell={cell}
                onChange={(newVal) => handleNewUserChange('organizationUnitIds', newVal)}
                options={organizationUnitOptions}
                getPlaceholder={getUnitsPlaceholder}
              />
            );
          }

          const selectedOptions = organizationUnitOptions.filter((option) =>
            cell.value.includes(option.id)
          );

          return <OrganizationUnitDisplayCell selectedOptions={selectedOptions} />;
        },
        renderEditCell: (cell) => {
          const { row } = cell;
          if (row.userType === 'MainUnitUser' || isNewUserRow(row)) {
            return null;
          }

          return (
            <MultiAutocompleteCell
              cell={cell}
              onChange={(newVal) => handleExistingUserChange(row, 'organizationUnitIds', newVal)}
              options={organizationUnitOptions}
              getPlaceholder={getUnitsPlaceholder}
            />
          );
        },
      },
    ],
    [
      getUnitsPlaceholder,
      handleExistingUserChange,
      handleNewUserChange,
      organizationUnitOptions,
      userTypeOptions,
    ]
  );

  return useMemo(
    () => [
      {
        field: 'email',
        flex: 2,
        headerName: 'Email',
        minWidth: 250,
        renderCell: ({ row, value }) =>
          isNewUserRow(row) ? (
            <TextEditCell
              type="email"
              focusOnRender={true}
              value={value}
              onChange={(newVal) => handleNewUserChange('email', newVal)}
            />
          ) : (
            <TextDisplayCell value={value} />
          ),
      },
      ...(hasProductWithOrganizationUnits ? columnsForOrganizationUnits : []),
      {
        align: 'center',
        field: 'actions',
        flex: 1,
        headerAlign: 'center',
        headerName: 'Czynności',
        minWidth: 80,
        renderCell: ({ row }) =>
          isNewUserRow(row) ? (
            <NewUserActionsCell
              handleSaveNewUser={handleSaveNewUser}
              handleAbortAddingUser={handleAbortAddingUser}
            />
          ) : (
            <ExistingUserActionsCell row={row} />
          ),
        sortable: false,
      },
    ],
    [
      columnsForOrganizationUnits,
      handleSaveNewUser,
      handleAbortAddingUser,
      handleNewUserChange,
      hasProductWithOrganizationUnits,
    ]
  );
};
