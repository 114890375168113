import { createEnumMapper, createArrayMapper } from 'pl.curulis/utils/mapperCreators';
import { OrgUnit, orgUnitTypeConsts } from './OrganizationUnitModel';
import { OrganizationUnitDto } from './OrganizationUnitDto';

const orgUnitTypeMapper = createEnumMapper(orgUnitTypeConsts, undefined);

export const mapOrgUnitDtoToModel = (dto: OrganizationUnitDto): OrgUnit => ({
  ...dto,
  type: orgUnitTypeMapper(dto.type),
});

export const arrayMapOrgUnitDtoToModel = createArrayMapper(mapOrgUnitDtoToModel);
