import { BestLogoExtended } from 'pl.curulis/assets/BestLogoExtended';
import { Profile } from '../../models/Profile';
import { BestLogo } from 'pl.curulis/assets/BestLogo';

export const bestProfile: Profile = {
  appType: 'Web',
  helpUrl: 'https://best.wiedza.publink.com/',
  host: 'best.publink.com',
  landingUrl: 'https://publink.com/best-asystent',
  logo: <BestLogo />,
  logoExtended: <BestLogoExtended />,
  product: 'best',
  videoAnnotation:
    'Wygeneruj uzasadnienie do projektu budżetu w kilka minut i wprowadzaj szybko zmiany do budżetu. Obejrzyj naszą instrukcję wideo.',
  videoUrl: 'https://www.youtube.com/embed/EPcer9MT7h0',
};
