import { createContext, useContext } from 'react';
import { CouponInfo } from '../../../models/CouponInfo';

type CouponContextType = {
  couponId: string;
  showCouponInput: boolean;
  hasNotClaimedCoupon: boolean;
  setShowCouponInput: (showCouponInput: boolean) => void;
  setCouponId: (couponId: string) => void;
  couponInfo: CouponInfo | undefined;
  setCouponInfo: (couponInfo: CouponInfo | undefined) => void;
  clearCoupon: VoidFunction;
};

const CouponContext = createContext<CouponContextType>({
  couponId: '',
  showCouponInput: false,
  hasNotClaimedCoupon: false,
  setShowCouponInput: () => null,
  setCouponId: () => null,
  couponInfo: undefined,
  setCouponInfo: () => null,
  clearCoupon: () => null,
});

const useCouponContext = () => useContext(CouponContext);

export { CouponContext, useCouponContext };
export type { CouponContextType };
