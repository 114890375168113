import { FC, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectProductsSubscriptions } from 'pl.curulis/modules/productsSubscriptions/src/slice/selectors';
import { selectSettingsState } from '../../../slice/selectors';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBlockHasChanges } from '../../../utils/useBlockHasChanges';
import { customerAddressesFormValidationSchema } from '../../../utils/customerAddressesFormValidation';
import { putCustomerAddressesAsyncThunk } from '../../../slice/thunks';
import { useDismissibleSnackbar } from 'pl.curulis/modules/DismissibleSnackbar';
import { AddressForm } from './AddressForm';
import { ButtonSection } from './ButtonsSection';
import { isEqual, omit } from 'lodash';
import { nullAddressObject } from 'pl.curulis/models/Address/src/AddressModel';
import { CustomerAddresses, PartialCustomerAddresses } from 'pl.curulis/modules/Settings/models';

type CustomerAddressesFormProps = {
  onValuesChange?: (values: PartialCustomerAddresses) => void;
  onIsValidChange?: (isValid: boolean) => void;
  isCheckout?: boolean;
};

export const CustomerAddressesForm: FC<CustomerAddressesFormProps> = ({
  isCheckout,
  onValuesChange,
  onIsValidChange,
}) => {
  const dispatch = useDispatch();
  const subscriptions = useSelector(selectProductsSubscriptions);
  const { customerAddresses: savedCustomerAddresses, customerAddressesFetchStatus } =
    useSelector(selectSettingsState);

  const { openSnackbar } = useDismissibleSnackbar();

  const [checked, setChecked] = useState(false);
  const [manuallyUnchecked, setManuallyUnchecked] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    register,
    reset,
    watch,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm<CustomerAddresses>({
    mode: 'onChange',
    resolver: yupResolver(customerAddressesFormValidationSchema),
  });

  useBlockHasChanges(isDirty);

  useEffect(() => {
    if (!onValuesChange) {
      return;
    }

    const subscription = watch((formValues) => {
      onValuesChange(formValues);
    });

    return subscription.unsubscribe;
  }, [watch, onValuesChange]);

  useEffect(() => {
    // When values saved in store are changed we have to treat updated values
    // as defaults to which `isDirty` is evaluated - it's our base for determining
    // if user made changes.
    // First reset will set initial values of the form and will trigger first
    // `onValuesChange` call, so that parent component can receive current form
    // values. For this to work, `watch` subscription must be registered in
    // earlier `useEffect`.
    reset(savedCustomerAddresses);
  }, [savedCustomerAddresses, reset]);

  useEffect(() => {
    onIsValidChange?.(isValid);
  }, [isValid, onIsValidChange]);

  const defaultShippingHasAnyValue = Object.values(savedCustomerAddresses.shipping).some(
    (value) => !!value
  );

  const isValuesEqual = isEqual(
    savedCustomerAddresses.shipping,
    omit(savedCustomerAddresses.billing, 'nip')
  );

  useEffect(() => {
    if (!manuallyUnchecked) {
      setChecked(defaultShippingHasAnyValue && !isValuesEqual);
    }
  }, [
    isValuesEqual,
    manuallyUnchecked,
    savedCustomerAddresses.shipping,
    defaultShippingHasAnyValue,
  ]);

  const onUncheck = () => {
    setManuallyUnchecked(true);
    defaultShippingHasAnyValue &&
      setValue('shipping', omit(savedCustomerAddresses.billing, 'nip'), {
        shouldDirty: true,
        shouldValidate: true,
      });
  };

  const onCheck = () => {
    if (!defaultShippingHasAnyValue) return;
    isValuesEqual
      ? setValue('shipping', nullAddressObject)
      : setValue('shipping', savedCustomerAddresses.shipping, {
          shouldDirty: true,
          shouldValidate: true,
        });
  };

  const onDismiss = () => {
    isValuesEqual
      ? reset({ billing: savedCustomerAddresses.billing, shipping: nullAddressObject })
      : reset();
  };

  const onSubmit = async () => {
    try {
      await dispatch(putCustomerAddressesAsyncThunk(getValues())).unwrap();
      openSnackbar('Zmiany zapisane pomyślnie.', { severity: 'success' });
    } catch {
      openSnackbar('Nie udało się zapisać zmian.', { severity: 'error' });
    }
  };

  if (customerAddressesFetchStatus === 'idle' || customerAddressesFetchStatus === 'loading') {
    return null;
  }

  return (
    <Stack direction="row" gap={2}>
      <Stack component="form" onSubmit={handleSubmit(onSubmit)} maxWidth={650} flex={1} gap={5}>
        <Stack gap={2}>
          <AddressForm
            register={register}
            errors={errors}
            control={control}
            displayNip
            formTitle="Nabywca"
          />
          {(subscriptions.products?.some((product) => product.subscription) || isCheckout) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      onUncheck();
                    } else {
                      onCheck();
                    }
                    setChecked(e.target.checked);
                  }}
                />
              }
              label="Odbiorca inny niż nabywca"
            />
          )}

          {checked && (
            <AddressForm
              register={register}
              errors={errors}
              control={control}
              formTitle="Odbiorca"
            />
          )}
        </Stack>
        {!isCheckout && (
          <ButtonSection
            disableDismiss={!isDirty}
            disableSubmit={!isDirty || !isValid || isSubmitting}
            onDismiss={onDismiss}
          />
        )}
      </Stack>
    </Stack>
  );
};
