import { FC, ReactNode } from 'react';
import CardHeader from '@mui/material/CardHeader';
import { Box, Stack } from '@mui/material';
import { Product } from 'pl.curulis/models/enums/Product';
import {
  DownloadButton,
  TrialButton,
  PurchaseButton,
  OpenButton,
} from 'pl.curulis/modules/Product/src/components/ProductCard/utils';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { claimTrialAsyncThunk, selectProductState } from 'pl.curulis/modules/productsSubscriptions';
import * as amplitude from '@amplitude/analytics-browser';
import { Known } from 'pl.curulis/models/utils';
import { useProductProfile } from 'pl.curulis/modules/Product/src/hooks/useProductProfile';
import { PATH_PRODUCT } from 'pl.curulis/routes/paths';
import { useSelector } from 'react-redux';
import { useCampaignData } from 'pl.curulis/modules/CampaignData';
import { PRODUCT_CARD_WIDTH, PRODUCT_CARD_HEIGHT } from './consts';

const updateUrl = (to: string) => (window.location.href = to);

export type ProductCardProps = {
  product: Known<Product>;
  children: ReactNode;
};

export const ProductCard: FC<ProductCardProps> = ({ product, children }) => {
  const { logoExtended, downloadLink, appUrl, applicationId } = useProductProfile(product);
  const selectedProduct = useSelector(selectProductState(product));
  const { campaignData } = useCampaignData();

  const pricingUrl = `${PATH_PRODUCT.pricing}?applicationId=${applicationId}`;

  const dispatch = useDispatch();

  const handleClaimTrial = async () => {
    amplitude.track('Okres próbny rozpoczęty', {
      product,
    });
    dispatch(claimTrialAsyncThunk({ product, campaignData }));
  };

  const handlePurchase = () => {
    amplitude.track('Proces Zakupowy Rozpoczęty', {
      Product: product,
    });
    updateUrl(pricingUrl);
  };

  const handleOpen = async () => {
    await amplitude.track('Program Otwarty').promise;
    updateUrl(appUrl);
  };

  const handleDownloadPlikomat = () => {
    amplitude.track('Program Otwarty');
    updateUrl(appUrl + downloadLink);
  };

  const handleDownloadWpf = () => {
    amplitude.track('Program Otwarty');
    updateUrl(downloadLink || '');
  };

  const getActionButtons = () => {
    const isPurchaseButtonVisible =
      !selectedProduct?.subscription?.status || selectedProduct.subscription.status === 'InTrial';
    const isTrialAvailable = selectedProduct?.trialInfo.trialAvailability === 'Available';

    if (product === 'plikomat') return <DownloadButton onClick={handleDownloadPlikomat} />;
    if (product === 'wpf') {
      const isDownloadButtonVisible =
        selectedProduct?.subscription?.status === 'InTrial' ||
        selectedProduct?.subscription?.status === 'Active' ||
        selectedProduct?.subscription?.status === 'NonRenewing';

      return (
        <>
          {isTrialAvailable && <TrialButton onClick={handleClaimTrial} />}
          {isDownloadButtonVisible && <DownloadButton onClick={handleDownloadWpf} />}
          {isPurchaseButtonVisible && <PurchaseButton onClick={handlePurchase} />}
        </>
      );
    }

    const isOpenButtonVisible = !!selectedProduct?.subscription?.status;

    return (
      <>
        {isOpenButtonVisible && <OpenButton onClick={handleOpen} />}
        {isTrialAvailable && <TrialButton onClick={handleClaimTrial} />}
        {isPurchaseButtonVisible && <PurchaseButton onClick={handlePurchase} />}
      </>
    );
  };

  return (
    <Box
      component={Stack}
      sx={{
        backgroundColor: (theme) => theme.palette.primary.lighter,
        width: PRODUCT_CARD_WIDTH,
        minHeight: PRODUCT_CARD_HEIGHT,
        boxShadow: 'none',
        borderRadius: 1,
      }}
      gap={3}
    >
      <CardHeader sx={{ paddingTop: 4 }} avatar={<Box height={32}>{logoExtended}</Box>} />
      <Stack flexGrow={1} paddingX={3}>
        {children}
      </Stack>
      <Stack sx={{ paddingX: 3, paddingBottom: 4 }} direction="row" gap={1}>
        {getActionButtons()}
      </Stack>
    </Box>
  );
};
