import { FC } from 'react';
import { Stack } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import { getNavConfig } from './navConfig';
import { LogoutButton } from './LogoutButton';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { selectIsAdmin } from 'pl.curulis/modules/Authentication/';
import { useSelector } from 'react-redux';
import { MenuDivider } from './MenuDivider';
import { NavbarVariant } from '../../Navbar';
import { NavItemComponent } from 'pl.curulis/components/NavItemComponent';
import { useHubspotChat } from 'pl.curulis/modules/HubspotChat';

type MenuMainProps = {
  variant: NavbarVariant;
};

export const MenuMain: FC<MenuMainProps> = ({ variant }) => {
  const isAdmin = useSelector(selectIsAdmin);
  const navConfig = getNavConfig(isAdmin);

  const { toggleChat } = useHubspotChat();

  return (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        '& .simplebar-horizontal': { display: 'none' },
      }}
    >
      <Stack>
        {navConfig.map((item) => (
          <NavItemComponent key={item.title} item={item} variant={variant} />
        ))}
      </Stack>
      <Stack sx={{ marginTop: 'auto' }}>
        <NavItemComponent
          item={{
            title: 'Chat Live',
            onClick: toggleChat,
            icon: <ForumOutlinedIcon />,
          }}
          variant={variant}
        />
        <MenuDivider />
        <LogoutButton variant={variant} />
      </Stack>
    </Scrollbar>
  );
};
