import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthState } from '../slice/selectors';

type RequireAuthProps = {
  children: ReactNode;
};
export const RequireAuth = ({ children }: RequireAuthProps) => {
  const { isAuthenticated } = useSelector(selectAuthState);

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return null;
};
