import { NavigateOptions, useLocation, useNavigate } from 'react-router';
import { filterBlacklisted } from './blacklistedQueries';

type NavigateWithQueryOptions = Pick<NavigateOptions, 'replace'> & {
  customBlacklistedQueries?: string[];
};

const useNavigateWithQuery = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  return (hrefWithoutQuery: string, options: NavigateWithQueryOptions = {}) => {
    const { customBlacklistedQueries, ...navOptions } = options;
    return navigate(
      hrefWithoutQuery + filterBlacklisted(search, customBlacklistedQueries),
      navOptions
    );
  };
};

export { useNavigateWithQuery };
