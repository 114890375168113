import { FC } from 'react';

export const SwbLogo: FC = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 161 129"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M160.667 115.456V12.833C160.667 5.74274 154.913 0 147.809 0H89.6078C85.6196 0 81.6731 0.932936 78.1212 2.71588L63.2696 10.1172C59.697 11.9001 55.7712 12.833 51.7831 12.833H12.8575C5.75367 12.833 0 18.5758 0 25.6661V115.477C0 122.567 5.75367 128.31 12.8575 128.31H147.809C154.913 128.31 160.667 122.567 160.667 115.477V115.456Z"
      fill="#FA8544"
    />
    <path
      d="M126.768 89.9973C134.121 89.9973 138.4 92.8168 138.4 98.7876C138.4 104.758 134.121 107.599 126.768 107.599H122.053L124.94 113.383C125.564 114.648 126.872 115.456 128.305 115.456C139.854 115.456 147.81 109.299 147.81 98.8084C147.81 88.318 139.854 82.1606 128.305 82.1606H109.32L112.208 87.9448C112.831 89.2095 114.139 90.018 115.572 90.018H126.768V89.9973Z"
      fill="white"
    />
    <path
      d="M104.605 99.7415C97.2518 99.7415 92.9729 96.9219 92.9729 90.9512C92.9729 84.9804 97.2518 82.1401 104.605 82.1401H109.32L106.433 76.3559C105.789 75.0705 104.48 74.2827 103.068 74.2827C91.5189 74.2827 83.5635 80.4401 83.5635 90.9304C83.5635 101.421 91.5189 107.578 103.068 107.578H122.053L119.166 101.794C118.542 100.529 117.234 99.7208 115.801 99.7208H104.605V99.7415Z"
      fill="white"
    />
  </svg>
);
