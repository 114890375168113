import { useDismissibleDialog } from '../../../DismissibleDialog';

export const useUserExistDialog = () => {
  const { openDialog } = useDismissibleDialog();
  return () =>
    openDialog({
      title: 'Błąd tworzenia użytkownika',
      content: 'Istnieje już aktywny użytkownik o podanym adresie email.',
    });
};
