import {
  settingsUrlsDict,
  settingsUrlsConsts,
  type SettingsUrlsType,
} from '../consts/settingsUrls';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { TabsWithContent } from 'pl.curulis/components/TabsWithContent';
import { CustomerAddressesTabContent } from './CustomerAddresses/CustomerAddressesTabContent';
import { PasswordChangeTabContent } from './PasswordChange/PasswordChangeTabContent';
import { AppSettingsTabContent } from './AppSettings/AppSettingsTabContent';
import { Box } from '@mui/material';
import { PATH_APP } from 'pl.curulis/routes/paths';
import { useSelector } from 'react-redux';
import { selectIsAdmin } from 'pl.curulis/modules/Authentication';
import { NotificationTabContent } from './Notifications/NotificationTabContent';
import { nonAdminSettingsUrlsConsts } from '../consts/nonAdminSettingsUrlsConsts';

const TabContentWrapper: FC<PropsWithChildren> = ({ children }) => (
  <Box marginBottom={7}>{children}</Box>
);

export const Settings: FC = () => {
  const { settingsTab } = useParams();
  const isAdmin = useSelector(selectIsAdmin);

  const fallbackParam = isAdmin ? '' : 'bezpieczenstwo';
  const urlsConsts = isAdmin ? settingsUrlsConsts : nonAdminSettingsUrlsConsts;
  const currentTab = urlsConsts.find((url) => url === settingsTab) ?? fallbackParam;

  const contentTypeViewDict: Record<SettingsUrlsType, ReactNode> = {
    '': (
      <TabContentWrapper>
        <AppSettingsTabContent />
      </TabContentWrapper>
    ),
    bezpieczenstwo: (
      <TabContentWrapper>
        <PasswordChangeTabContent />
      </TabContentWrapper>
    ),
    powiadomienia: (
      <TabContentWrapper>
        <NotificationTabContent />
      </TabContentWrapper>
    ),
    rozliczenia: (
      <TabContentWrapper>
        <CustomerAddressesTabContent />
      </TabContentWrapper>
    ),
  };

  return (
    <TabsWithContent
      onChangePath={(tab) => `${PATH_APP.settings}/${tab}`}
      currentTab={currentTab}
      urlsConsts={urlsConsts}
      urlsDict={settingsUrlsDict}
      contentDict={contentTypeViewDict}
    />
  );
};
