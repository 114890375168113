import { PanelLayout } from 'pl.curulis/layouts/Menu/PanelLayout';
import { FetchSubscriptions } from 'pl.curulis/modules/productsSubscriptions/src/components/FetchSubscriptions';
import { DismissibleDialogProvider } from 'pl.curulis/modules/DismissibleDialog';
import { DismissibleSnackbarProvider } from 'pl.curulis/modules/DismissibleSnackbar';
import { FC } from 'react';
import ScrollToTop from 'components/ScrollToTop';
import { CampaignDataStore } from 'pl.curulis/modules/CampaignData';
import { FetchCustomerAddresses } from 'pl.curulis/modules/Settings';
import { HubProvider } from 'pl.curulis/modules/NotificationsHub';
import { AuthListeners } from 'pl.curulis/modules/Authentication/src/components/AuthListeners';

export const DashboardLayoutWrapper: FC = () => (
  <HubProvider>
    <CampaignDataStore>
      <DismissibleSnackbarProvider>
        <DismissibleDialogProvider>
          <FetchSubscriptions />
          <FetchCustomerAddresses />
          <ScrollToTop />
          <AuthListeners>
            <PanelLayout />
          </AuthListeners>
        </DismissibleDialogProvider>
      </DismissibleSnackbarProvider>
    </CampaignDataStore>
  </HubProvider>
);
