import { useEffect, useState } from 'react';

const usePreserver = <T>(volatile: T) => {
  const [preserved, setPreserved] = useState<T>();

  useEffect(() => {
    if (Boolean(volatile)) {
      setPreserved(volatile);
    }
  }, [volatile]);

  return preserved;
};

export { usePreserver };
