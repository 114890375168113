import { useState, useCallback, useRef, useEffect } from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useGridApiContext, GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { RowModel } from '../../UsersDataGrid';

export type MultiAutocompleteOption<T> = {
  id: T;
  label: string;
};

const areValuesEqual = (values1: string[] = [], values2: string[] = []) =>
  values1.length === values2.length && values1.every((val) => values2.includes(val));

type MultiAutocompleteCellProps<T> = {
  cell: GridRenderEditCellParams<RowModel, T[]>;
  onChange: (selectedValues: T[]) => void;
  options: MultiAutocompleteOption<T>[];
  getPlaceholder: (internalValue: T[], isOpen: boolean) => string;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function MultiAutocompleteCell<T extends string>({
  cell,
  onChange,
  options,
  getPlaceholder,
}: MultiAutocompleteCellProps<T>) {
  const { id, value = [], field, cellMode } = cell;
  const [internalValue, setInternalValue] = useState(value);
  const [open, setOpen] = useState(true);
  const gridApiRef = useGridApiContext();
  const elRef = useRef<HTMLElement>();

  const closeInput = useCallback(
    (event: MouseEvent | React.SyntheticEvent<Element, Event>) => {
      const targetEl = event.target as HTMLElement;

      if (!elRef.current?.contains(targetEl) && !targetEl.closest('.MuiAutocomplete-popper')) {
        event.stopPropagation();
        setOpen(false);

        if (areValuesEqual(value, internalValue)) {
          return;
        }

        cellMode === 'edit' &&
          gridApiRef.current.setEditCellValue({
            id,
            field,
            value: internalValue,
          });
        onChange(internalValue);
      }
    },
    [internalValue, value, onChange, gridApiRef, id, field, cellMode]
  );

  useEffect(() => {
    if (open) {
      window.addEventListener('click', closeInput, true);
    } else {
      window.removeEventListener('click', closeInput, true);
    }

    return () => window.removeEventListener('click', closeInput, true);
  }, [closeInput, open]);

  return (
    <Autocomplete
      noOptionsText="brak opcji"
      ref={elRef}
      sx={{
        width: '100%',
        height: '48px',
      }}
      open={open}
      multiple
      disableClearable
      disableCloseOnSelect
      options={options}
      renderTags={() => null}
      getOptionLabel={(option) => option.label}
      value={options.filter(({ id }) => internalValue?.includes(id))}
      onChange={(_event, selectedOptions) => setInternalValue(selectedOptions.map(({ id }) => id))}
      onOpen={() => setOpen(true)}
      onClose={closeInput}
      renderInput={(params) => (
        <TextField {...params} placeholder={getPlaceholder(internalValue ?? [], open)} />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
    />
  );
}
