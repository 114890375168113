import * as yup from 'yup';
import { validateNip } from 'pl.curulis/utils/validateNip';

type AddressFormContent = {
  organizationName?: string;
  address?: string;
  city?: string;
  zip?: string;
  nip?: string;
};

const hasAnyKey = (form: AddressFormContent): boolean =>
  !!(form?.organizationName ?? form?.address ?? form?.city ?? form?.zip ?? form?.nip);

const commonValidation = {
  organizationName: yup.string().required('Pole wymagane').max(250, 'Max 250 znaków'),
  address: yup.string().required('Pole wymagane').max(150, 'Max 150 znaków'),
  city: yup.string().required('Pole wymagane').max(50, 'Max 50 znaków'),
  zip: yup
    .string()
    .required('Pole wymagane')
    .matches(/^\d{2}-\d{3}$/, 'Niepoprawny kod pocztowy'),
};

const extendedValidation = {
  nip: yup
    .string()
    .required('Pole wymagane')
    .matches(/^\d{10}$/, 'Niepoprawny nr NIP')
    .test('nip-validator', 'Niepoprawny nr NIP', (value) => validateNip(value!)),
};

export const addressFormValidationSchema = yup.object().when({
  is: (form: AddressFormContent) => hasAnyKey(form),
  then: yup.object().shape(commonValidation),
});

export const addressFormValidationSchemaWithNip = yup.object().when({
  is: (form: AddressFormContent) => hasAnyKey(form),
  then: yup.object().shape({ ...commonValidation, ...extendedValidation }),
});

export const customerAddressesFormValidationSchema = yup.object({
  billing: addressFormValidationSchemaWithNip,
  shipping: addressFormValidationSchema,
});
