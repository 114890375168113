import { LoadingButton } from '@mui/lab';
import { FC, useState } from 'react';

type NewUserActionsCellProps = {
  handleSaveNewUser: VoidFunction;
  handleAbortAddingUser: VoidFunction;
};

export const NewUserActionsCell: FC<NewUserActionsCellProps> = ({
  handleSaveNewUser,
  handleAbortAddingUser,
}) => {
  const [isSavingNewUser, setIsSavingNewUser] = useState(false);

  const onSaveClick = async () => {
    if (isSavingNewUser) {
      return;
    }

    setIsSavingNewUser(true);

    try {
      await handleSaveNewUser();
    } finally {
      setIsSavingNewUser(false);
    }
  };

  return (
    <>
      <LoadingButton
        loading={isSavingNewUser}
        sx={{ marginRight: '10px' }}
        variant="contained"
        onClick={onSaveClick}
      >
        Zapisz
      </LoadingButton>
      <LoadingButton
        loading={isSavingNewUser}
        variant="contained"
        color="error"
        onClick={() => handleAbortAddingUser()}
      >
        Anuluj
      </LoadingButton>
    </>
  );
};
