import { Theme } from '@mui/material';

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.dropdown,
        },
        listbox: {
          padding: theme.spacing(0, 1),
          '& .MuiAutocomplete-option': {
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0),
            borderRadius: theme.shape.borderRadius,
          },
          '& [aria-selected=true]': {
            backgroundColor: `${theme.palette.action.active}20 !important`,
          },
          '& [aria-selected=true].Mui-focused': {
            backgroundColor: `${theme.palette.action.active}30 !important`,
          },
          '& [aria-selected=true].Mui-focusVisible': {
            backgroundColor: `${theme.palette.action.active}40 !important`,
          },
          '& .Mui-focused': {
            backgroundColor: `${theme.palette.primary.main}20 !important`,
          },
          '& .Mui-focusVisible': {
            backgroundColor: `${theme.palette.primary.main}30 !important`,
          },
        },
      },
    },
  };
}
