import {
  OrganizationMemberDto,
  arrayMapOrganizationMemberDtoToModel,
} from 'pl.curulis/models/OrganizationMember';
import { tom } from 'pl.curulis/modules/Axios';

export const getOrganizationMembersList = async () => {
  const response = await tom.get<OrganizationMemberDto[]>('users');
  return arrayMapOrganizationMemberDtoToModel(response.data);
};
