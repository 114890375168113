import { useRootSelector } from 'utils/useRootSelector';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Stack, Skeleton } from '@mui/material';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { useDismissibleSnackbar } from 'pl.curulis/modules/DismissibleSnackbar';
import { useEffect, useMemo } from 'react';
import { AppSettings, ValidUnitDeclension } from 'pl.curulis/modules/Settings/models';
import { UnitDeclensionForm } from './components/UnitDeclension/UnitDeclensionForm';
import { selectSettingsState } from '../../slice/selectors';
import { unitDeclensionValidationSchema } from '../../utils/appSettingsFormValidation';
import { TableThemeForm } from './components/TableTheme/TableThemeForm';
import { SettingsSection } from '../SettingsSection';
import { postDocumentSettingsAsyncThunk, postUnitDeclensionAsyncThunk } from '../../slice/thunks';
import { useBlockHasChanges } from '../../utils/useBlockHasChanges';
import { ButtonSection } from '../CustomerAddresses/components/ButtonsSection';

export const AppSettingsTabContent = () => {
  const dispatch = useDispatch();
  const {
    unitDeclension: savedUnitDeclension,
    documentSettings: savedDocumentsSettings,
    unitDeclensionFetchStatus,
    documentSettingsFetchStatus,
  } = useRootSelector(selectSettingsState);
  const { openSnackbar } = useDismissibleSnackbar();
  const defaultValues = useMemo(
    () => ({
      unitDeclension: savedUnitDeclension,
      documentSettings: savedDocumentsSettings,
    }),
    [savedDocumentsSettings, savedUnitDeclension]
  );

  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<AppSettings>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(unitDeclensionValidationSchema),
  });

  useBlockHasChanges(isDirty);

  useEffect(() => {
    // When values saved in store are changed we have to treat updated
    // values as new default to which `isDirty` is evaluated - it's
    // our new base for determining if user made changes.
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = async (currentValues: AppSettings) => {
    if (!isValid) {
      return;
    }

    const { unitDeclension, documentSettings } = currentValues;

    try {
      await Promise.all([
        dispatch(postUnitDeclensionAsyncThunk(unitDeclension as ValidUnitDeclension)).unwrap(),
        dispatch(postDocumentSettingsAsyncThunk(documentSettings)).unwrap(),
      ]);
      openSnackbar('Zmiany zapisane pomyślnie.', { severity: 'success' });
    } catch (err) {
      openSnackbar('Nie udało się zapisać zmian.', { severity: 'error' });
    }
  };

  if (unitDeclensionFetchStatus === 'loading' || documentSettingsFetchStatus === 'loading') {
    return <Skeleton height="100%" />;
  }

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} gap={5}>
      <SettingsSection
        title="Nazwy w jednostce"
        description="Nazwy w jednostce używane są w części opisowej generowanych dokumentów"
      >
        <UnitDeclensionForm control={control} register={register} errors={errors} />
      </SettingsSection>
      <SettingsSection
        title="Styl tabel"
        description="Styl tabel określa jakie nagłówki pojawią się w części tabelarycznej generowanych dokumentów"
      >
        <TableThemeForm control={control} />
      </SettingsSection>
      <ButtonSection
        disableDismiss={!isDirty}
        disableSubmit={!isDirty || !isValid || isSubmitting}
        onDismiss={() => reset()}
      />
    </Stack>
  );
};
