import { UsersDataGrid } from './UsersDataGrid';
import { UserUrlsType, userUrlsConsts, userUrlsDict } from '../utils/userUrls';
import { FC, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { OrganizationUnitsDataGrid } from './OrganizationUnitsDataGrid';
import { TabsWithContent } from 'pl.curulis/components/TabsWithContent';
import { PATH_APP } from '../../../../routes/paths';

export const TabPanel: FC = () => {
  const { usersTab } = useParams();

  const fallbackParam = userUrlsConsts[0];
  const currentTab = userUrlsConsts.find((url) => url === usersTab) ?? fallbackParam;

  const contentTypeViewDict: Record<UserUrlsType, ReactNode> = {
    '': <UsersDataGrid />,
    'jednostki-organizacyjne': <OrganizationUnitsDataGrid />,
  };

  return (
    <TabsWithContent
      onChangePath={(tab) => `${PATH_APP.users}/${tab}`}
      currentTab={currentTab}
      urlsConsts={userUrlsConsts}
      urlsDict={userUrlsDict}
      contentDict={contentTypeViewDict}
    />
  );
};
