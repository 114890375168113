import { FC, ReactNode } from 'react';
import { NavbarCompact } from './NavbarCompact';
import { NavbarDrawerFlyout } from './NavbarDrawerFlyout';
import { NavbarVariant } from '../../Navbar';

type NavbarWrapperProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  variant: NavbarVariant;
};

export const NavbarWrapper: FC<NavbarWrapperProps> = ({ children, isOpen, onClose, variant }) => {
  if (variant === 'flyout') {
    return (
      <NavbarDrawerFlyout isOpen={isOpen} onClose={onClose}>
        {children}
      </NavbarDrawerFlyout>
    );
  }

  return <NavbarCompact>{children}</NavbarCompact>;
};
