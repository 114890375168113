import { Theme } from '@mui/material';

// ----------------------------------------------------------------------

export default function IconButton(theme: Theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
