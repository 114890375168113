import { FC } from 'react';
import { Page } from 'pl.curulis/components/Page';
import { useOrganizationMembersFetch } from '../utils/useOrganizationMembersFetch';
import { useOrganizationUnitsFetch } from '../utils/useOrganizationUnitsFetch';
import { TabPanel } from '../components/TabPanel';
import { selectProductSubscription } from 'pl.curulis/modules/productsSubscriptions';
import { useSelector } from 'react-redux';
import { UsersDataGrid } from 'pl.curulis/modules/UserManagement/src/components/UsersDataGrid';
import { CenteringContainer } from 'pl.curulis/components/CenteringContainer';

export const UserManagementView: FC = () => {
  useOrganizationMembersFetch();
  useOrganizationUnitsFetch();

  const hasProductWithOrganizationUnits = !!useSelector(selectProductSubscription('best'));

  return (
    <Page title="Użytkownicy">
      {hasProductWithOrganizationUnits ? (
        <CenteringContainer isPaddingTop={false}>
          <TabPanel />
        </CenteringContainer>
      ) : (
        <CenteringContainer>
          <UsersDataGrid />
        </CenteringContainer>
      )}
    </Page>
  );
};
