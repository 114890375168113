import { FC } from 'react';
import { Stack } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { TableThemeCard } from './TableThemeCard';
import { TableDark } from 'pl.curulis/assets/TableDark';
import { TableLight } from 'pl.curulis/assets/TableLight';
import { AppSettings } from 'pl.curulis/modules/Settings/models';

type TableThemeFormProps = {
  control: Control<AppSettings, any>;
};

export const TableThemeForm: FC<TableThemeFormProps> = ({ control }) => (
  <Controller
    name="documentSettings.tableTheme"
    control={control}
    render={({ field }) => (
      <Stack spacing={2} direction="row">
        <TableThemeCard
          onClick={field.onChange}
          value={'Default'}
          isSelected={field.value === 'Default'}
        >
          <TableDark />
        </TableThemeCard>
        <TableThemeCard onClick={field.onChange} value={'Eco'} isSelected={field.value === 'Eco'}>
          <TableLight />
        </TableThemeCard>
      </Stack>
    )}
  />
);
