import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Flags, flags } from './flags';
import { RootState } from 'pl.curulis/redux/store';

type FlagsState = Record<Flags, boolean>;

const initialState = flags.reduce((result, flag) => {
  const storedValue = localStorage.getItem(flag);

  try {
    result[flag] = !!(storedValue && JSON.parse(storedValue));
  } catch (e) {
    console.error(e);
  }

  return result;
}, {} as FlagsState);

const slice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setFeatureFlag(
      state,
      action: PayloadAction<{
        flag: Flags;
        value: boolean;
      }>
    ) {
      const { flag, value } = action.payload;

      state[flag] = value;
      localStorage.setItem(flag, JSON.stringify(value));
    },
  },
});

const {
  actions: { setFeatureFlag },
  reducer: featureFlagsReducer,
} = slice;
const selectFeatureFlags = (state: RootState) => state.featureFlags;

export { setFeatureFlag, selectFeatureFlags, featureFlagsReducer };
