import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectProductsSubscriptions } from '../slice/selectors';
import { fetchSubscriptionsAsyncThunk } from '../slice/thunks';
import { useProductPricePoints } from 'pl.curulis/modules/Plans';

export const FetchSubscriptions = () => {
  useProductPricePoints();

  const dispatch = useDispatch();
  const { subscriptionsFetchStatus } = useSelector(selectProductsSubscriptions);

  useEffect(() => {
    if (subscriptionsFetchStatus === 'idle') {
      dispatch(fetchSubscriptionsAsyncThunk());
    }
  }, [dispatch, subscriptionsFetchStatus]);

  return null;
};
