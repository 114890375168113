import { Button, Stack, TextField, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { ErrorBadge } from 'pl.curulis/components/ErrorBadge';
import { RequestStatus } from 'pl.curulis/utils/api/requestStatus';
import { ChangeEvent, FC, FormEvent, useState } from 'react';
import { fetchCouponValidity } from '../api/fetchCouponValidity';
import { useCouponContext } from '../contexts/CouponContext';

export const CouponForm: FC = () => {
  const {
    couponId,
    hasNotClaimedCoupon,
    showCouponInput,
    setShowCouponInput,
    setCouponId,
    setCouponInfo,
  } = useCouponContext();

  const [requestStatus, setRequestStatus] = useState<RequestStatus>('idle');

  const executeFetchCouponValidity = async (couponId: string) => {
    setRequestStatus('loading');
    try {
      const couponInfo = await fetchCouponValidity(couponId);
      setCouponInfo(couponInfo);
      setRequestStatus('success');
    } catch {
      setRequestStatus('error');
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCouponId(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    executeFetchCouponValidity(couponId);
  };

  const isErrorOccurred = requestStatus === 'error';

  return (
    <Stack gap={1}>
      <FormControlLabel
        control={
          <Checkbox
            value={showCouponInput}
            onChange={(ev) => setShowCouponInput(ev.target.checked)}
          />
        }
        label="Mam kod rabatowy"
      />
      {showCouponInput && (
        <Stack component="form" direction="row" spacing={1} onSubmit={handleSubmit}>
          <ErrorBadge
            message="Kupon nieprawidłowy"
            visible={isErrorOccurred}
            bottomPosition="outer"
          >
            <TextField
              onChange={handleChange}
              value={couponId}
              label="Kupon rabatowy"
              error={isErrorOccurred}
              size="small"
              fullWidth
            />
          </ErrorBadge>
          <Button type="submit" variant="contained">
            Zastosuj
          </Button>
        </Stack>
      )}
      {hasNotClaimedCoupon && (
        <Typography fontSize="small" color="grey.600" width="80%">
          Pamiętaj aby zastosować kupon rabatowy w celu jego zaaplikowania przed sfinalizowaniem
          zakupu
        </Typography>
      )}
    </Stack>
  );
};
