import { Role } from 'pl.curulis/models/enums/Role';
import { tom } from 'pl.curulis/modules/Axios';
import {
  OrganizationMemberDto,
  mapOrganizationMemberDtoToModel,
} from 'pl.curulis/models/OrganizationMember';
import { Product } from 'pl.curulis/models/Product/src/enums/Product';

export type CreateOrganizationMemberRequestPayload = {
  email: string;
  applicationId: string | null;
  productRoles: { product: Product; role: Role }[];
};

export const createOrganizationMember = async (payload: CreateOrganizationMemberRequestPayload) => {
  const response = await tom.post<OrganizationMemberDto>('Users', payload);
  return mapOrganizationMemberDtoToModel(response.data);
};
