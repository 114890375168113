import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { tableThemeValueDict } from 'pl.curulis/modules/Settings/src/consts/tableTheme';
import { TableTheme } from 'pl.curulis/modules/Settings/models';

type TableThemeCardProps = PropsWithChildren<{
  isSelected: boolean;
  value: TableTheme;
  onClick: (val: TableTheme) => void;
}>;

export const TableThemeCard: FC<TableThemeCardProps> = ({
  children,
  isSelected,
  value,
  onClick,
}) => (
  <Card
    sx={(theme) => ({
      width: 112,
      height: 110,
      borderRadius: 0.5,
      ...(isSelected
        ? {
            backgroundColor: theme.palette.primary.lighter,
            boxShadow: `inset 0px 0px 0px 2px ${theme.palette.primary.dark}`,
          }
        : {
            boxShadow: `inset 0px 0px 0px 2px ${theme.palette.background.neutral}`,
          }),
    })}
  >
    <CardActionArea onClick={() => onClick(value)} sx={{ height: '100%' }}>
      <Stack height="100%" justifyContent="center" alignItems="center">
        <Box width={32} height={32}>
          {children}
        </Box>
        <Typography variant="body2" mt={1}>
          {tableThemeValueDict[value]}
        </Typography>
      </Stack>
    </CardActionArea>
  </Card>
);
