import { FC } from 'react';
import { Page } from 'pl.curulis/components/Page';
import { useSettingsFetch } from '../utils/useSettingsFetch';
import { CenteringContainer } from 'pl.curulis/components/CenteringContainer';
import { Settings } from '../components/Settings';

export const SettingsView: FC = () => {
  useSettingsFetch();

  return (
    <Page title="Ustawienia">
      <CenteringContainer isPaddingTop={false}>
        <Settings />
      </CenteringContainer>
    </Page>
  );
};
