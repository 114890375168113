import { selectConfig } from 'pl.curulis/modules/Configuration';
import { PATH_AUTH_ERROR } from 'pl.curulis/routes/paths';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { logoutUser } from '../slice/reducer';

export const useAuthMethods = () => {
  const {
    appUrl,
    services: { tom },
  } = useSelector(selectConfig);
  const { baseUrl: tomUrl } = tom;
  const dispatch = useDispatch();

  const login = useCallback(() => {
    window.location.href = `${tomUrl}/auth/login?returnUrl=${window.location}`;
  }, [tomUrl]);

  const logout = useCallback(() => {
    dispatch(logoutUser());
    window.location.href = `${tomUrl}/auth/logout?returnUrl=${appUrl}`;
  }, [tomUrl, appUrl, dispatch]);

  const openAuthErrorPage = useCallback(
    (error?: string) => {
      window.location.href = `${appUrl}/${PATH_AUTH_ERROR}${error ? '?error=' + error : ''}`;
    },
    [appUrl]
  );

  return { login, logout, openAuthErrorPage };
};
