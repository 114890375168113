import { useMediaQuery, useTheme } from '@mui/material';

type MediaType = 'largeDesktop' | 'smallDesktop' | 'mobile' | 'compact' | 'desktop';

const useRwd = () => {
  const { breakpoints } = useTheme();

  const isLargeDesktop = useMediaQuery(breakpoints.up('lg'));
  const isSmallDesktop = useMediaQuery(breakpoints.only('md'));
  const isDesktop = useMediaQuery(breakpoints.up('md'));
  const isCompact = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.only('xs'));

  let mediaType: MediaType;

  switch (true) {
    case isLargeDesktop:
      mediaType = 'largeDesktop';
      break;
    case isSmallDesktop:
      mediaType = 'smallDesktop';
      break;
    case isDesktop:
      mediaType = 'desktop';
      break;
    case isMobile:
      mediaType = 'mobile';
      break;
    default:
      mediaType = 'compact';
      break;
  }

  return { isLargeDesktop, isSmallDesktop, isCompact, isMobile, isDesktop, mediaType };
};

export { useRwd };
export type { MediaType };
