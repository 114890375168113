import {
  Tooltip,
  IconButton,
  Snackbar,
  SnackbarProps,
  Alert,
  AlertColor,
  Stack,
  Typography,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { usePreserver } from 'pl.curulis/utils/usePreserver';

type DismissibleSnackbarProps = Pick<SnackbarProps, 'open' | 'autoHideDuration'> & {
  dismissTooltip?: string;
  confirmTooltip?: string;
  severity?: AlertColor;
  onDismiss?: () => void;
  onConfirm?: () => void;
  onTimeout?: () => void;
  message?: string | ReactNode;
  actions?: ReactNode;
};

const DismissibleSnackbar: FC<DismissibleSnackbarProps> = ({
  open,
  dismissTooltip = 'Zamknij',
  confirmTooltip = 'Potwierdź',
  onDismiss,
  onTimeout = onDismiss,
  message,
  autoHideDuration = 6000,
  onConfirm,
  severity,
  actions,
}) => {
  const messagePreserved = usePreserver(message);

  const content =
    typeof message === 'string' ? <Typography>{messagePreserved}</Typography> : messagePreserved;

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onTimeout}>
      <Alert severity={severity} sx={{ alignItems: 'center' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {content}
          {Boolean(onConfirm) && Boolean(confirmTooltip) && (
            <Tooltip title={confirmTooltip!}>
              <IconButton size="small" onClick={onConfirm!}>
                <CheckIcon fontSize="medium" color={severity} />
              </IconButton>
            </Tooltip>
          )}
          <Stack spacing={1} pl={2} pr={1} direction="row">
            {actions}
          </Stack>
          <Tooltip title={dismissTooltip}>
            <IconButton size="small" onClick={onDismiss}>
              <CloseIcon fontSize="medium" color={severity} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Alert>
    </Snackbar>
  );
};

export { DismissibleSnackbar };
export type { DismissibleSnackbarProps };
