import { Product } from 'pl.curulis/models/enums/Product';
import { Profile } from '../models/Profile';
import { bestProfile } from './bestProfile';
import { defaultProfile } from './defaultProfile';
import { swbProfile } from './swbProfile';
import { wpfProfile } from './wpfProfile';
import { plikomatProfile } from './plikomatProfile';
import { umowyProfile } from './umowyProfile';

export const profiles: Record<Product, Profile> = {
  swb: swbProfile,
  best: bestProfile,
  wpf: wpfProfile,
  umowy: umowyProfile,
  plikomat: plikomatProfile,
  Unknown: defaultProfile,
};
