import { ConfigModel } from 'pl.curulis/models/Config';
import { ConfigProducts } from '../../../../models/Config/src/Products';
import { normalizeGuid } from '../../../../utils/normalizeGuid';

export const configMapper = (dto: ConfigModel): ConfigModel => ({
  ...dto,
  products: mapProducts(dto.products),
});

const mapProducts = (dtoProducts: ConfigProducts) => {
  Object.values(dtoProducts).forEach((product) => {
    product.applicationId = normalizeGuid(product.applicationId)!;
  });

  return dtoProducts;
};
