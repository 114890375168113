const createArrayMapper =
  <TOrigin, TDestination>(schema: (origin: TOrigin) => TDestination) =>
  (array: TOrigin[]) =>
    array.map(schema);

const createEnumMapper =
  <TOutput extends string | undefined>(consts: readonly TOutput[], fallbackValue: TOutput) =>
  (testedString: string | null | undefined) =>
    consts.find((value) => value?.toLowerCase() === testedString?.toLowerCase()) ?? fallbackValue;

const createDisplayValueGetter =
  <TModel extends string | number | symbol, TOutput = string>(dict: Record<TModel, TOutput>) =>
  (key: TModel | null | undefined) =>
    key ? dict[key] : null;

export { createArrayMapper, createEnumMapper, createDisplayValueGetter };
