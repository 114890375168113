import { ExecutiveAuthority } from '../../models';

export const executiveAuthorityConsts = [
  'CommuneMayor',
  'Mayor',
  'President',
  'CountyBoard',
  'VoivodeshipBoard',
] as const;

const executiveAuthorityDisplayValueDict: Record<ExecutiveAuthority, string> = {
  CommuneMayor: 'Wójt',
  Mayor: 'Burmistrz',
  President: 'Prezydent',
  CountyBoard: 'Zarząd Powiatu',
  VoivodeshipBoard: 'Zarząd Województwa',
};

export const executiveAuthorityOptions = executiveAuthorityConsts.map((executiveAuthority) => ({
  value: executiveAuthority,
  label: executiveAuthorityDisplayValueDict[executiveAuthority],
}));
