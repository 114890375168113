import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useDismissibleSnackbar } from 'pl.curulis/modules/DismissibleSnackbar';
import { postChangePassword } from '../../../api/postChangePassword';
import { useBlockHasChanges } from '../../../utils/useBlockHasChanges';
import { ButtonSection } from '../../CustomerAddresses/components/ButtonsSection';
import { TextfieldWithError } from 'pl.curulis/components/TextFieldWithError';
const schema = yup.object({
  currentPassword: yup.string().required('Wprowadź aktualne hasło'),
  newPassword: yup
    .string()
    .required('Wprowadź nowe hasło')
    .min(8, 'Hasło musi mieć minimum 8 znaków.')
    .max(64, 'Hasło może mieć maksymalnie 64 znaki.'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Hasła muszą się zgadzać')
    .required('Potwierdź nowe hasło'),
});

type Schema = InferType<typeof schema>;

export const PasswordChangeForm: FC = () => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<Schema>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  useBlockHasChanges(isDirty);

  const { openSnackbar } = useDismissibleSnackbar();

  const onSubmit = async (data: Schema) => {
    try {
      await postChangePassword({
        newPassword: data.newPassword,
        currentPassword: data.currentPassword,
      });
      openSnackbar('Hasło zaktualizowano pomyślnie.', { severity: 'success' });
    } catch (err) {
      if (err.status === 400 && err.type === 'PasswordNotMatch') {
        return openSnackbar('Aktualne hasło jest nieprawidłowe.', { severity: 'error' });
      }
      openSnackbar('Nie udało się zaktualizować hasła.', { severity: 'error' });
    } finally {
      reset();
    }
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} maxWidth={350} gap={5}>
      <Stack gap={2}>
        <TextfieldWithError
          {...register('currentPassword')}
          label="Aktualne hasło"
          type="password"
          autoComplete="new-password"
          errorMessage={errors.currentPassword?.message}
        />
        <TextfieldWithError
          {...register('newPassword')}
          label="Nowe hasło"
          type="password"
          autoComplete="new-password"
          errorMessage={errors.newPassword?.message}
        />
        <TextfieldWithError
          {...register('confirmNewPassword')}
          label="Potwierdź nowe hasło"
          type="password"
          autoComplete="new-password"
          errorMessage={errors.confirmNewPassword?.message}
        />
      </Stack>
      <ButtonSection
        disableDismiss={!isDirty}
        onDismiss={() => reset()}
        disableSubmit={isSubmitting || !isDirty}
      />
    </Stack>
  );
};
