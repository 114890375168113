export const subscriptionStatusConsts = [
  'InTrial',
  'Active',
  'NonRenewing',
  'Cancelled',
  'Paused',
  'Future',
] as const;
export type SubscriptionStatus = (typeof subscriptionStatusConsts)[number];

export const StatusDisplayValueDict: Record<SubscriptionStatus, string> = {
  NonRenewing: 'Aktywna (Odwołana)',
  Active: 'Aktywna',
  InTrial: 'Okres próbny',
  Cancelled: 'Anulowana',
  Paused: 'Wstrzymana',
  Future: 'Dostępna w przyszłości',
};
