import { useRootSelector } from 'utils/useRootSelector';
import { useCreateOrganizationMemberRequest } from './useCreateOrganizationMemberRequest';
import { useReactivateOrganizationMemberRequest } from './useReactivateOrganizationMemberRequest';
import { UserFormData } from './validate';
import { assignOrganizationUnits } from '../api/assignOrganizationUnits';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { invalidateOrganizationMembersFetch } from '../slice/reducer';
import { selectAllOrganizationMembers, selectAllOrganizationUnits } from '../slice/selectors';
import * as amplitude from '@amplitude/analytics-browser';
import { OrgUnit } from 'pl.curulis/models/OrganizationUnit';
import { useCallback } from 'react';
import { useUserExistDialog } from './useUserExistDialog';

const trackAmplitudeUserAdd = (addedUser: UserFormData, allOrganizationUnits: OrgUnit[]) => {
  const { userType, organizationUnitIds } = addedUser;

  if (userType === 'MainUnitUser') {
    return;
  }

  const organizationUnitsMap = new Map(allOrganizationUnits.map((unit) => [unit.constId, unit]));
  const departamentsCount = organizationUnitIds.filter(
    (id) => organizationUnitsMap.get(id)?.type === 'DEP'
  ).length;
  const primaryUnitsCount = organizationUnitIds.length - departamentsCount;

  if (primaryUnitsCount) {
    amplitude.track('Konto JO Utworzone', { 'Unit Type': 'Jednostka Organizacyjna' });
  }

  if (departamentsCount) {
    amplitude.track('Konto JO Utworzone', { 'Unit Type': 'Dysponent' });
  }
};

export const useAddUser = () => {
  const allUsers = useRootSelector(selectAllOrganizationMembers);
  const allOrganizationUnits = useRootSelector(selectAllOrganizationUnits);
  const { executeCreateOrganizationMember } = useCreateOrganizationMemberRequest();
  const { executeReactivateOrganizationMember } = useReactivateOrganizationMemberRequest();
  const dispatch = useDispatch();
  const userExistsDialog = useUserExistDialog();

  const addUser = useCallback(
    async (form: UserFormData) => {
      const existingUser = allUsers.find((user) => user.email === form.email);

      const user = existingUser || (await executeCreateOrganizationMember(form));

      if (existingUser?.isDisabled) {
        await executeReactivateOrganizationMember(existingUser.userId);
      }

      if (existingUser && !existingUser.isDisabled) {
        userExistsDialog();

        return false;
      }

      if (!user) {
        return false;
      }

      if (existingUser?.userType === 'OrganizationUnitUser' && form.userType === 'MainUnitUser') {
        await assignOrganizationUnits(user.userId, []);
      } else if (form.userType === 'OrganizationUnitUser') {
        await assignOrganizationUnits(user.userId, form.organizationUnitIds);
      }

      trackAmplitudeUserAdd(form, allOrganizationUnits);

      dispatch(invalidateOrganizationMembersFetch());

      return true;
    },
    [
      userExistsDialog,
      allUsers,
      allOrganizationUnits,
      dispatch,
      executeCreateOrganizationMember,
      executeReactivateOrganizationMember,
    ]
  );

  return { addUser };
};
