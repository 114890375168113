import { styled, Box, BoxProps } from '@mui/material';

type CenteringContainerProps = {
  isPaddingTop?: boolean;
} & BoxProps;

export const CenteringContainer = styled(Box)<CenteringContainerProps>(
  ({ theme, isPaddingTop = true }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
    paddingTop: isPaddingTop ? 63 : 0,
    paddingLeft: 63,
    [theme.breakpoints.down('sm')]: { padding: 5 },
    minHeight: '100%',
  })
);
