import { createSlice } from '@reduxjs/toolkit';
import { fetchPlansAsyncThunk } from './thunks';
import { RequestStatus } from 'pl.curulis/utils/api/requestStatus';
import { ProductPlan } from 'pl.curulis/models/Product';

type PricePointsState = {
  pricePoints: ProductPlan[];
  status: RequestStatus;
};

const initialState: PricePointsState = {
  pricePoints: [],
  status: 'idle',
};

const slice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    invalidatePlansFetch: (state) => {
      state.status = 'idle';
    },
    clearPlans: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchPlansAsyncThunk.fulfilled, (state, action) => {
        action.payload.sort(sortDescendingByPrice);

        state.pricePoints = action.payload;
        state.status = 'success';
      })
      .addCase(fetchPlansAsyncThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlansAsyncThunk.rejected, (state) => {
        state.status = 'error';
      }),
});

const sortDescendingByPrice = (a: ProductPlan, b: ProductPlan) =>
  b.monthlyNetValue - a.monthlyNetValue;

const { actions, reducer } = slice;
export { reducer, actions };
