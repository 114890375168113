import { Theme } from '@mui/material';

export const datagridStyles = ({ theme }: { theme: Theme }) => ({
  '& .MuiDataGrid-row .MuiDataGrid-cell:focus-within': { outline: 'none' },
  '& .MuiDataGrid-row .MuiOutlinedInput-root .MuiInputBase-input': { padding: '13px' },
  '& .MuiDataGrid-row .MuiOutlinedInput-root .MuiAutocomplete-input': { padding: '4px' },
  ':hover': { backgroundColor: 'none' },
  '& .MuiDataGrid-row': {
    backgroundColor: theme.palette.background.light,
  },
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-row.Mui-hovered': {
    backgroundColor: theme.palette.background.light,
  },
  '& .MuiDataGrid-row.Mui-hovered:nth-of-type(odd)': {
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: theme.palette.background.light,
  },
  '& .MuiDataGrid-row:nth-of-type(odd):hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-row.Mui-selected': { backgroundColor: theme.palette.background.light },
  '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd)': {
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-row.Mui-selected.Mui-hovered': {
    backgroundColor: theme.palette.background.light,
  },
  '& .MuiDataGrid-row.Mui-selected.Mui-hovered:nth-of-type(odd)': {
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-row.Mui-selected:hover': { backgroundColor: theme.palette.background.light },
  '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd):hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
    boxShadow: 'none',
    backgroundColor: 'inherit',
    padding: '16px 10px',
  },
  '& .MuiDataGrid-pinnedRowsRenderZone .MuiDataGrid-row': {
    backgroundColor: theme.palette.background.default,
  },
  '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor': {
    borderColor: theme.palette.primary.light,
  },
  '.MuiDataGrid-cell': { padding: '16px 10px' },
});
