import { createSelector } from '@reduxjs/toolkit';
import { Role } from 'pl.curulis/models/enums/Role';
import { RootState } from 'pl.curulis/redux/store';

export const selectAuthState = (state: RootState) => state.auth;

export const selectAuthenticatedUser = (state: RootState) => state.auth.authenticatedUser;

export const selectIsImpersonating = createSelector(
  selectAuthenticatedUser,
  (authenticatedUser) =>
    authenticatedUser &&
    authenticatedUser.organizationGuid !== authenticatedUser.userOrganizationGuid
);

export const selectIsAdmin = createSelector(selectAuthenticatedUser, (authenticatedUser) => {
  const adminRoles: Role[] = ['SA', 'Administrator'];
  return !!(authenticatedUser && adminRoles.includes(authenticatedUser.ssoRole));
});
