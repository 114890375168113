import { FC } from 'react';
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';

type ButtonProps = {
  onClick: () => void;
};

export const DownloadButton: FC<ButtonProps> = ({ onClick }) => (
  <Button startIcon={<BrowserUpdatedIcon />} variant="outlined" onClick={onClick}>
    Pobierz
  </Button>
);

export const PurchaseButton: FC<ButtonProps> = ({ onClick }) => (
  <Button variant="outlined" onClick={onClick}>
    Kup
  </Button>
);

export const TrialButton: FC<ButtonProps> = ({ onClick }) => (
  <Button onClick={onClick} variant="outlined">
    Wypróbuj
  </Button>
);

export const OpenButton: FC<ButtonProps> = ({ onClick }) => (
  <Button startIcon={<OpenInNewIcon />} onClick={onClick} variant="contained">
    Otwórz
  </Button>
);
