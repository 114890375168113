import { FC, useEffect, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { fetchConfig } from './slice/actions';
import { selectConfigState } from './slice/selectors';
import { getRandomUUID } from 'utils/getRandomUUID';

const configPath =
  (process.env.NODE_ENV === 'production' ? '/js/config.json' : '/js/config-local.json') +
  '?v=' +
  getRandomUUID();

type ConfigProviderProps = {
  children: ReactNode;
};

const ConfigProvider: FC<ConfigProviderProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { isReady } = useSelector(selectConfigState);

  useEffect(() => {
    dispatch(fetchConfig(configPath));
  }, [dispatch]);

  if (!isReady) return null;

  return <>{children}</>;
};

export { ConfigProvider };
