import { Button, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useCouponContext } from '../contexts/CouponContext';
import { LoadingButton } from '@mui/lab';

type CheckoutButtonsProps = {
  isAddressValid: boolean;
  onClose: VoidFunction;
  onSubmit: () => Promise<void>;
};

export const CheckoutButtons: FC<CheckoutButtonsProps> = ({
  isAddressValid,
  onClose,
  onSubmit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { hasNotClaimedCoupon } = useCouponContext();

  const onClick = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Stack direction="row" justifyContent="end" spacing={1} mt={5}>
      <Button onClick={onClose}>Anuluj</Button>
      <LoadingButton
        onClick={onClick}
        variant="contained"
        loading={isSubmitting}
        disabled={hasNotClaimedCoupon || !isAddressValid}
      >
        Zamów
      </LoadingButton>
    </Stack>
  );
};
