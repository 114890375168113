import { DecisionMakingAuthority } from '../../models';

export const decisionMakingAuthorityConsts = [
  'UrbanCouncil',
  'CommuneCouncil',
  'TownAndCommuneCouncil',
  'CommuneAndTownCouncil',
  'TownCouncil',
  'CountyCouncil',
  'VoivodeshipAssembly',
] as const;

const decisionMakingAuthorityDisplayValueDict: Record<DecisionMakingAuthority, string> = {
  UrbanCouncil: 'Rada Miejska',
  CommuneCouncil: 'Rada Gminy',
  TownAndCommuneCouncil: 'Rada Miasta i Gminy',
  CommuneAndTownCouncil: 'Rada Gminy i Miasta',
  TownCouncil: 'Rada Miasta',
  CountyCouncil: 'Rada Powiatu',
  VoivodeshipAssembly: 'Sejmik Województwa',
};

export const decisionMakingAuthorityOptions = decisionMakingAuthorityConsts.map(
  (decisionMakingAuthority) => ({
    value: decisionMakingAuthority,
    label: decisionMakingAuthorityDisplayValueDict[decisionMakingAuthority],
  })
);
