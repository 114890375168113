import { bolek } from 'pl.curulis/modules/Axios';
import {
  OrganizationUnitDto,
  OrgUnitCollection,
  arrayMapOrgUnitDtoToModel,
} from 'pl.curulis/models/OrganizationUnit';

export type GetOrganizationUnitsPayload = {
  units: OrganizationUnitDto[];
};

export const getOrganizationUnits = async (organizationId: string): Promise<OrgUnitCollection> => {
  const response = await bolek.get<GetOrganizationUnitsPayload>(`/units/${organizationId}`);

  return arrayMapOrgUnitDtoToModel(response.data.units);
};
