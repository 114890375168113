import { pickBy } from 'lodash';

export const FORCE_LOGOUT_QUERY = 'forceLogout';

const filterBlacklisted = (search: string | null, customBlacklistedQueries: string[] = []) => {
  if (!search) return '';

  const searchSegments = search.slice(1).split('&');
  const searchSegmentsDict: Record<string, string> = Object.fromEntries(
    searchSegments.map((segment) => segment.split('='))
  );
  const filteredSegmentsDict = pickBy(
    searchSegmentsDict,
    (_value, key) => ![FORCE_LOGOUT_QUERY, ...customBlacklistedQueries].includes(key)
  );

  return '?' + new URLSearchParams(filteredSegmentsDict).toString();
};

export { filterBlacklisted };
