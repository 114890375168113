import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { useEffect } from 'react';
import { useRootSelector } from 'utils/useRootSelector';
import { selectPlansStatus } from '../slice/selectors';
import { fetchPlansAsyncThunk } from '../slice/thunks';

const useProductPricePoints = () => {
  const dispatch = useDispatch();
  const fetchStatus = useRootSelector(selectPlansStatus);

  useEffect(() => {
    if (fetchStatus === 'idle') {
      dispatch(fetchPlansAsyncThunk());
    }
  }, [dispatch, fetchStatus]);

  return { fetchStatus };
};

export { useProductPricePoints };
