import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { PATH_APP } from 'pl.curulis/routes';
import { Navigate, useLocation } from 'react-router-dom';
import { selectIsAdmin } from 'pl.curulis/modules/Authentication';
import { nonAdminSettingsUrlsConsts } from '../consts/nonAdminSettingsUrlsConsts';

export const SettingsAuth: FC<{ children: ReactNode }> = ({ children }) => {
  const isAdmin = useSelector(selectIsAdmin);
  const { pathname } = useLocation();
  const isAlreadyRedirected = nonAdminSettingsUrlsConsts.some((e) => pathname.includes(e));
  if (!isAdmin && !isAlreadyRedirected)
    return <Navigate to={`${PATH_APP.settings}/bezpieczenstwo`} replace />;

  return <>{children}</>;
};
