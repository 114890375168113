import { Skeleton, Stack, Typography } from '@mui/material';
import { selectProductsSubscriptions } from 'pl.curulis/modules/productsSubscriptions/src/slice/selectors';
import { WelcomeProductCard } from 'pl.curulis/modules/Product/src/components';
import { useSelector } from 'react-redux';
import {
  PRODUCT_CARD_HEIGHT,
  PRODUCT_CARD_WIDTH,
} from 'pl.curulis/modules/Product/src/components/ProductCard/consts';
import { selectAuthenticatedUser, selectIsAdmin } from '../../../../modules/Authentication';

const NUMBER_OF_PRODUCTS = 5;

export const Products = () => {
  const { products, subscriptionsFetchStatus } = useSelector(selectProductsSubscriptions);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const isAdmin = useSelector(selectIsAdmin);

  if (!products && subscriptionsFetchStatus === 'loading')
    return (
      <Stack direction="row" flexWrap="wrap" gap={2} mt={12}>
        {Array.from(Array(NUMBER_OF_PRODUCTS)).map((_skeleton, index) => (
          <Skeleton
            key={index}
            variant="rounded"
            width={PRODUCT_CARD_WIDTH}
            height={PRODUCT_CARD_HEIGHT}
          />
        ))}
      </Stack>
    );
  if (!products) return null;

  const filteredProducts = isAdmin ? products : products.filter((product) => product.subscription);

  return (
    <Stack>
      <Stack mb={3}>
        {authenticatedUser?.organizationName ? (
          <Typography variant="h3">Witaj {authenticatedUser?.organizationName}</Typography>
        ) : (
          <Typography variant="h3">Twoje konto Publink</Typography>
        )}
        <Typography variant="body1">Zarządzaj swoimi subskrypcjami i ustawieniami</Typography>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={2}>
        {filteredProducts.map(({ product }) => (
          <WelcomeProductCard key={product} product={product} />
        ))}
        <WelcomeProductCard product="plikomat" />
      </Stack>
    </Stack>
  );
};
