import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUserManagementState } from '../slice/selectors';
import { getOrganizationMembersListAsyncThunk } from '../slice/thunks';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { useDismissibleDialog } from 'pl.curulis/modules/DismissibleDialog';

export const useOrganizationMembersFetch = () => {
  const { organizationMembersStatus } = useSelector(selectUserManagementState);
  const { openDialog } = useDismissibleDialog();
  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationMembersStatus === 'idle') {
      dispatch(getOrganizationMembersListAsyncThunk());
    } else if (organizationMembersStatus === 'error') {
      openDialog({ title: 'Nie udało się pobrać listy użytkowników Twojej organizacji.' });
    }
  }, [dispatch, openDialog, organizationMembersStatus]);
};
