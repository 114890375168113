import { createContext } from 'react';
import { DismissibleSnackbarProps } from '../components/DismissibleSnackbar';

type DismissibleSnackbarContextType = {
  openSnackbar: (props: DismissibleSnackbarProps) => void;
  closeSnackbar: () => void;
  isOpen: boolean;
};

const DismissibleSnackbarContext = createContext<DismissibleSnackbarContextType>({
  openSnackbar: () => null,
  closeSnackbar: () => null,
  isOpen: false,
});

export { DismissibleSnackbarContext };
