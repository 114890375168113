import { FC } from 'react';
import {
  OrganizationMemberModel,
  UserType,
  userTypeLabels,
} from 'pl.curulis/models/OrganizationMember/src/OrganizationMemberModel';
import { ColorSchema } from 'pl.curulis/theme/types';
import { StyledChip } from '../base/StyledChip';

const chipColors: Record<UserType, ColorSchema> = {
  MainUnitUser: 'info',
  OrganizationUnitUser: 'success',
};

const getChipProps = (user: OrganizationMemberModel) => ({
  color: chipColors[user.userType],
  label: userTypeLabels[user.userType],
});

export const UserTypeDisplayCell: FC<{ user: OrganizationMemberModel }> = ({ user }) => (
  <StyledChip
    sx={{
      width: '16rem',
      '&:hover': {
        cursor: 'pointer',
      },
    }}
    {...getChipProps(user)}
  />
);
