import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'pl.curulis/modules/Authentication';
import * as amplitude from '@amplitude/analytics-browser';
import { selectConfig } from 'pl.curulis/modules/Configuration';

declare const window: Window & { dataLayer: Record<string, unknown>[] };

export const ExternalIntegrations: FC = () => {
  const user = useSelector(selectAuthenticatedUser);
  const { amplitudeKey } = useSelector(selectConfig);

  useEffect(() => {
    if (user?.userGuid) {
      // used by user Guiding
      window.dataLayer.push({
        loggedUserData: {
          id: user.userGuid,
          email: user.userEmail,
          organizationName: user.organizationName,
        },
      });
      window.dataLayer.push({ event: 'user-data-set' });
    }
  }, [user]);

  useEffect(() => {
    if (user?.userGuid && amplitudeKey) {
      amplitude.init(amplitudeKey, {
        defaultTracking: {
          pageViews: true,
          sessions: true,
          fileDownloads: true,
        },
      });

      amplitude.setUserId(user.userEmail);
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('Logged User ID', user.userGuid);
      identifyEvent.set('User Role', user.userType === 'MainUnitUser' ? 'Skarbnik' : 'Księgowy');
      identifyEvent.set('Organization', user.organizationName);
      amplitude.identify(identifyEvent);
    }
  }, [amplitudeKey, user]);

  return null;
};
