import { Product } from 'pl.curulis/models/enums/Product';
import { Known } from 'pl.curulis/models/utils';
import { tom } from 'pl.curulis/modules/Axios';
import { CampaignData } from 'pl.curulis/models/CampaignData';

type ClaimTrialType = {
  product: Known<Product>;
  campaignData: CampaignData | null;
};

const claimTrial = async ({ product, campaignData }: ClaimTrialType) => {
  const response = await tom.post(`checkout/${product}/trial/create`, campaignData);

  return response.data;
};

export { claimTrial };
