import { Helmet } from 'react-helmet-async';
import { FC, forwardRef, ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';

type PageProps = {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
} & BoxProps;

const Page: FC<PageProps> = forwardRef(({ children, title = '', meta, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title} | Konto Publink`}</title>
      {meta}
    </Helmet>

    <Box ref={ref} sx={{ height: '100%' }} {...other}>
      {children}
    </Box>
  </>
));

export { Page };
