import { useContext, useEffect } from 'react';
import { HubContext } from './HubContext';

const useNotificationsHub = <TMessage>(
  eventName: string,
  onMessage: (message: TMessage) => void
) => {
  const { connection } = useContext(HubContext);

  useEffect(() => {
    if (connection) {
      (async () => {
        connection.on(eventName, onMessage);
      })();

      return () => {
        connection.off(eventName, onMessage);
      };
    }
  }, [connection, eventName, onMessage]);
};

export { useNotificationsHub };
