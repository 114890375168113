import { Theme } from '@mui/material';
//
import { CloseIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Chip(theme: Theme) {
  return {
    MuiChip: {
      defaultProps: {
        deleteIcon: <CloseIcon />,
      },

      styleOverrides: {
        outlined: {
          '&.MuiChip-colorPrimary': {
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
          },
          '&.MuiChip-colorSecondary': {
            borderColor: theme.palette.secondary.main,
          },
          '&.MuiChip-colorDefault': {
            borderColor: theme.palette.border.strong,
            '&.MuiChip-icon, .MuiChip-iconMedium': {
              backgroundColor: theme.palette.border.lighter,
              color: theme.palette.border.stronger,
            },
            '& .MuiChip-avatarMedium, .MuiChip-avatarSmall': {
              color: theme.palette.text.secondary,
            },
          },
          '&.MuiChip-colorInfo': {
            borderColor: theme.palette.info.light,
            '&.MuiChip-icon, .MuiChip-iconMedium': {
              backgroundColor: theme.palette.info.lighter,
              color: theme.palette.info.darker,
            },
          },
          '&.MuiChip-colorError': {
            borderColor: theme.palette.error.light,
            '&.MuiChip-icon, .MuiChip-iconMedium': {
              backgroundColor: theme.palette.error.lighter,
              color: theme.palette.error.darker,
            },
          },
        },
        filled: {
          '&.MuiChip-colorPrimary': {
            backgroundColor: theme.palette.primary.dark,
          },
          '&.MuiChip-colorInfo': {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.info.light,
          },
          '&.MuiChip-colorSuccess': {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.success.light,
          },
          '&.MuiChip-colorWarning': {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.warning.light,
          },
          '&.MuiChip-colorError': {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.error.light,
          },
        },
      },
    },
  };
}
