import { DataGrid, GridRowClassNameParams, plPL } from 'pl.curulis/modules/DataGrid';
import { OrganizationMemberModel } from 'pl.curulis/models/OrganizationMember';
import { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Skeleton, Stack, styled } from '@mui/material';
import { UserType } from 'pl.curulis/models/OrganizationMember/src/OrganizationMemberModel';
import { useSelector } from 'react-redux';
import { selectActiveOrganizationMembers, selectUserManagementState } from '../slice/selectors';
import { useUsersColumns } from '../utils/useUsersColumns';
import { AddButton } from './AddButton';
import { keyCodes } from 'pl.curulis/utils/keyCodes';
import { validateUserData } from '../utils/validate';
import { useDismissibleDialog } from 'pl.curulis/modules/DismissibleDialog';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { editUser, invalidateOrganizationMembersFetch } from '../slice/reducer';
import { assignOrganizationUnits } from '../api/assignOrganizationUnits';
import { useAddUser } from '../utils/useAddUser';
import { GridCellParams } from '@mui/x-data-grid-premium';
import * as amplitude from '@amplitude/analytics-browser';
import { datagridStyles } from 'pl.curulis/modules/UserManagement/src/utils/datagridStyles';
import { useApiRef } from 'pl.curulis/modules/UserManagement/src/utils/useApiRef';

const trackAmplitudeUserEdit = (
  userBeforeEdit: OrganizationMemberModel,
  userAfterEdit: OrganizationMemberModel
) => {
  const { userType: prevUserType, organizationUnitIds: prevOrgUnitIds } = userBeforeEdit;
  const { userType: newUserType, organizationUnitIds: newOrgUnitIds } = userAfterEdit;

  if (
    // When changing userType from `OrganizationUnitUser` to `MainUnitUser`, type change
    // always happen, but when changing from `MainUnitUser` to `OrganizationUnitUser`,
    // change in data will only happen once some org units have been assigned.
    (prevUserType === 'OrganizationUnitUser' && newUserType === 'MainUnitUser') ||
    (prevUserType === 'MainUnitUser' &&
      newUserType === 'OrganizationUnitUser' &&
      !!newOrgUnitIds.length) ||
    (!prevOrgUnitIds.length && !!newOrgUnitIds.length)
  ) {
    amplitude.track('Rola Użytkownika Zmieniona');

    return;
  }

  if (!!prevOrgUnitIds.length && !!newOrgUnitIds.length) {
    amplitude.track('Użytkownik Przypisany do Jednostki');
  }
};

export type NewUserRowModel = {
  userId: string;
  email: string;
  userType: UserType;
  organizationUnitIds: string[];
  isNewUserRow: true;
};

export type RowModel = NewUserRowModel | OrganizationMemberModel;

export function isNewUserRow(
  row: NewUserRowModel | OrganizationMemberModel
): row is NewUserRowModel {
  return 'isNewUserRow' in row;
}

export const UsersDataGrid: FC = () => {
  const dispatch = useDispatch();
  const { organizationUnitsStatus, organizationMembersStatus } =
    useSelector(selectUserManagementState);
  const gridApiRef = useApiRef();
  const activeUsers = useSelector(selectActiveOrganizationMembers);
  const { addUser } = useAddUser();
  const { openDialog } = useDismissibleDialog();
  const [newUserTemporaryRow, setNewUserTemporaryRow] = useState<NewUserRowModel | null>(null);
  const rows = newUserTemporaryRow ? [newUserTemporaryRow, ...activeUsers] : activeUsers;

  const handleNewUserChange = useCallback(
    async <T extends keyof NewUserRowModel>(field: T, value: NewUserRowModel[T]) => {
      setNewUserTemporaryRow((current) =>
        current
          ? {
              ...current,
              [field]: value,
            }
          : null
      );
    },
    []
  );

  const handleExistingUserChange = useCallback(
    async <T extends keyof OrganizationMemberModel>(
      user: OrganizationMemberModel,
      field: T,
      value: OrganizationMemberModel[T]
    ) => {
      const userAfterChange: OrganizationMemberModel = {
        ...user,
        [field]: value,
      };

      if (field === 'organizationUnitIds') {
        // We can only edit `userType` or `organizationUnitIds`
        // `userType` itself is always valid, if `userType` is changed to
        // `OrganizationUnitUser` and there is no oganization units selected,
        // organization units cell will communicate the need to select units and
        // after the selection this function will be invoked again and then it
        // will make sense to validate.
        try {
          await validateUserData({
            email: userAfterChange.email,
            userType: userAfterChange.userType,
            organizationUnitIds: userAfterChange.organizationUnitIds,
          });
        } catch (err) {
          openDialog({
            content: err.message,
          });

          return;
        }
      }

      dispatch(editUser(userAfterChange));

      const { userId, organizationUnitIds } = userAfterChange;
      const cellMode = gridApiRef.current.getCellMode(userId, field);

      cellMode === 'edit' &&
        gridApiRef.current.stopCellEditMode({
          id: userId,
          field,
        });

      try {
        if (field === 'userType' && value === 'MainUnitUser') {
          // Editing `userType` is only a frontend layer for differentiating
          // between user with organization units and without them. When setting it
          // to `MainUnitUser`, we will clear org units on backend, but frontend store
          // will still keep information about org units, so that user can go back to
          // previous state if changing the type was a mistake.
          await assignOrganizationUnits(userId, []);
        } else if (
          field === 'organizationUnitIds' ||
          (field === 'userType' && value === 'OrganizationUnitUser' && !!organizationUnitIds.length)
        ) {
          await assignOrganizationUnits(userId, organizationUnitIds);
        } else if (
          field === 'userType' &&
          value === 'OrganizationUnitUser' &&
          !organizationUnitIds.length
        ) {
          // setTimeout, so that userType is updated in grid and cell is recognized as editable
          setTimeout(() =>
            gridApiRef.current.startCellEditMode({
              id: userId,
              field: 'organizationUnitIds',
            })
          );
        }
        trackAmplitudeUserEdit(user, userAfterChange);
      } catch (err) {
        openDialog({ title: `Edycja użytkownika ${userAfterChange.email} nie powiodła się.` });
        dispatch(invalidateOrganizationMembersFetch());
      }
    },
    [dispatch, gridApiRef, openDialog]
  );

  const handleSaveNewUser = useCallback(async () => {
    if (!newUserTemporaryRow) {
      return;
    }

    const userFormData = {
      email: newUserTemporaryRow.email,
      userType: newUserTemporaryRow.userType,
      organizationUnitIds: newUserTemporaryRow.organizationUnitIds,
    };

    try {
      await validateUserData(userFormData);
    } catch (err) {
      openDialog({
        content: err.message,
      });

      return;
    }

    await addUser(userFormData);

    setNewUserTemporaryRow(null);
  }, [addUser, openDialog, newUserTemporaryRow]);

  const handleAbortAddingUser = useCallback(() => {
    setNewUserTemporaryRow(null);
  }, []);

  const columns = useUsersColumns({
    handleNewUserChange,
    handleExistingUserChange,
    handleSaveNewUser,
    handleAbortAddingUser,
  });

  const handleKeydown = useCallback(
    (ev: KeyboardEvent) => {
      if (
        document.querySelector('.MuiAutocomplete-popper') || // autocomplete popper is displayed
        document.querySelector('.MuiDialog-container') // dialog is displayed
      ) {
        return;
      }

      if (ev.key === keyCodes.ESCAPE) {
        handleAbortAddingUser();
      }

      if (ev.key === keyCodes.ENTER) {
        handleSaveNewUser();
      }
    },
    [handleAbortAddingUser, handleSaveNewUser]
  );

  const handleAddUser = () => {
    setNewUserTemporaryRow({
      userId: crypto.randomUUID(), // temporary id, for grid to handle the row
      email: '',
      userType: 'MainUnitUser',
      organizationUnitIds: [],
      isNewUserRow: true,
    });
  };

  useEffect(() => {
    if (newUserTemporaryRow) {
      // capture true, so that `handleKeydown` is fired before
      // popper or dialog is closed and early return conditon
      // in `handleKeydown` can prevent submitting/aborting
      window.addEventListener('keydown', handleKeydown, true);
    }

    return () => window.removeEventListener('keydown', handleKeydown, true);
  }, [newUserTemporaryRow, handleKeydown]);

  useEffect(() => {
    if (newUserTemporaryRow) {
      gridApiRef.current.scroll({ top: 0 });
    }
  }, [newUserTemporaryRow, gridApiRef]);

  if (organizationMembersStatus === 'loading' || organizationUnitsStatus === 'loading') {
    return <Skeleton height="100%" />;
  }

  return (
    <Stack maxHeight="100%" padding="8px 0">
      <AddButton onClick={handleAddUser} isDisabled={!!newUserTemporaryRow}>
        Dodaj Użytkownika
      </AddButton>
      <DataGridStyled
        apiRef={gridApiRef}
        rows={rows}
        pinnedRows={{
          top: newUserTemporaryRow ? [newUserTemporaryRow] : [],
        }}
        columns={columns}
        disableColumnReorder
        disableColumnMenu
        getRowClassName={getRowClassName}
        getRowId={(row) => row.userId}
        hideFooter
        rowHeight={64}
        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
        isCellEditable={(cell: GridCellParams<RowModel>) =>
          !isNewUserRow(cell.row) &&
          cell.field !== 'email' &&
          !(cell.row.userType === 'MainUnitUser' && cell.field === 'organizationUnitIds')
        }
        onCellClick={(cell) =>
          cell.isEditable &&
          cell.cellMode !== 'edit' &&
          gridApiRef.current.startCellEditMode({
            id: cell.id,
            field: cell.field,
          })
        }
        slots={{
          noRowsOverlay: () => null,
          loadingOverlay: () => null,
        }}
      />
    </Stack>
  );
};

const getRowClassName = (params: GridRowClassNameParams<RowModel>) => {
  const isForNewUser = isNewUserRow(params.row);

  return classNames({
    isDisabled: !isForNewUser && (params.row as OrganizationMemberModel).isDisabled,
    isNewUserRow: isForNewUser,
  });
};

const DataGridStyled = styled(DataGrid<RowModel>)(datagridStyles);
