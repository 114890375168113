import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { DismissibleSnackbarContext } from '../utils/DismissibleSnackbarContext';
import { DismissibleSnackbarProps, DismissibleSnackbar } from './DismissibleSnackbar';

type DismissibleSnackbarProviderProps = {
  children: ReactNode;
};

const DismissibleSnackbarProvider: FC<DismissibleSnackbarProviderProps> = ({ children }) => {
  const [props, setProps] = useState<DismissibleSnackbarProps>({});

  const openSnackbar = useCallback((props: DismissibleSnackbarProps) => {
    setProps(props);
  }, []);

  const closeSnackbar = useCallback(() => {
    setProps((prevProps) => ({ ...prevProps, open: false }));
  }, []);

  return (
    <DismissibleSnackbarContext.Provider
      value={useMemo(
        () => ({
          openSnackbar,
          closeSnackbar,
          isOpen: props.open ?? false,
        }),
        [closeSnackbar, openSnackbar, props]
      )}
    >
      {children}
      <DismissibleSnackbar {...props} />
    </DismissibleSnackbarContext.Provider>
  );
};

export { DismissibleSnackbarProvider };
