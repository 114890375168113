import { useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { useGridApiContext, GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { RowModel } from '../../UsersDataGrid';

type Option<T> = {
  id: T;
  label: string;
};

type SingleSelectEditCellProps<T> = {
  cell: GridRenderEditCellParams<RowModel, T>;
  onChange: (value: T) => void;
  options: Option<T>[];
  openOnRender?: boolean;
};

export function SingleSelectEditCell<T extends string>({
  cell,
  onChange,
  options,
  openOnRender = false,
}: SingleSelectEditCellProps<T>) {
  const { id, value, field, cellMode } = cell;
  const [open, setOpen] = useState(openOnRender);
  const gridApiRef = useGridApiContext();

  return (
    <Autocomplete
      noOptionsText="brak opcji"
      sx={{
        width: '100%',
        height: '48px',
      }}
      open={open}
      disableClearable
      disableCloseOnSelect
      options={options}
      getOptionLabel={(option) => option.label}
      value={options.find(({ id }) => value === id)}
      onChange={(_event, selectedOption) => {
        const val = selectedOption.id;

        cellMode === 'edit' &&
          gridApiRef.current.setEditCellValue({
            id,
            field,
            value: val,
          });
        onChange(val);
        setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderInput={(params) => <TextField {...params} />}
    />
  );
}
