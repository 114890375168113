import { FC } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { MultiAutocompleteOption } from '../base/MultiAutocompleteCell';
import { OutlinedChip } from '../base/OutlinedChip';

type OrganizationUnitDisplayCellProps = {
  selectedOptions: MultiAutocompleteOption<string>[];
};

export const OrganizationUnitDisplayCell: FC<OrganizationUnitDisplayCellProps> = ({
  selectedOptions,
}) => {
  if (!selectedOptions.length) {
    return (
      <Typography sx={{ width: '100%' }} color="error" align="center" noWrap>
        Uzupełnij jednostki organizacyjne
      </Typography>
    );
  }

  const selectedUnitsLabels = selectedOptions.map(({ label }) => label);
  const [displayedUnitLabel, ...rest] = selectedUnitsLabels;
  const chipLabel = `${displayedUnitLabel}${rest.length ? ` +${rest.length}` : ''}`;
  const tooltip = rest.length ? selectedUnitsLabels.join(', ') : '';

  return (
    <Tooltip title={tooltip}>
      <Box maxWidth="100%">
        <OutlinedChip
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          label={chipLabel}
        />
      </Box>
    </Tooltip>
  );
};
