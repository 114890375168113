import { FC, useCallback, useMemo, useState, type ReactNode } from 'react';
import { DismissibleDialogContext } from '../utils/DismissibleDialogContext';
import { DismissibleDialog } from './DismissibleDialog';
import { DialogAction } from './DismissibleDialogButton';

type DialogProps = {
  title?: ReactNode;
  content?: ReactNode;
  dismissAction?: DialogAction;
  confirmAction?: DialogAction;
  customActions?: DialogAction[];
  disableRestoreFocus?: boolean;
  withTransition?: boolean;
};

type DismissibleDialogProviderProps = {
  children: ReactNode;
};

const DismissibleDialogProvider: FC<DismissibleDialogProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState<DialogProps>();

  const openDialog = useCallback((localProps?: DialogProps) => {
    const dismissAction: DialogAction = {
      title: localProps?.dismissAction?.title ?? 'Zamknij',
      handler: (ev) => {
        localProps?.dismissAction?.handler?.(ev);
        closeDialog();
      },
    };

    setDialogProps({ ...localProps, dismissAction });
    setOpen(true);
  }, []);

  const closeDialog = () => setOpen(false);

  const handleClose = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>, reason: string) => {
    if ((reason === 'backdropClick' || reason === 'escapeKeyDown') && dialogProps?.dismissAction) {
      return dialogProps.dismissAction.handler(ev);
    }

    closeDialog();
  };

  const value = useMemo(
    () => ({
      openDialog,
      closeDialog,
    }),
    [openDialog]
  );

  return (
    <DismissibleDialogContext.Provider value={value}>
      {children}
      <DismissibleDialog open={open} onClose={handleClose} {...dialogProps} />
    </DismissibleDialogContext.Provider>
  );
};

export { DismissibleDialogProvider };
