import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import { FC, ReactNode } from 'react';
import { DialogAction, DialogButton } from './DismissibleDialogButton';

export type DismissibleDialogOwnProps = Partial<{
  title: ReactNode;
  content: ReactNode;
  dismissAction: DialogAction;
  confirmAction: DialogAction;
}>;

type DismissibleDialogProps = Pick<DialogProps, 'open' | 'onClose'> & DismissibleDialogOwnProps;

export const DismissibleDialog: FC<DismissibleDialogProps> = ({
  onClose,
  open,
  confirmAction,
  content,
  dismissAction,
  title,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{content}</DialogContent>
    <DialogActions>
      {!!dismissAction && (
        <DialogButton action={dismissAction} variant={!!confirmAction ? 'outlined' : 'contained'} />
      )}
      {!!confirmAction && <DialogButton action={confirmAction} variant="contained" autoFocus />}
    </DialogActions>
  </Dialog>
);
