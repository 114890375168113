import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ConfigModel } from 'pl.curulis/models/Config';

const fetchConfig = createAsyncThunk('config/fetchConfig', async (path: string) => {
  const response = await axios.get<ConfigModel>(path);

  return response.data;
});

export { fetchConfig };
