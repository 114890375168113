import { createAsyncThunk } from '@reduxjs/toolkit';
import { claimTrial } from '../api/claimTrial';
import { getSubscriptions } from 'pl.curulis/modules/productsSubscriptions/src/api/getSubscriptions';

const fetchSubscriptionsAsyncThunk = createAsyncThunk(
  'customerLicense/fetch-subscriptions',
  getSubscriptions
);

const claimTrialAsyncThunk = createAsyncThunk('customerLicense/claim-trial', claimTrial);

export { fetchSubscriptionsAsyncThunk, claimTrialAsyncThunk };
