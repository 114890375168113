import { UnitNamePrefix } from '../../models';

export const unitNamePrefixConsts = [
  'Town',
  'Community',
  'MetropolitanCommunity',
  'TownAndCommunity',
  'CommunityAndTown',
  'County',
  'Voivodeship',
] as const;

const unitNamePrefixDisplayValueDict: Record<UnitNamePrefix, string> = {
  Community: 'Gmina',
  MetropolitanCommunity: 'Gmina Miejska',
  CommunityAndTown: 'Gmina i Miasto',
  County: 'Powiat',
  Town: 'Miasto',
  TownAndCommunity: 'Miasto i Gmina',
  Voivodeship: 'Województwo',
};

export const unitNamePrefixOptions = unitNamePrefixConsts.map((unitNamePrefix) => ({
  value: unitNamePrefix,
  label: unitNamePrefixDisplayValueDict[unitNamePrefix],
}));
