import { FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useBlockHasChanges } from '../../../utils/useBlockHasChanges';
import { ButtonSection } from '../../CustomerAddresses/components/ButtonsSection';
import { useDispatch } from '../../../../../../utils/useDispatch';
import { selectSettingsState } from '../../../slice/selectors';
import { useRootSelector } from '../../../../../../utils/useRootSelector';
import { useEffect } from 'react';
import { NotificationsModel } from '../../../../models';
import { postNotificationsAsyncThunk } from '../../../slice/thunks';
import { useDismissibleSnackbar } from '../../../../../DismissibleSnackbar';

export const Notifications = () => {
  const dispatch = useDispatch();
  const { notifications: savedNotifications } = useRootSelector(selectSettingsState);

  const { openSnackbar } = useDismissibleSnackbar();

  const defaultValues = savedNotifications;

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm<NotificationsModel>({
    defaultValues,
  });

  useBlockHasChanges(isDirty);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = async (notificationsFormState: NotificationsModel) => {
    try {
      await dispatch(postNotificationsAsyncThunk(notificationsFormState)).unwrap();
      openSnackbar('Zmiany zapisane pomyślnie.', { severity: 'success' });
    } catch {
      openSnackbar('Nie udało się zapisać zmian.', { severity: 'error' });
    }
  };
  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} maxWidth={350} gap={5}>
      <Stack>
        <Controller
          name="bestEngagementNotificationsEnabled"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={onChange} />}
              label={<Typography>Zaangażowanie</Typography>}
            />
          )}
        />
        <Controller
          name="bestFinancialPlansNotificationsEnabled"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={onChange} />}
              label={<Typography>Plany finansowe</Typography>}
            />
          )}
        />
      </Stack>
      <ButtonSection
        disableDismiss={!isDirty}
        onDismiss={() => reset()}
        disableSubmit={isSubmitting || !isDirty}
      />
    </Stack>
  );
};
