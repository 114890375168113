const breakpoints = {
  values: {
    xs: 0,
    sm: 686,
    md: 1280,
    lg: 1600,
    xl: 1920,
  },
};

export default breakpoints;
