import { Typography, Stack, useTheme } from '@mui/material';
import { FC } from 'react';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

type BulletPointsProps = {
  content: string[];
};

export const BulletPoints: FC<BulletPointsProps> = ({ content }) => {
  const theme = useTheme();

  return (
    <Stack spacing={2} paddingTop={3} borderTop={`dashed 1px ${theme.palette.border.lighter}`}>
      {content.map((bulletPoint) => (
        <Stack key={bulletPoint} direction="row">
          <CheckCircleOutlinedIcon color="success" sx={{ marginRight: 1 }} />
          <Typography variant="body2">{bulletPoint}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};
