import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Navigate,
} from 'react-router-dom';
import { PATH_APP, PATH_EXTERNAL } from './paths';
import { globalRoutes } from './src/global.routes';
import { productRoutes } from './src/product.routes';
import { userRoutes } from './src/user.routes';
import { settingsRoutes } from './src/settings.routes';
import { agreementRoutes } from './src/agreement.routes';
import { RequireAuth } from '../modules/Authentication/src/components/RequireAuth';
import { CheckAuth } from 'pl.curulis/modules/Authentication';

const Router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path={PATH_APP.core}
        element={
          <>
            <CheckAuth />
            <RequireAuth>
              <Outlet />
            </RequireAuth>
          </>
        }
      >
        <Route path={PATH_APP.core} element={<Navigate to={PATH_APP.products} />} />
        {agreementRoutes}
        {productRoutes}
        {userRoutes}
        {settingsRoutes}
      </Route>
      {globalRoutes}
    </>
  )
);

export { Router, PATH_APP, PATH_EXTERNAL };
