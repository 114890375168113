import { FC } from 'react';

export const BestLogo: FC = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 161 129"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M160.419 12.9188C160.419 5.83237 154.674 0.0927734 147.581 0.0927734H89.4696C85.4877 0.0927734 81.5472 1.0252 78.0008 2.80717L63.1721 10.2044C59.6049 11.9864 55.6852 12.9188 51.7032 12.9188H12.8377C5.7448 12.9188 0 18.6584 0 25.7449V115.506C0 122.593 5.7448 128.332 12.8377 128.332H147.581C154.674 128.332 160.419 122.593 160.419 115.506V12.9188Z"
      fill="#0FBFF1"
    />
    <path
      d="M126.572 90.0409C133.914 90.0409 138.186 92.8589 138.186 98.8264C138.186 104.794 133.914 107.633 126.572 107.633H121.865L124.747 113.414C125.37 114.678 126.676 115.486 128.107 115.486C139.638 115.486 147.581 109.332 147.581 98.8471C147.581 88.3625 139.638 82.2085 128.107 82.2085H109.151L112.034 87.9895C112.656 89.2535 113.963 90.0616 115.394 90.0616H126.572V90.0409Z"
      fill="white"
    />
    <path
      d="M104.444 99.7796C97.1018 99.7796 92.8295 96.9616 92.8295 90.9941C92.8295 85.0265 97.1018 82.1878 104.444 82.1878H109.151L106.269 76.4068C105.626 75.1221 104.319 74.3347 102.909 74.3347C91.3777 74.3347 83.4346 80.4887 83.4346 90.9733C83.4346 101.458 91.3777 107.612 102.909 107.612H121.865L118.982 101.831C118.36 100.567 117.053 99.7589 115.622 99.7589H104.444V99.7796Z"
      fill="white"
    />
  </svg>
);
