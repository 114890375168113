import { Autocomplete, TextField } from '@mui/material';
import { AppSettings } from 'pl.curulis/modules/Settings/models';
import { FC } from 'react';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';

type UnitDeclensionDropdownProps = {
  label: string;
  name: ControllerRenderProps<AppSettings>['name'];
  control: Control<AppSettings, any>;
  options: { value: string; label: string }[];
};

export const UnitDeclensionDropdown: FC<UnitDeclensionDropdownProps> = ({
  label,
  control,
  name,
  options,
}) => (
  <Controller
    control={control}
    name={name}
    render={({ field, fieldState }) => (
      <Autocomplete
        sx={{
          width: '100%',
          flexGrow: 1,
          '.MuiAutocomplete-clearIndicator': { display: 'none' },
        }}
        options={options}
        value={options.find((option) => option.value === field.value) ?? null}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} label={label} error={!!fieldState.error} />}
        onChange={(e, data) => field.onChange(data?.value)}
      />
    )}
  />
);
