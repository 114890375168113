import { UmowyLogo } from 'pl.curulis/assets/UmowyLogo';
import { UmowyLogoExtended } from 'pl.curulis/assets/UmowyLogoExtended';
import { Profile } from '../models/Profile';

export const umowyProfile: Profile = {
    appType: 'Web',
    host: 'umowy.publink.com',
    helpUrl: 'https://wiedza.umowy.publink.com/',
    landingUrl: 'https://publink.com/umowy',
    logo: <UmowyLogo />,
    logoExtended: <UmowyLogoExtended />,
    product: 'umowy',
};
