import { CenteringContainer } from 'pl.curulis/components/CenteringContainer';
import { Agreements } from '../components/Agreements';
import { Page } from 'pl.curulis/components/Page';

export const AgreementsView = () => (
  <Page title="Umowy">
    <CenteringContainer>
      <Agreements />
    </CenteringContainer>
  </Page>
);
