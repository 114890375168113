import { Product } from 'pl.curulis/models/enums/Product';

export type LicenseDescription = {
  pricePointId: string;
  product: Product;
  generalInfo?: string;
  bulletPointsInfo?: string[];
  isHighlighted?: boolean;
};

export const licenseDescriptionsData: LicenseDescription[] = [
  {
    pricePointId: 'ekspert-1rok',
    product: 'wpf',
    generalInfo: 'Powierz nam WPF i opiekę nad zadłużeniem.',
    bulletPointsInfo: [
      'Stała współpraca w zakresie WPF i długu',
      'Dostęp do wszystkich funkcji',
      'Niewymagana nauka programu',
      'Przygotowanie projektu, uchwały i\u00A0wszystkich zmian WPF oraz prowadzenie emisji obligacji',
    ],
  },
  {
    pricePointId: 'premium-1rok',
    product: 'wpf',
    generalInfo: 'Pozwól, że WPF zrobimy za Ciebie.',
    isHighlighted: true,
    bulletPointsInfo: [
      'Stała współpraca w zakresie WPF',
      'Dostęp do wszystkich funkcji',
      'Niewymagana nauka programu',
      'Przygotowanie projektu, uchwały i\u00A0wszystkich zmian WPF',
    ],
  },
  {
    pricePointId: 'ekonomiczna-1rok',
    product: 'wpf',
    generalInfo: 'Otrzymaj pomoc, kiedy jej potrzebujesz.',
    bulletPointsInfo: [
      'Doraźne wsparcie w zakresie WPF',
      'Dostęp do wszystkich funkcji',
      'Nauka programu z ekspertem',
      'Wsparcie za pośrednictwem chata oraz umawianych konsultacji telefonicznych',
    ],
  },
  {
    pricePointId: 'minimalna-1rok',
    product: 'wpf',
    generalInfo: 'Bądź niezależny i sam obsługuj program.',
    bulletPointsInfo: [
      'Samodzielna praca w zakresie WPF',
      'Dostęp do wszystkich funkcji',
      'Nauka programu z ekspertem',
      'Wsparcie za pośrednictwem chata',
    ],
  },
  {
    pricePointId: 'swb',
    product: 'swb',
    generalInfo: 'Nie pisz sprawozdań, generuj je w aplikacji.',
    bulletPointsInfo: [
      'Ustawowy obowiązek: Pełne sprawozdanie roczne z wykonania budżetu',
      'Dodatkowe sprawozdanie półroczne z\u00A0wykonania budżetu',
      'Wygenerowanie informacji o wykonaniu budżetu za 1\u00A0i 3\u00A0kwartał',
    ],
  },
  {
    pricePointId: 'best',
    product: 'best',
    generalInfo: 'Przygotuj szybciej budżet i uniknij błędów.',
    bulletPointsInfo: [
      'Generowanie dokumentów opisowych',
      'Generowanie projektu budżetu i planów finansowych',
      'Sprawdzanie dokumentów',
      'Tworzenie i zarządzanie budżetem',
    ],
  },
  {
    pricePointId: 'umowy',
    product: 'umowy',
    generalInfo: 'Łatwo zarządzaj umowami i angażuj środki',
    bulletPointsInfo: [
      'Wszystkie dokumenty w jednym systemie',
      'Spójność prowadzenia dokumentacji w całym urzędzie',
      'Możliwość automatycznego angażowania środków z umowy w planie budżetowym',
      'Historia zmian wprowadzanych przez współpracowników',
    ],
  },
];
