import { HubConnection } from '@microsoft/signalr';
import { createContext } from 'react';

type HubContextType = {
  connection: HubConnection | null;
};

const HubContext = createContext<HubContextType>({
  connection: null,
});

export { HubContext };
