import { FC, ReactNode } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { toLocaleCurrency } from 'pl.curulis/utils/toLocaleCurrency';
import { useCouponContext } from '../../../contexts/CouponContext';
import ClearIcon from '@mui/icons-material/Clear';

type PriceSummaryTableCellProps = {
  numeric?: boolean;
  bold?: boolean;
  children: ReactNode;
};
const PriceSummaryTableCell: FC<PriceSummaryTableCellProps> = ({ numeric, bold, children }) => (
  <TableCell align={numeric ? 'right' : 'left'}>
    <Typography fontWeight={bold ? 'bold' : 'normal'}>{children}</Typography>
  </TableCell>
);

type PriceSummaryProps = {
  name: string;
  yearlyNetValue: number;
  yearlyGrossValue: number;
};
const PriceSummary: FC<PriceSummaryProps> = ({ name, yearlyNetValue, yearlyGrossValue }) => {
  const { couponInfo, clearCoupon } = useCouponContext();

  const getDiscountValue = (value: number) => {
    let discountValue;
    if (!couponInfo) {
      discountValue = 0;
    } else {
      if (couponInfo.discountType === 'Percentage') {
        discountValue = (value * (couponInfo.value / 100)) / 100;
      } else {
        discountValue = value - couponInfo.value;
      }
    }
    return discountValue;
  };

  const getValueAfterDiscount = (value: number) => value - getDiscountValue(value);

  return (
    <>
      <TableContainer>
        <Table
          size="small"
          sx={{
            'td:first-of-type, td:last-of-type, th:first-of-type, th:last-of-type': {
              boxShadow: 'none',
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">Netto</TableCell>
              <TableCell align="center">Brutto</TableCell>
              {couponInfo && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <PriceSummaryTableCell>{name}:</PriceSummaryTableCell>
              <PriceSummaryTableCell numeric>
                {toLocaleCurrency(yearlyNetValue)}
              </PriceSummaryTableCell>
              <PriceSummaryTableCell numeric>
                {toLocaleCurrency(yearlyGrossValue)}
              </PriceSummaryTableCell>
              {couponInfo && <TableCell />}
            </TableRow>
            {couponInfo && (
              <>
                <TableRow>
                  <PriceSummaryTableCell>Rabat:</PriceSummaryTableCell>
                  <PriceSummaryTableCell numeric>
                    -{toLocaleCurrency(getDiscountValue(yearlyNetValue))}
                  </PriceSummaryTableCell>
                  <PriceSummaryTableCell numeric>
                    -{toLocaleCurrency(getDiscountValue(yearlyGrossValue))}
                  </PriceSummaryTableCell>
                  <TableCell width={20} padding="none">
                    <Tooltip title="Usuń">
                      <IconButton onClick={clearCoupon} sx={{ width: 20, height: 20 }}>
                        <ClearIcon
                          sx={(theme) => ({ width: 16, color: `${theme.palette.primary.main}` })}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <PriceSummaryTableCell bold>Podsumowanie:</PriceSummaryTableCell>
                  <PriceSummaryTableCell bold numeric>
                    {toLocaleCurrency(getValueAfterDiscount(yearlyNetValue))}
                  </PriceSummaryTableCell>
                  <PriceSummaryTableCell bold numeric>
                    {toLocaleCurrency(getValueAfterDiscount(yearlyGrossValue))}
                  </PriceSummaryTableCell>
                  {couponInfo && <TableCell />}
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export { PriceSummary };
