import { FC } from 'react';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { NavbarVariant } from '../../Navbar';
import { NavItemComponent } from 'pl.curulis/components/NavItemComponent';
import { useAuthMethods } from 'pl.curulis/modules/Authentication';

type LogoutButtonProps = {
  variant: NavbarVariant;
};

export const LogoutButton: FC<LogoutButtonProps> = ({ variant }) => {
  const { logout } = useAuthMethods();

  return (
    <NavItemComponent
      variant={variant}
      item={{
        title: 'Wyloguj',
        icon: <PowerSettingsNewIcon />,
        onClick: logout,
      }}
    />
  );
};
