import { OrganizationMemberModel } from 'pl.curulis/models/OrganizationMember';

type TestedRowProps = Pick<OrganizationMemberModel, 'isDisabled' | 'userId'>;

export type RowDeletionRejectionReason =
  | 'AttemptingToDeleteAuthenticatedUser'
  | 'MemberAlreadyDeleted';

export class RowDeletionAllower {
  private readonly authenticatedUserId: string = '';

  constructor(authenticatedUserId: string) {
    this.authenticatedUserId = authenticatedUserId;
  }

  checkIfRowDeleteDisabled(row: TestedRowProps) {
    return this.checkIfAuthUser(row) || this.checkIfAlreadyDeleted(row);
  }

  private checkIfAuthUser(row: TestedRowProps): RowDeletionRejectionReason | undefined {
    if (row.userId === this.authenticatedUserId) return 'AttemptingToDeleteAuthenticatedUser';
  }

  private checkIfAlreadyDeleted(row: TestedRowProps): RowDeletionRejectionReason | undefined {
    if (row.isDisabled) return 'MemberAlreadyDeleted';
  }
}
