import { configureStore, combineReducers, PreloadedState } from '@reduxjs/toolkit';
import { authReducer } from 'pl.curulis/modules/Authentication';
import { configReducer } from '../modules/Configuration';
import { plansReducer } from 'pl.curulis/modules/Plans';
import { productsSubscriptionsReducer } from 'pl.curulis/modules/productsSubscriptions';
import { featureFlagsReducer } from 'pl.curulis/modules/FeatureFlags';
import { userManagementReducer } from 'pl.curulis/modules/UserManagement';
import { settingsReducer } from 'pl.curulis/modules/Settings';

export const rootReducer = combineReducers({
  config: configReducer,
  auth: authReducer,
  plans: plansReducer,
  productsSubscriptions: productsSubscriptionsReducer,
  featureFlags: featureFlagsReducer,
  userManagement: userManagementReducer,
  settings: settingsReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppGetState = AppStore['getState'];
