import { FC, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { CouponProvider } from '../contexts/CouponContext';
import { CheckoutSuccessDialog } from './CheckoutSuccessDialog';
import { CheckoutForm } from './CheckoutForm';

type CheckoutDialogProps = {
  pricePointId: string;
  open: boolean;
  name: string;
  description: string;
  yearlyNetValue: number;
  yearlyGrossValue: number;
  onClose: VoidFunction;
  onCheckoutSuccess?: VoidFunction;
};

export const CheckoutDialog: FC<CheckoutDialogProps> = ({
  open,
  pricePointId,
  name,
  description,
  yearlyNetValue,
  yearlyGrossValue,
  onClose,
  onCheckoutSuccess,
}) => {
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const onSubmitSuccess = () => {
    onClose();
    setSuccessDialogOpen(true);
    onCheckoutSuccess?.();
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  return (
    <>
      <CheckoutSuccessDialog open={successDialogOpen} onClose={handleSuccessDialogClose} />
      <Dialog
        open={open}
        onClose={onClose}
        sx={(theme) => ({
          '& .MuiDialog-container': {
            '& .MuiPaper-root': { [theme.breakpoints.up('md')]: { minWidth: '975px' } },
          },
        })}
      >
        <DialogTitle sx={{ padding: 2.4 }}>Twoje zamówienie</DialogTitle>
        <DialogContent>
          <CouponProvider>
            <CheckoutForm
              pricePointId={pricePointId}
              name={name}
              description={description}
              yearlyNetValue={yearlyNetValue}
              yearlyGrossValue={yearlyGrossValue}
              onSubmitSuccess={onSubmitSuccess}
              onClose={onClose}
            />
          </CouponProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};
