import { Skeleton, Stack } from '@mui/material';
import { selectProductsSubscriptions } from 'pl.curulis/modules/productsSubscriptions/src/slice/selectors';
import { useSelector } from 'react-redux';
import { AgreementsProductCard } from 'pl.curulis/modules/Product/src/components';

export const Agreements = () => {
  const { products, subscriptionsFetchStatus } = useSelector(selectProductsSubscriptions);

  if (!products && subscriptionsFetchStatus === 'loading')
    return <Skeleton width="100%" height="316px" />;
  if (!products) return null;

  return (
    <Stack direction="row" flexWrap="wrap" gap={2}>
      {products.map(({ product, subscription }) => {
        if (!subscription) return null;
        return (
          <AgreementsProductCard key={product} product={product} subscription={subscription} />
        );
      })}
    </Stack>
  );
};
