import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, Button, Stack, TextField } from '@mui/material';
import { selectUserManagementState } from 'pl.curulis/modules/UserManagement/src/slice/selectors';
import { keyCodes } from 'pl.curulis/utils/keyCodes';
import { FC, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  parentId: yup.string().required('Uzupełnij nazwę jednostki'),
  name: yup.string().required('Uzupełnij nazwę dysponenta'),
});

export type SchemaType = Record<'parentId' | 'name', string>;

type AddOrganizationFormProps = {
  handleAddNewOrg: (updatedRow: SchemaType) => Promise<SchemaType | null>;
  onCancel: VoidFunction;
};

export const AddOrganizationForm: FC<AddOrganizationFormProps> = ({
  handleAddNewOrg,
  onCancel,
}) => {
  const { organizationUnits } = useSelector(selectUserManagementState);
  const units = organizationUnits.filter((row) => row.type === 'JP');

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SchemaType>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit((data) => handleAddNewOrg(data));

  const handleKeydown = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === keyCodes.ESCAPE) {
        onCancel();
      }
    },
    [onCancel]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown, true);

    return () => window.removeEventListener('keydown', handleKeydown, true);
  }, [handleKeydown]);

  return (
    <form onSubmit={onSubmit}>
      <Stack direction="row" marginY={2} gap={2}>
        <Box flexShrink={0} flexGrow={1}>
          <Controller
            control={control}
            name="parentId"
            render={({ field }) => (
              <Autocomplete
                noOptionsText="brak opcji"
                disableClearable
                options={units}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Wybierz urząd" error={Boolean(errors.parentId)} />
                )}
                onChange={(e, data) => field.onChange(data.constId)}
              />
            )}
          />
        </Box>
        <Box flexShrink={0} flexGrow={1}>
          <TextField
            error={Boolean(errors.name)}
            fullWidth
            label="Nazwa dysponenta"
            {...register('name')}
          />
        </Box>
        <Button type="submit" variant="contained">
          Dodaj
        </Button>
        <Button onClick={onCancel}>Anuluj</Button>
      </Stack>
    </form>
  );
};
