export const UmowyLogo = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 442 354"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M441.182 318.054V35.3418C441.182 15.8154 425.387 0 405.887 0H246.069C235.106 0 224.297 2.56228 214.503 7.46595L173.715 27.8758C163.921 32.7795 153.112 35.3418 142.149 35.3418H35.2945C15.7943 35.3418 0 51.1572 0 70.6835V318.076C0 337.602 15.7943 353.418 35.2945 353.418H405.887C425.387 353.418 441.182 337.58 441.182 318.054Z"
      fill="#A971F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M255.268 250.551C255.268 267.029 267.025 274.782 287.231 274.782H287.253H287.275H318.004C321.908 274.782 325.482 276.991 327.224 280.503L335.166 296.429L348.093 296.429C368.299 296.429 380.056 288.654 380.056 272.176C380.056 255.698 368.299 247.945 348.093 247.945L348.026 247.922H317.276C313.372 247.922 309.798 245.714 308.055 242.202L300.125 226.298H287.231C267.025 226.298 255.268 234.073 255.268 250.551ZM300.191 226.276L292.261 210.372C290.496 206.838 286.922 204.629 282.996 204.629C251.297 204.629 229.437 221.593 229.437 250.529C229.437 279.465 251.297 296.429 282.996 296.429L335.122 296.429L343.063 312.355C344.806 315.867 348.379 318.076 352.284 318.076C384.005 318.076 405.843 301.112 405.843 272.176C405.843 243.24 383.983 226.276 352.284 226.276H300.191Z"
      fill="white"
    />
  </svg>
);
