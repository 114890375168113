import { FC } from 'react';
import { Navigate, useLocation } from 'react-router';
import { filterBlacklisted } from '../utils/blacklistedQueries';

type NavigateWithQueryProps = {
  hrefWithoutQuery: string;
  replace?: boolean;
};

const NavigateWithQuery: FC<NavigateWithQueryProps> = ({ hrefWithoutQuery, replace }) => {
  const { search } = useLocation();

  return <Navigate replace={replace} to={hrefWithoutQuery + filterBlacklisted(search)} />;
};

export { NavigateWithQuery };
