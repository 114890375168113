import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUnitDeclension } from '../api/getUnitDeclension';
import { getTextDocument } from '../api/getTextDocument';
import { postUnitDeclension } from '../api/postUnitDeclension';
import { postTextDocument } from '../api/postTextDocument';
import { getCustomerAddresses } from '../api/getCustomerAdressess';
import { putCustomerAddresses } from '../api/putCustomerAddresses';
import { CustomerAddresses } from '../../models';
import { getNotifications } from '../api/getNotifications';
import { postNotifications } from '../api/postNotifications';

export const getUnitDeclensionAsyncThunk = createAsyncThunk(
  'settings/getUnitDeclension',
  getUnitDeclension
);

export const getDocumentSettingsAsyncThunk = createAsyncThunk(
  'settings/getTextDocument',
  getTextDocument
);

export const postUnitDeclensionAsyncThunk = createAsyncThunk(
  'settings/postUnitDeclension',
  postUnitDeclension
);

export const postDocumentSettingsAsyncThunk = createAsyncThunk(
  'settings/postTextDocument',
  postTextDocument
);

export const getCustomerAddressesAsyncThunk = createAsyncThunk(
  'customer/getCustomerAddresses',
  getCustomerAddresses
);

export const putCustomerAddressesAsyncThunk = createAsyncThunk(
  'customer/putCustomerAddresses',
  (customerAddresses: CustomerAddresses) => {
    const currentShippingHasAnyValues = Object.values(customerAddresses.shipping).some(
      (value) => !!value
    );

    const customerAddressesPayload = currentShippingHasAnyValues
      ? { billing: customerAddresses.billing, shipping: customerAddresses.shipping }
      : { billing: customerAddresses.billing };

    return putCustomerAddresses(customerAddressesPayload);
  }
);

export const getNotificationsAsyncThunk = createAsyncThunk(
  'settings/getNotifications',
  getNotifications
);

export const postNotificationsAsyncThunk = createAsyncThunk(
  'settings/postNotifications',
  postNotifications
);
