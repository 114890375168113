import { Stack } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';
import { OrgUnit } from 'pl.curulis/models/OrganizationUnit';
import { OutlinedChip } from 'pl.curulis/modules/UserManagement/src/components/cells/base/OutlinedChip';
import { selectUserManagementState } from 'pl.curulis/modules/UserManagement/src/slice/selectors';
import { FC } from 'react';
import { useSelector } from 'react-redux';

type DepartmentsDisplayCellProps = GridRenderCellParams<
  OrgUnit,
  any,
  any,
  GridTreeNodeWithRender
> & {
  setEditedOrganization: React.Dispatch<React.SetStateAction<OrgUnit | null>>;
};

export const DepartmentsDisplayCell: FC<DepartmentsDisplayCellProps> = ({
  formattedValue,
  setEditedOrganization,
  row,
  api,
}) => {
  const { organizationUnits } = useSelector(selectUserManagementState);
  const departments = organizationUnits.filter((department) => department.parentId === row.constId);

  const onStartEdit = (department: OrgUnit) => {
    setEditedOrganization(department);

    api.startCellEditMode({
      id: row.constId,
      field: 'name',
    });
  };

  return (
    <Stack gap={2} maxWidth="100%">
      <OutlinedChip label={formattedValue} />
      {departments.map((department, index) => (
        <OutlinedChip
          sx={{
            marginTop: index === 0 ? 1 : 0,
          }}
          key={department.constId}
          label={department.name}
          onClick={() => onStartEdit(department)}
        />
      ))}
    </Stack>
  );
};
