import { Typography } from '@mui/material';
import { FC } from 'react';

export const TextDisplayCell: FC<{ value?: string }> = ({ value }) => {
  if (!value) {
    return null;
  }

  return <Typography>{value}</Typography>;
};
