import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OrganizationMemberModel } from 'pl.curulis/models/OrganizationMember';
import { getOrganizationMembersListAsyncThunk, getOrganizationUnitsAsyncThunk } from './thunks';
import { RequestStatus } from 'pl.curulis/utils/api/requestStatus';
import { OrgUnit, OrgUnitCollection } from 'pl.curulis/models/OrganizationUnit';

type UserManagementState = {
  organizationMembers: OrganizationMemberModel[];
  organizationMembersStatus: RequestStatus;
  organizationUnits: OrgUnitCollection;
  organizationUnitsStatus: RequestStatus;
};

const initialState: UserManagementState = {
  organizationMembers: [],
  organizationMembersStatus: 'idle',
  organizationUnits: [],
  organizationUnitsStatus: 'idle',
};

const slice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    invalidateOrganizationUnitsFetch: (state) => {
      state.organizationUnitsStatus = 'idle';
      state.organizationUnits = [];
    },
    invalidateOrganizationMembersFetch: (state) => {
      state.organizationMembersStatus = 'idle';
      state.organizationMembers = [];
    },
    clearOrganizationMembers: () => initialState,
    editUser: (state, action: PayloadAction<OrganizationMemberModel>) => {
      const updatedUser = action.payload;
      const currentMembers = state.organizationMembers;

      state.organizationMembers = currentMembers.map((user) => {
        if (user.userId === updatedUser.userId) {
          return updatedUser;
        }

        return user;
      });
    },
    editOrganizationUnits: (state, action: PayloadAction<OrgUnit>) => {
      const updatedUnit = action.payload;
      const currentOrganizationUnits = state.organizationUnits;

      state.organizationUnits = currentOrganizationUnits.map((unit) => {
        if (unit.constId === updatedUnit.constId) {
          return updatedUnit;
        }

        return unit;
      });
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getOrganizationMembersListAsyncThunk.pending, (state) => {
        state.organizationMembersStatus = 'loading';
      })
      .addCase(getOrganizationMembersListAsyncThunk.fulfilled, (state, action) => {
        state.organizationMembers = action.payload;
        state.organizationMembersStatus = 'success';
      })
      .addCase(getOrganizationMembersListAsyncThunk.rejected, (state) => {
        state.organizationMembersStatus = 'error';
      })
      .addCase(getOrganizationUnitsAsyncThunk.pending, (state) => {
        state.organizationUnitsStatus = 'loading';
      })
      .addCase(getOrganizationUnitsAsyncThunk.fulfilled, (state, action) => {
        state.organizationUnitsStatus = 'success';
        state.organizationUnits = action.payload;
      })
      .addCase(getOrganizationUnitsAsyncThunk.rejected, (state) => {
        state.organizationUnitsStatus = 'error';
        state.organizationUnits = [];
      }),
});

export const { actions, reducer: userManagementReducer } = slice;
export const {
  clearOrganizationMembers,
  invalidateOrganizationMembersFetch,
  invalidateOrganizationUnitsFetch,
  editUser,
  editOrganizationUnits,
} = actions;
