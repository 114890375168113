import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'pl.curulis/redux/store';
import { isUserAdmin } from '../utils/isUserAdmin';

const collator = new Intl.Collator(navigator.language, { numeric: true, sensitivity: 'accent' });

export const selectUserManagementState = (state: RootState) => state.userManagement;

export const selectOrganizationUnits = (state: RootState) => state.userManagement.organizationUnits;

export const selectAllOrganizationMembers = (state: RootState) =>
  state.userManagement.organizationMembers;

export const selectActiveOrganizationMembers = createSelector(
  selectAllOrganizationMembers,
  (organizationMembers) =>
    organizationMembers
      .filter(({ isDisabled }) => !isDisabled)
      .sort((a, b) => {
        const isAdmin1 = isUserAdmin(a);
        const isAdmin2 = isUserAdmin(b);

        if (isAdmin1 || isAdmin2) {
          return Number(isAdmin1) - Number(isAdmin2);
        }

        return collator.compare(a.email, b.email);
      })
);

export const selectAllOrganizationUnits = (state: RootState) =>
  state.userManagement.organizationUnits;
