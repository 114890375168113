export const nullAddressObject = {
  organizationName: null,
  address: null,
  city: null,
  zip: null,
};

export const nullBillingObject = {
  ...nullAddressObject,
  nip: null,
};

export type AddressModel = {
  organizationName: string | null;
  address: string | null;
  city: string | null;
  zip: string | null;
};

export type BillingModel = AddressModel & {
  nip: string | null;
};
