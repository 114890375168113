import { DataGrid } from 'pl.curulis/modules/DataGrid';
import { FC, useState } from 'react';
import { Skeleton, Stack, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUserManagementState } from '../slice/selectors';
import { AddButton } from './AddButton';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { datagridStyles } from '../utils/datagridStyles';
import { useDismissibleSnackbar } from 'pl.curulis/modules/DismissibleSnackbar';
import {
  editOrganizationUnits,
  invalidateOrganizationUnitsFetch,
} from 'pl.curulis/modules/UserManagement';
import { OrgUnit } from 'pl.curulis/models/OrganizationUnit';
import { createUnitDepartment } from 'pl.curulis/modules/UserManagement/src/api/createUnitDepartment';
import { renameUnitDepartment } from 'pl.curulis/modules/UserManagement/src/api/renameUnitDepartment';
import { useOrganizationUnitsColumns } from 'pl.curulis/modules/UserManagement/src/utils/useOrganizationUnitsColumns';
import {
  AddOrganizationForm,
  SchemaType,
} from 'pl.curulis/modules/UserManagement/src/components/AddOrganizationForm';
import { useApiRef } from 'pl.curulis/modules/UserManagement/src/utils/useApiRef';
import { selectAuthenticatedUser } from '../../../Authentication';

export const OrganizationUnitsDataGrid: FC = () => {
  const { organizationUnits, organizationUnitsStatus } = useSelector(selectUserManagementState);
  const dispatch = useDispatch();
  const { openSnackbar } = useDismissibleSnackbar();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const gridApiRef = useApiRef();
  const rows = organizationUnits.filter((row) => row.type !== 'DEP');

  const [isAddingNewRow, setIsAddingNewRow] = useState(false);
  const [editedOrganization, setEditedOrganization] = useState<OrgUnit | null>(null);

  const handleAddNewOrg = async (updatedRow: SchemaType) => {
    if (!authenticatedUser) return null;

    try {
      const newOrg = {
        organizationId: authenticatedUser?.organizationGuid,
        name: updatedRow.name,
        parentId: updatedRow.parentId,
      };
      await createUnitDepartment(newOrg);
      dispatch(invalidateOrganizationUnitsFetch());
    } catch {
      openSnackbar('Nie udało się dodać dysponenta', {
        severity: 'error',
      });
    } finally {
      setIsAddingNewRow(false);
    }

    return updatedRow;
  };

  const handleRenameOrg = async (originalOrg: OrgUnit, updatedOrg: OrgUnit) => {
    if (!authenticatedUser) return;
    if (originalOrg.name === updatedOrg.name) return;

    try {
      const renamedOrg = {
        ...updatedOrg,
        newName: updatedOrg.name,
        newShortName: updatedOrg.shortName,
        constId: updatedOrg.constId,
        organizationConstId: authenticatedUser.organizationGuid,
      };

      await renameUnitDepartment(renamedOrg);
      dispatch(editOrganizationUnits(updatedOrg));
    } catch {
      openSnackbar('Nie udało się zaktualizować dysponenta', {
        severity: 'error',
      });
    }
  };

  const columns = useOrganizationUnitsColumns({
    handleRenameOrg,
    editedOrganization,
    setEditedOrganization,
  });

  if (organizationUnitsStatus === 'loading') {
    return <Skeleton width="100%" height="100%" />;
  }

  return (
    <Stack maxHeight="100%" padding="8px 0">
      <AddButton onClick={() => setIsAddingNewRow(true)} isDisabled={false}>
        Dodaj Dysponenta
      </AddButton>
      {isAddingNewRow && (
        <AddOrganizationForm
          handleAddNewOrg={handleAddNewOrg}
          onCancel={() => setIsAddingNewRow(false)}
        />
      )}
      <DataGridStyled
        hideFooter
        apiRef={gridApiRef}
        hideFooterSelectedRowCount
        disableMultipleRowSelection
        rows={rows}
        getRowHeight={() => 'auto'}
        columns={columns}
        getRowId={(row) => row.constId}
        sx={{
          '& .MuiDataGrid-cell': {
            alignItems: 'baseline',
          },
        }}
        disableColumnMenu
      />
    </Stack>
  );
};

const DataGridStyled = styled(DataGrid<OrgUnit>)(datagridStyles);
