import { useBlockNavigation } from 'pl.curulis/utils/useBlockNavigation';
import { useCallback } from 'react';
import { useDismissibleDialog } from 'pl.curulis/modules/DismissibleDialog';

export const useBlockHasChanges = (hasUnsavedChanges: boolean) => {
  const { openDialog, closeDialog } = useDismissibleDialog();

  const { proceed, reset } = useBlockNavigation({
    shouldBlock: hasUnsavedChanges,
    onBlock: () =>
      openDialog({
        content: 'Wprowadzone zmiany nie zostały zapisane. Czy chcesz wyjść bez zapisywania?',
        dismissAction: { title: 'Nie', handler: () => reset?.() },
        confirmAction: { title: 'Tak', handler: onConfirmNavigate },
      }),
  });

  const onConfirmNavigate = useCallback(() => {
    if (!proceed) return;

    proceed();
    closeDialog();
  }, [closeDialog, proceed]);
};
