import { FC, useRef } from 'react';
import { tom, bolek } from './instances';
import { useSelector } from 'react-redux';
import { selectServicesUrls } from 'pl.curulis/modules/Configuration';
import { AxiosError, AxiosInstance } from 'axios';
import { ApiError } from 'pl.curulis/utils/api/ApiError';
import { handleError as handleResponseError } from 'pl.curulis/utils/api/handleError';
import { useAuthMethods } from 'pl.curulis/modules/Authentication';

type SetupInstanceProps = {
  instance: AxiosInstance;
  baseUrl: string;
};

const SetupInstance: FC<SetupInstanceProps> = ({ instance, baseUrl }) => {
  const requestInterceptorIdRef = useRef(0);
  const responseInterceptorIdRef = useRef(0);
  const { logout } = useAuthMethods();

  const handleError = async (error: AxiosError): Promise<ApiError> => {
    if (error.response?.status === 401) {
      logout();
    }

    return Promise.reject(handleResponseError(error));
  };

  instance.interceptors.request.eject(requestInterceptorIdRef.current);
  requestInterceptorIdRef.current = instance.interceptors.request.use((config) => {
    config.baseURL = baseUrl;
    return config;
  });

  instance.interceptors.response.eject(responseInterceptorIdRef.current);
  responseInterceptorIdRef.current = instance.interceptors.response.use(
    (response) => response,
    handleError
  );

  return null;
};

export const SetupAxiosInterceptors = () => {
  const { tomUrl, bolekUrl } = useSelector(selectServicesUrls);

  return (
    <>
      <SetupInstance instance={tom} baseUrl={tomUrl} />
      <SetupInstance instance={bolek} baseUrl={bolekUrl} />
    </>
  );
};
