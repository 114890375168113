import * as yup from 'yup';
import { DecisionMakingAuthority, ExecutiveAuthority, UnitNamePrefix } from '../../models';
import { unitNamePrefixConsts } from '../consts/unitNamePrefixOptions';
import { decisionMakingAuthorityConsts } from '../consts/decisionMakingAuthorityOptions';
import { executiveAuthorityConsts } from '../consts/executiveAuhorityOptions';

const sufixesValidator = yup.string().matches(/^(\D*)$/, 'Niepoprawna wartość');

export const unitDeclensionValidationSchema = yup.object({
  unitDeclension: yup.object({
    unitNamePrefix: yup
      .mixed<UnitNamePrefix>()
      .oneOf([...unitNamePrefixConsts])
      .required('Typ jednostki jest wymagany.'),
    decisionMakingAuthority: yup
      .mixed<DecisionMakingAuthority>()
      .oneOf([...decisionMakingAuthorityConsts])
      .required('Organ stanowiący jest wymagany.'),
    executiveAuthority: yup
      .mixed<ExecutiveAuthority>()
      .oneOf([...executiveAuthorityConsts])
      .required('Organ wykonawczy jest wymagany.'),
    unitNameGenitive: sufixesValidator,
    decisionMakingAuthoritySuffix: sufixesValidator,
    executiveAuthoritySuffix: sufixesValidator,
  }),
  documentSettings: yup.object({
    tableTheme: yup.string().required('Styl tabeli jest wymagany'),
  }),
});
