import { bolek } from 'pl.curulis/modules/Axios';
import { OrganizationUnitDto, mapOrgUnitDtoToModel } from 'pl.curulis/models/OrganizationUnit';

export type CreateUnitDepartmentRequestPayload = {
  organizationId: string;
  name: string;
  shortName?: string;
  parentId?: string;
};

export const createUnitDepartment = async (payload: CreateUnitDepartmentRequestPayload) => {
  const response = await bolek.post<OrganizationUnitDto>('units', payload);

  return mapOrgUnitDtoToModel(response.data);
};
