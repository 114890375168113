import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
          '&:disabled': {
            borderColor: theme.palette.action.disabled,
          },
        },
        sizeLarge: {
          height: 48,
        },
        // contained
        containedInherit: {
          color: theme.palette.text.primary,
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
          background: theme.palette.primary.dark,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
          color: theme.palette.primary.dark,
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
          color: theme.palette.primary.dark,
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
          color: theme.palette.primary.dark,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
          color: theme.palette.primary.dark,
          background: theme.palette.warning.dark,
        },
        containedError: {
          boxShadow: theme.customShadows.error,
          color: theme.palette.primary.dark,
          background: theme.palette.error.dark,
        },
        // outlined
        outlinedPrimary: {
          color: theme.palette.primary.dark,
          borderColor: theme.palette.primary.dark,
          '&.MuiButton-root': {
            background: theme.palette.background.light,
          },
          '& .MuiSvgIcon-root': {
            color: theme.palette.primary.main,
          },
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.border.light}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
