import { normalizeGuid } from 'pl.curulis/utils/normalizeGuid';
import { useQuery } from 'pl.curulis/utils/useQuery';

const useQueryParamApplicationId = () => {
  const query = useQuery();
  const applicationId = normalizeGuid(query.get('applicationId'));

  return applicationId;
};

export { useQueryParamApplicationId };
