import { SwbLogoExtended } from 'pl.curulis/assets/SwbLogoExtended';
import { Profile } from '../models/Profile';
import { SwbLogo } from 'pl.curulis/assets/SwbLogo';

export const swbProfile: Profile = {
  appType: 'Web',
  helpUrl: 'https://swb.wiedza.publink.com/',
  host: 'swb.publink.com',
  landingUrl: 'https://publink.com/swb-asystent',
  logo: <SwbLogo />,
  logoExtended: <SwbLogoExtended />,
  product: 'swb',
  videoAnnotation:
    'Sprawdź, jak wygenerować opisówkę z wykonania budżetu. Obejrzyj naszą instrukcję wideo.',
  videoUrl: 'https://www.youtube.com/embed/1gjvipUKpSQ',
};
