import { FC } from 'react';
import { useTheme } from '@mui/material';

export const PublinkLogoExtendedCurulis: FC = () => {
  const theme = useTheme();
  const paletteColorDark = theme.palette.primary.dark;

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 199 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.5916 57.5157C94.8112 57.5157 94.1445 57.3221 93.5915 56.935C93.0386 56.5427 92.6148 56.0136 92.3202 55.3477C92.0256 54.6818 91.8784 53.9308 91.8784 53.0945C91.8784 52.2635 92.0231 51.515 92.3125 50.8491C92.607 50.1832 93.0282 49.6567 93.576 49.2696C94.1239 48.8824 94.7802 48.6888 95.5451 48.6888C96.3306 48.6888 96.9921 48.8798 97.5296 49.2618C98.0671 49.6438 98.4727 50.1677 98.7467 50.8336C99.0257 51.4943 99.1653 52.248 99.1653 53.0945C99.1653 53.9256 99.0283 54.6767 98.7544 55.3477C98.4805 56.0136 98.0774 56.5427 97.5451 56.935C97.0128 57.3221 96.3616 57.5157 95.5916 57.5157ZM95.7079 56.4549C96.3022 56.4549 96.7931 56.3104 97.1807 56.0213C97.5683 55.7323 97.8578 55.3348 98.049 54.8289C98.2402 54.3179 98.3358 53.7398 98.3358 53.0945C98.3358 52.439 98.2402 51.8608 98.049 51.3601C97.8578 50.8543 97.5683 50.4594 97.1807 50.1755C96.7983 49.8916 96.3151 49.7496 95.7311 49.7496C95.1316 49.7496 94.6355 49.8967 94.2427 50.191C93.8499 50.4852 93.558 50.8852 93.3667 51.3911C93.1807 51.897 93.0877 52.4648 93.0877 53.0945C93.0877 53.7295 93.1833 54.3024 93.3745 54.8135C93.5709 55.3193 93.8629 55.7194 94.2505 56.0136C94.6381 56.3078 95.1239 56.4549 95.7079 56.4549ZM98.3358 57.2834V50.9885H98.2118V46.1337H99.3591V57.2834H98.3358Z"
        fill={paletteColorDark}
      />
      <path
        d="M103.774 57.5157C103.144 57.5157 102.614 57.4021 102.185 57.175C101.761 56.9479 101.443 56.6459 101.232 56.2691C101.02 55.8923 100.914 55.4819 100.914 55.038C100.914 54.5837 101.004 54.1966 101.185 53.8766C101.371 53.5514 101.622 53.2855 101.937 53.0791C102.257 52.8726 102.627 52.7151 103.046 52.6067C103.469 52.5035 103.937 52.4132 104.449 52.3357C104.965 52.2531 105.469 52.1835 105.96 52.1267C106.456 52.0647 106.891 52.0054 107.263 51.9486L106.86 52.1964C106.875 51.3705 106.715 50.7588 106.379 50.3613C106.043 49.9638 105.459 49.7651 104.627 49.7651C104.053 49.7651 103.567 49.8942 103.17 50.1522C102.777 50.4103 102.5 50.8181 102.34 51.3756L101.232 51.0504C101.423 50.3019 101.808 49.7212 102.387 49.3083C102.965 48.8953 103.717 48.6888 104.642 48.6888C105.407 48.6888 106.056 48.8334 106.588 49.1224C107.126 49.4064 107.506 49.8193 107.728 50.3613C107.831 50.6039 107.898 50.8749 107.929 51.1743C107.96 51.4737 107.976 51.7782 107.976 52.088V57.2834H106.96V55.1851L107.255 55.309C106.971 56.0265 106.529 56.5737 105.929 56.9505C105.33 57.3273 104.611 57.5157 103.774 57.5157ZM103.906 56.5324C104.438 56.5324 104.903 56.4369 105.301 56.2459C105.699 56.0549 106.02 55.7942 106.263 55.4639C106.506 55.1283 106.663 54.7515 106.736 54.3334C106.798 54.065 106.831 53.7707 106.836 53.4507C106.841 53.1255 106.844 52.8829 106.844 52.7229L107.278 52.9474C106.891 52.999 106.469 53.0507 106.015 53.1023C105.565 53.1539 105.12 53.2133 104.681 53.2804C104.247 53.3475 103.854 53.4275 103.503 53.5204C103.265 53.5875 103.035 53.683 102.813 53.8069C102.591 53.9256 102.407 54.0856 102.263 54.2869C102.123 54.4883 102.053 54.7386 102.053 55.038C102.053 55.2806 102.113 55.5155 102.232 55.7426C102.356 55.9697 102.552 56.1581 102.821 56.3078C103.095 56.4575 103.456 56.5324 103.906 56.5324Z"
        fill={paletteColorDark}
      />
      <path
        d="M111.319 57.2834L108.761 48.9134L109.9 48.9211L111.885 55.4174L113.877 48.9211H114.916L116.908 55.4174L118.908 48.9211H120.04L117.482 57.2834H116.49L114.397 50.6246L112.311 57.2834H111.319Z"
        fill={paletteColorDark}
      />
      <path
        d="M127.328 57.2834V53.0171C127.328 52.5525 127.284 52.1267 127.196 51.7395C127.109 51.3472 126.966 51.0065 126.77 50.7175C126.579 50.4232 126.328 50.1961 126.018 50.0361C125.713 49.8761 125.341 49.7961 124.902 49.7961C124.499 49.7961 124.142 49.8658 123.832 50.0051C123.527 50.1445 123.269 50.3458 123.057 50.6091C122.85 50.8672 122.692 51.182 122.584 51.5537C122.475 51.9254 122.421 52.3486 122.421 52.8235L121.615 52.6454C121.615 51.7886 121.765 51.0685 122.065 50.4852C122.364 49.9019 122.778 49.4606 123.305 49.1612C123.832 48.8618 124.437 48.7121 125.119 48.7121C125.62 48.7121 126.057 48.7895 126.429 48.9444C126.806 49.0992 127.124 49.3083 127.382 49.5715C127.646 49.8348 127.858 50.1368 128.018 50.4774C128.178 50.813 128.295 51.1717 128.367 51.5537C128.439 51.9305 128.475 52.3073 128.475 52.6842V57.2834H127.328ZM121.274 57.2834V48.9211H122.305V50.9807H122.421V57.2834H121.274Z"
        fill={paletteColorDark}
      />
      <path
        d="M130.487 47.3338V46.0563H131.627V47.3338H130.487ZM130.487 57.2834V48.9211H131.627V57.2834H130.487Z"
        fill={paletteColorDark}
      />
      <path
        d="M137.338 57.5157C136.522 57.5157 135.814 57.335 135.214 56.9737C134.62 56.6124 134.157 56.1039 133.827 55.4484C133.496 54.7928 133.331 54.0237 133.331 53.141C133.331 52.2273 133.493 51.4376 133.819 50.7717C134.145 50.1058 134.602 49.5922 135.191 49.2308C135.785 48.8695 136.486 48.6888 137.292 48.6888C138.119 48.6888 138.824 48.8798 139.408 49.2618C139.992 49.6386 140.434 50.1806 140.734 50.8878C141.034 51.595 141.17 52.439 141.145 53.4197H139.982V53.0171C139.961 51.9331 139.726 51.1149 139.276 50.5626C138.832 50.0103 138.181 49.7341 137.323 49.7341C136.424 49.7341 135.734 50.0258 135.253 50.6091C134.778 51.1924 134.54 52.0234 134.54 53.1023C134.54 54.1553 134.778 54.9735 135.253 55.5568C135.734 56.1349 136.413 56.424 137.292 56.424C137.891 56.424 138.413 56.2872 138.858 56.0136C139.307 55.7349 139.661 55.3348 139.92 54.8135L140.982 55.2238C140.651 55.9517 140.163 56.5169 139.517 56.9195C138.876 57.317 138.15 57.5157 137.338 57.5157ZM134.137 53.4197V52.4674H140.532V53.4197H134.137Z"
        fill={paletteColorDark}
      />
      <path
        d="M141.47 61V59.9005H141.942C142.32 59.9005 142.619 59.8024 142.842 59.6063C143.069 59.4153 143.183 59.0927 143.183 58.6384V48.9211H144.315V58.9249C144.315 59.6218 144.147 60.1405 143.811 60.4812C143.48 60.8271 142.971 61 142.284 61H141.47ZM143.183 47.3338V46.0563H144.315V47.3338H143.183Z"
        fill={paletteColorDark}
      />
      <path
        d="M153.914 57.5157C152.803 57.5157 151.865 57.2705 151.1 56.7801C150.335 56.2846 149.757 55.6006 149.364 54.7283C148.971 53.8559 148.775 52.8493 148.775 51.7086C148.775 50.5678 148.971 49.5612 149.364 48.6888C149.757 47.8165 150.335 47.1351 151.1 46.6447C151.865 46.1492 152.803 45.9014 153.914 45.9014C155.211 45.9014 156.271 46.2344 157.093 46.9002C157.914 47.561 158.478 48.4488 158.783 49.5638L157.62 49.8658C157.382 48.9831 156.956 48.2836 156.341 47.7674C155.726 47.2513 154.917 46.9932 153.914 46.9932C153.041 46.9932 152.315 47.1919 151.736 47.5894C151.157 47.9868 150.72 48.5417 150.426 49.2541C150.136 49.9613 149.987 50.7794 149.976 51.7086C149.971 52.6377 150.113 53.4559 150.403 54.163C150.697 54.8702 151.136 55.4251 151.72 55.8278C152.31 56.2252 153.041 56.424 153.914 56.424C154.917 56.424 155.726 56.1659 156.341 55.6497C156.956 55.1283 157.382 54.4289 157.62 53.5514L158.783 53.8533C158.478 54.9683 157.914 55.8587 157.093 56.5246C156.271 57.1853 155.211 57.5157 153.914 57.5157Z"
        fill={paletteColorDark}
      />
      <path
        d="M163.54 57.4925C163.038 57.4925 162.599 57.4151 162.222 57.2602C161.85 57.1053 161.532 56.8963 161.268 56.633C161.01 56.3698 160.8 56.0704 160.64 55.7349C160.48 55.3942 160.364 55.0354 160.291 54.6586C160.219 54.2766 160.183 53.8972 160.183 53.5204V48.9211H161.33V53.1874C161.33 53.6469 161.374 54.0727 161.462 54.465C161.55 54.8573 161.689 55.2006 161.881 55.4948C162.077 55.7839 162.328 56.0084 162.633 56.1685C162.943 56.3285 163.317 56.4085 163.757 56.4085C164.16 56.4085 164.514 56.3388 164.819 56.1994C165.129 56.0601 165.387 55.8613 165.594 55.6032C165.806 55.34 165.966 55.0225 166.074 54.6509C166.183 54.274 166.237 53.8508 166.237 53.381L167.043 53.5591C167.043 54.416 166.894 55.1361 166.594 55.7194C166.294 56.3027 165.881 56.744 165.354 57.0434C164.826 57.3428 164.222 57.4925 163.54 57.4925ZM166.354 57.2834V55.2238H166.237V48.9211H167.377V57.2834H166.354Z"
        fill={paletteColorDark}
      />
      <path
        d="M169.388 57.2834V48.9211H170.412V50.9343L170.21 50.671C170.303 50.4232 170.425 50.1961 170.575 49.9896C170.724 49.778 170.887 49.6051 171.063 49.4709C171.28 49.2799 171.533 49.1354 171.823 49.0373C172.112 48.934 172.404 48.8747 172.699 48.8592C172.993 48.8385 173.262 48.8592 173.505 48.9211V49.9896C173.2 49.9122 172.867 49.8942 172.505 49.9354C172.143 49.9767 171.81 50.1213 171.505 50.369C171.226 50.5858 171.016 50.8465 170.877 51.1511C170.737 51.4505 170.644 51.7705 170.598 52.1112C170.551 52.4467 170.528 52.7797 170.528 53.11V57.2834H169.388Z"
        fill={paletteColorDark}
      />
      <path
        d="M178.022 57.4925C177.521 57.4925 177.081 57.4151 176.704 57.2602C176.332 57.1053 176.014 56.8963 175.751 56.633C175.492 56.3698 175.283 56.0704 175.123 55.7349C174.962 55.3942 174.846 55.0354 174.774 54.6586C174.701 54.2766 174.665 53.8972 174.665 53.5204V48.9211H175.813V53.1874C175.813 53.6469 175.857 54.0727 175.944 54.465C176.032 54.8573 176.172 55.2006 176.363 55.4948C176.559 55.7839 176.81 56.0084 177.115 56.1685C177.425 56.3285 177.8 56.4085 178.239 56.4085C178.642 56.4085 178.996 56.3388 179.301 56.1994C179.611 56.0601 179.869 55.8613 180.076 55.6032C180.288 55.34 180.448 55.0225 180.557 54.6509C180.665 54.274 180.72 53.8508 180.72 53.381L181.526 53.5591C181.526 54.416 181.376 55.1361 181.076 55.7194C180.776 56.3027 180.363 56.744 179.836 57.0434C179.309 57.3428 178.704 57.4925 178.022 57.4925ZM180.836 57.2834V55.2238H180.72V48.9211H181.859V57.2834H180.836Z"
        fill={paletteColorDark}
      />
      <path d="M184.026 57.2834V45.9014H185.165V57.2834H184.026Z" fill={paletteColorDark} />
      <path
        d="M187.489 47.3338V46.0563H188.629V47.3338H187.489ZM187.489 57.2834V48.9211H188.629V57.2834H187.489Z"
        fill={paletteColorDark}
      />
      <path
        d="M193.922 57.508C192.94 57.508 192.131 57.2963 191.496 56.8731C190.865 56.4498 190.478 55.8613 190.333 55.1077L191.496 54.9141C191.62 55.389 191.901 55.7684 192.341 56.0523C192.785 56.3311 193.333 56.4704 193.984 56.4704C194.62 56.4704 195.121 56.3388 195.488 56.0755C195.855 55.8071 196.038 55.4432 196.038 54.9838C196.038 54.7257 195.979 54.5166 195.86 54.3566C195.746 54.1914 195.511 54.0392 195.155 53.8998C194.798 53.7604 194.266 53.5952 193.558 53.4043C192.798 53.1978 192.204 52.9913 191.775 52.7848C191.346 52.5783 191.041 52.3409 190.86 52.0725C190.679 51.7989 190.589 51.466 190.589 51.0736C190.589 50.5988 190.723 50.1832 190.992 49.827C191.261 49.4657 191.633 49.187 192.108 48.9908C192.584 48.7895 193.137 48.6888 193.767 48.6888C194.398 48.6888 194.961 48.7921 195.457 48.9986C195.958 49.1999 196.361 49.4838 196.666 49.8503C196.971 50.2168 197.152 50.6426 197.209 51.1278L196.046 51.3369C195.969 50.8465 195.723 50.4594 195.31 50.1755C194.901 49.8864 194.382 49.7367 193.752 49.7264C193.157 49.7109 192.674 49.8245 192.302 50.0671C191.93 50.3045 191.744 50.622 191.744 51.0194C191.744 51.2414 191.811 51.4324 191.945 51.5924C192.08 51.7473 192.323 51.8944 192.674 52.0338C193.031 52.1731 193.537 52.3254 194.193 52.4906C194.963 52.6867 195.568 52.8932 196.007 53.11C196.447 53.3268 196.759 53.5823 196.945 53.8766C197.131 54.1708 197.224 54.5347 197.224 54.9683C197.224 55.7581 196.93 56.3801 196.341 56.8343C195.757 57.2834 194.951 57.508 193.922 57.508Z"
        fill={paletteColorDark}
      />
      <path
        d="M66.4856 15.5624H70.9332L71.5297 19.4104H71.8472C72.9586 16.8329 75.8185 15.166 79.2341 15.166C84.9539 15.166 88.4876 19.4104 88.4876 26.0331C88.4876 32.6558 84.7545 37.0181 79.1954 37.0181C75.5417 37.0181 72.8405 35.2334 71.8492 32.7737H71.5317V40.9779C71.5317 42.2301 70.516 43.2444 69.2621 43.2444H66.4877V15.5624H66.4856ZM71.5297 27.0637C71.5297 30.4361 73.9519 32.8144 77.4469 32.8144C81.2981 32.8144 83.3235 30.0377 83.3235 25.9925C83.3235 21.6688 80.9806 19.4084 77.5263 19.4084C74.072 19.4084 71.5297 21.8274 71.5297 25.159V27.0637Z"
        fill={paletteColorDark}
      />
      <path
        d="M111.182 36.5784H106.773L106.138 32.6918H105.741C104.509 35.3099 101.969 36.9748 98.4331 36.9748C93.7066 36.9748 91.0869 33.8423 91.0869 28.7645V15.517H96.1309V28.0103C96.1309 31.2221 97.7186 32.7304 100.499 32.7304C103.953 32.7304 106.138 30.2321 106.138 26.5833V17.7836C106.138 16.5314 107.153 15.517 108.407 15.517H111.182V36.5784Z"
        fill={paletteColorDark}
      />
      <path
        d="M114.329 7.26592H117.103C118.357 7.26592 119.373 8.28026 119.373 9.53244V19.4464H119.69C120.722 16.9074 123.503 15.1227 127.077 15.1227C132.557 15.1227 136.331 19.2471 136.331 26.0691C136.331 32.891 132.717 36.9748 127.077 36.9748C123.781 36.9748 120.842 35.5071 119.69 32.8503H119.373L118.776 36.5784H114.329V7.26592ZM125.249 32.771C128.585 32.771 131.166 30.5899 131.166 26.0691C131.166 21.3104 128.427 19.3671 125.29 19.3671C121.754 19.3671 119.373 21.7068 119.373 25.1564V27.0997C119.373 30.5899 121.915 32.771 125.251 32.771H125.249Z"
        fill={paletteColorDark}
      />
      <path d="M138.93 7.27302H143.934V36.6219H138.93V7.27302Z" fill={paletteColorDark} />
      <path
        d="M149.584 7.0716C151.086 7.0716 152.303 8.28719 152.303 9.78736V9.765C152.303 11.2652 151.086 12.4808 149.584 12.4808C148.082 12.4808 146.864 11.2652 146.864 9.765V9.78736C146.864 8.28719 148.082 7.0716 149.584 7.0716ZM147.082 15.5624H152.086V36.6217H147.082V15.5624Z"
        fill={paletteColorDark}
      />
      <path
        d="M159.644 15.5624L160.279 19.4491H160.596C162.025 16.713 164.647 15.166 167.904 15.166C172.551 15.166 175.529 18.1806 175.529 23.6934V36.6217H172.793C171.539 36.6217 170.524 35.6074 170.524 34.3552V24.6041C170.524 20.996 168.775 19.4084 165.917 19.4084C162.422 19.4084 160.277 21.9473 160.277 25.7546V36.6217H155.233V15.5624H159.644Z"
        fill={paletteColorDark}
      />
      <path
        d="M178.624 7.27265H181.399C182.653 7.27265 183.668 8.287 183.668 9.53917V24.4067L192.287 15.5622H198.801L190.54 23.8111L199 36.6215H194.343C193.581 36.6215 192.871 36.2393 192.45 35.6051L186.769 27.0229L183.67 30.1553V36.6195H178.626V7.27062L178.624 7.27265Z"
        fill={paletteColorDark}
      />
      <path
        d="M51.6021 21.8516C51.5804 13.7812 44.8749 7.29226 36.737 7.29226H21.1935L25.1746 13.28C25.7206 14.1002 26.6422 14.5934 27.6341 14.5934H36.7856C40.8397 14.5934 44.202 17.8017 44.2398 21.8221C44.2803 25.8801 40.9614 29.1956 36.8775 29.1956H30.399L34.3585 35.1512C34.9153 35.9901 35.8613 36.4967 36.8748 36.4967C45.0074 36.4967 51.621 29.9246 51.5967 21.8543L51.6021 21.8516Z"
        fill={paletteColorDark}
      />
      <path
        d="M5.2866e-05 14.6438C0.0216748 22.7148 6.72718 29.2044 14.8651 29.2044H30.4086L26.4275 23.2161C25.8815 22.3959 24.9599 21.9026 23.968 21.9026H14.8165C10.7624 21.9026 7.40016 18.694 7.36232 14.6732C7.32178 10.6149 10.6407 7.29909 14.7246 7.29909H21.2031L17.2435 1.34295C16.6814 0.50394 15.7354 0 14.7219 0C6.59204 0 -0.0215691 6.57267 5.2866e-05 14.6438Z"
        fill={paletteColorDark}
      />
    </svg>
  );
};
