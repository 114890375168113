import { useTheme } from '@mui/material';

export const TableLight = () => {
  const theme = useTheme();
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.077 32H2.92305C2.10382 32 1.41183 31.7176 0.8471 31.1527C0.282367 30.5878 0 29.8957 0 29.0762V2.92375C0 2.10432 0.282367 1.41217 0.8471 0.847305C1.41183 0.282435 2.10382 0 2.92305 0H29.077C29.8962 0 30.5882 0.282435 31.1529 0.847305C31.7176 1.41217 32 2.10432 32 2.92375V29.0762C32 29.8957 31.7176 30.5878 31.1529 31.1527C30.5882 31.7176 29.8962 32 29.077 32ZM1.6923 9.00987H30.3077V2.92375C30.3077 2.61598 30.1795 2.33386 29.9231 2.0774C29.6667 1.82094 29.3847 1.69271 29.077 1.69271H2.92305C2.61535 1.69271 2.3333 1.82094 2.0769 2.0774C1.8205 2.33386 1.6923 2.61598 1.6923 2.92375V9.00987ZM7.9231 10.7026H1.6923V29.0762C1.6923 29.384 1.8205 29.6661 2.0769 29.9226C2.3333 30.1791 2.61535 30.3073 2.92305 30.3073H7.9231V10.7026ZM24.0769 10.7026V30.3073H29.077C29.3847 30.3073 29.6667 30.1791 29.9231 29.9226C30.1795 29.6661 30.3077 29.384 30.3077 29.0762V10.7026H24.0769ZM22.3846 10.7026H9.6154V30.3073H22.3846V10.7026Z"
        fill={theme.palette.primary.dark}
      />
    </svg>
  );
};
