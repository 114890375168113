import { Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { FC, forwardRef } from 'react';

type TextfieldWithErrorProps = {
  errorMessage?: string;
} & TextFieldProps;

export const TextfieldWithError: FC<TextfieldWithErrorProps> = forwardRef(
  ({ errorMessage, ...restProps }, ref) => (
    <Stack sx={{ width: '100%', flexGrow: 1, gap: 1 }}>
      <TextField ref={ref} error={!!errorMessage} {...restProps} />
      {errorMessage && (
        <Typography color="error" variant="subtitle2">
          {errorMessage}
        </Typography>
      )}
    </Stack>
  )
);
