import {
  Dialog,
  DialogTitle,
  Stack,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { PublinkLogo } from 'pl.curulis/assets';
import { FC } from 'react';
type CheckoutSuccessDialogProps = {
  open: boolean;
  onClose: VoidFunction;
};
const CheckoutSuccessDialog: FC<CheckoutSuccessDialogProps> = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose}>
    <Box sx={{ width: 400, m: 'auto', mt: 4 }}>
      <PublinkLogo />
    </Box>
    <DialogTitle mb={2}>
      <Stack alignItems="center">
        <Typography variant="h5">Gratulacje!</Typography>
        <Typography variant="h5">Wybrany plan został pomyślnie aktywowany.</Typography>
      </Stack>
    </DialogTitle>
    <DialogContent>
      <Typography textAlign="center">
        Fakturę otrzymają Państwo na podany przy rejestracji adres e-mail, termin płatności to 14
        dni.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onClose}>
        Zamknij
      </Button>
    </DialogActions>
  </Dialog>
);

export { CheckoutSuccessDialog };
