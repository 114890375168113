import { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { SummarySection } from './SummarySection/src/SummarySection';
import { CustomerAddressesForm } from 'pl.curulis/modules/Settings/src/components/CustomerAddresses/components/CustomerAddressesForm';
import { CouponForm } from './CouponForm';
import { CheckoutButtons } from './CheckoutButtons';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { useCouponContext } from '../contexts/CouponContext';
import { useDismissibleDialog } from 'pl.curulis/modules/DismissibleDialog';
import { useCurrentProduct } from 'pl.curulis/modules/Product';
import { Navigate } from 'react-router';
import { removeKeysWithFalsyValues } from 'pl.curulis/utils/removeKeysWithFalsyValues';
import { postCheckout, PostCheckoutModel } from '../api/postCheckout';
import { putCustomerAddressesAsyncThunk } from 'pl.curulis/modules/Settings/src/slice/thunks';
import * as amplitude from '@amplitude/analytics-browser';
import { CustomerAddresses, PartialCustomerAddresses } from 'pl.curulis/modules/Settings/models';
import { isEmpty } from 'lodash';
import { PATH_APP } from '../../../../routes/paths';

type CheckoutFormProps = {
  pricePointId: string;
  name: string;
  description: string;
  yearlyNetValue: number;
  yearlyGrossValue: number;
  onClose: VoidFunction;
  onSubmitSuccess: VoidFunction;
};

export const CheckoutForm: FC<CheckoutFormProps> = ({
  pricePointId,
  name,
  description,
  yearlyNetValue,
  yearlyGrossValue,
  onClose,
  onSubmitSuccess,
}) => {
  const dispatch = useDispatch();
  const { couponId, showCouponInput } = useCouponContext();
  const { openDialog } = useDismissibleDialog();
  const product = useCurrentProduct();
  const [addressesFormValues, setAddressesFormValues] = useState<PartialCustomerAddresses>();
  const [isAddressValid, setIsAddressValid] = useState<boolean>(false);

  if (product === 'Unknown') {
    return <Navigate replace to={PATH_APP.core} />;
  }

  const onSubmit = async () => {
    if (!addressesFormValues || !isAddressValid) return;

    const validAddresses = addressesFormValues as CustomerAddresses;
    const nonEmptyShippingAddress =
      validAddresses.shipping && !isEmpty(removeKeysWithFalsyValues(validAddresses.shipping));

    try {
      const postCheckoutBody: PostCheckoutModel = {
        pricePointId,
        ...(showCouponInput && couponId && { couponId }),
        ...(nonEmptyShippingAddress && { shippingAddress: validAddresses.shipping }),
        billingAddress: validAddresses.billing,
      };
      await postCheckout(postCheckoutBody);
      dispatch(putCustomerAddressesAsyncThunk(validAddresses));
      amplitude.track('Subskrypcja rozpoczęta', {
        checkout: postCheckoutBody,
        product,
      });

      onSubmitSuccess();
    } catch {
      openDialog({ title: 'Coś poszło nie tak.', content: 'Spróbuj ponownie później.' });
    }
  };

  return (
    <Stack direction="row" gap={6}>
      <Stack gap={1}>
        <CustomerAddressesForm
          onIsValidChange={setIsAddressValid}
          onValuesChange={setAddressesFormValues}
          isCheckout={true}
        />
        <CouponForm />
      </Stack>
      <Stack justifyContent="space-between" flexShrink={0} maxWidth="50%">
        <SummarySection
          name={name}
          description={description}
          yearlyNetValue={yearlyNetValue}
          yearlyGrossValue={yearlyGrossValue}
        />
        <CheckoutButtons isAddressValid={isAddressValid} onClose={onClose} onSubmit={onSubmit} />
      </Stack>
    </Stack>
  );
};
