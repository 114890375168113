import { SettingsSection } from '../SettingsSection';
import { Notifications } from './components/Notifications';

export const NotificationTabContent = () => (
  <SettingsSection
    title="Powiadomienia mailowe"
    description="pozwalają włączyć i wyłączyć powiadomienia mailowe o zmianach w dokumentach"
  >
    <Notifications />
  </SettingsSection>
);
