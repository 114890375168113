import { selectProductsDefinitions } from 'pl.curulis/modules/Configuration';
import { useSelector } from 'react-redux';
import { profiles } from '../profiles';
import { Profile } from '../models/Profile';
import { Product } from 'pl.curulis/models/enums/Product';

type ProfileExtended = Profile & { appUrl: string; applicationId: string };

const useProductProfile = (product: Product): ProfileExtended => {
  const productDefinition = useSelector(selectProductsDefinitions).find(
    (productDefinition) => productDefinition.product === product
  );

  return {
    appUrl: productDefinition?.url || '',
    applicationId: productDefinition?.applicationId || '',
    ...profiles[product],
  };
};

export { useProductProfile };
