import { Button } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import AddIcon from '@mui/icons-material/Add';

type AddButtonProps = PropsWithChildren & {
  onClick: VoidFunction;
  isDisabled: boolean;
};

export const AddButton: FC<AddButtonProps> = ({ onClick, isDisabled, children }) => (
  <Button
    sx={{ width: '20em', marginBottom: '16px', flexShrink: 0 }}
    startIcon={<AddIcon />}
    disabled={isDisabled}
    onClick={onClick}
    variant="contained"
    size="large"
  >
    {children}
  </Button>
);
