import { FC, ReactNode, useMemo, useState } from 'react';
import { CouponInfo } from '../../../models/CouponInfo';
import { CouponContext, CouponContextType } from './CouponContext';

type CouponProviderProps = {
  children: ReactNode;
};

const CouponProvider: FC<CouponProviderProps> = ({ children }) => {
  const [couponId, setCouponId] = useState('');
  const [couponInfo, setCouponInfo] = useState<CouponInfo>();
  const [showCouponInput, setShowCouponInput] = useState(false);

  const clearCoupon = () => {
    setCouponId('');
    setCouponInfo(undefined);
  };

  const value: CouponContextType = useMemo(
    () => ({
      couponId,
      showCouponInput,
      hasNotClaimedCoupon: showCouponInput && !!couponId && !couponInfo,
      setShowCouponInput,
      setCouponId,
      couponInfo,
      setCouponInfo,
      clearCoupon,
    }),
    [couponId, couponInfo, showCouponInput]
  );

  return <CouponContext.Provider value={value}>{children}</CouponContext.Provider>;
};

export { CouponProvider };
