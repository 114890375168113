import { tom } from 'pl.curulis/modules/Axios';
import { UnitDeclension, UnitDeclensionDto } from '../../models';
import { createEnumMapper } from 'pl.curulis/utils/mapperCreators';
import { unitNamePrefixConsts } from '../consts/unitNamePrefixOptions';
import { decisionMakingAuthorityConsts } from '../consts/decisionMakingAuthorityOptions';
import { executiveAuthorityConsts } from '../consts/executiveAuhorityOptions';

const unitNamePrefixMapper = createEnumMapper(unitNamePrefixConsts, undefined);
const decisionMakingAuthorityMapper = createEnumMapper(decisionMakingAuthorityConsts, undefined);
const executiveAuthorityMapper = createEnumMapper(executiveAuthorityConsts, undefined);

const mapper = (dto: UnitDeclensionDto): UnitDeclension => ({
  ...dto,
  unitNamePrefix: unitNamePrefixMapper(dto.unitNamePrefix),
  decisionMakingAuthority: decisionMakingAuthorityMapper(dto.decisionMakingAuthority),
  executiveAuthority: executiveAuthorityMapper(dto.executiveAuthority),
});

export const getUnitDeclension = async (): Promise<UnitDeclension> => {
  const response = await tom.get<UnitDeclensionDto>(`/settings/declension`);

  return mapper(response.data);
};
