import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { MenuMain } from './MenuMain';
import { NavbarWrapper } from './NavbarWrapper';
import { FeatureFlags } from 'pl.curulis/modules/FeatureFlags';
import { MenuDivider } from './MenuMain/src/MenuDivider';
import { PublinkLogo } from 'pl.curulis/assets';
import { Link } from 'react-router-dom';
import { PATH_APP } from 'pl.curulis/routes/paths';

export type NavbarVariant = 'compact' | 'flyout';

type NavbarProps = {
  variant: NavbarVariant;
  isOpen: boolean;
  onClose: () => void;
};

export const Navbar: FC<NavbarProps> = ({ variant, isOpen, onClose }) => {
  const isFlyout = variant === 'flyout';
  const flyoutLogoStyles = {
    paddingLeft: '24px',
    '& svg': {
      height: 40,
      width: 60,
    },
  };

  return (
    <NavbarWrapper isOpen={isOpen} onClose={onClose} variant={variant}>
      <Stack height="100%">
        <Box
          sx={{
            padding: '16px',
            '& svg': {
              height: 40,
            },
            ...(isFlyout && flyoutLogoStyles),
          }}
        >
          <Link to={PATH_APP.core}>
            <PublinkLogo />
          </Link>
        </Box>
        <MenuDivider />
        <MenuMain variant={variant} />
        <FeatureFlags variant={variant} />
      </Stack>
    </NavbarWrapper>
  );
};
