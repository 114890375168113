import { Typography, Stack } from '@mui/material';
import { FC } from 'react';
import { ProductPlan } from 'pl.curulis/models/Product';

type PriceProps = Pick<ProductPlan, 'pricePointId' | 'yearlyNetValue' | 'monthlyNetValue'>;

export const Price: FC<PriceProps> = ({ yearlyNetValue, monthlyNetValue }) => (
  <Stack marginY={3}>
    <Stack direction="row" gap={0.5}>
      <Typography fontSize="2.25rem" fontWeight="500" lineHeight={1.2}>
        {monthlyNetValue / 100}
      </Typography>
      <Typography alignSelf="flex-end">
        zł netto /msc
      </Typography>
    </Stack>
    <Typography variant="body2">{yearlyNetValue / 100} zł netto /rocznie</Typography>
  </Stack>
);
