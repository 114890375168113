import { Stack } from '@mui/material';
import { FC } from 'react';
import { PriceSummary } from './PriceSummary';
import { ProductInfo } from './ProductInfo';

type SummarySectionProps = {
  name: string;
  description: string;
  yearlyNetValue: number;
  yearlyGrossValue: number;
};

export const SummarySection: FC<SummarySectionProps> = ({
  name,
  description,
  yearlyNetValue,
  yearlyGrossValue,
}) => (
  <Stack spacing={3}>
    <ProductInfo name={name} description={description} />
    <PriceSummary name={name} yearlyNetValue={yearlyNetValue} yearlyGrossValue={yearlyGrossValue} />
  </Stack>
);
