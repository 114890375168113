import { Route } from 'react-router';
import { PATH_APP } from '../paths';
import { SettingsView } from 'pl.curulis/modules/Settings';
import { SettingsAuth } from 'pl.curulis/modules/Settings/src/components/SettingsAuth';
import { DashboardLayoutWrapper } from 'pl.curulis/routes/src/common/DashboardLayoutWrapper';

export const settingsRoutes = (
  <Route path={PATH_APP.settings} element={<DashboardLayoutWrapper />}>
    <Route
      path={PATH_APP.settings}
      element={
        <SettingsAuth>
          <SettingsView />
        </SettingsAuth>
      }
    />
    <Route
      path={`${PATH_APP.settings}/:settingsTab`}
      element={
        <SettingsAuth>
          <SettingsView />
        </SettingsAuth>
      }
    />
  </Route>
);
