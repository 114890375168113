import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from 'pl.curulis/utils/api/requestStatus';
import { claimTrialAsyncThunk, fetchSubscriptionsAsyncThunk } from './thunks';
import { ProductSubscriptionModel } from 'pl.curulis/models/Product/src/ProductSubscriptionModel';

export type SubscriptionsInitialState = {
  products: ProductSubscriptionModel[] | null;
  subscriptionsFetchStatus: RequestStatus;
  trialFetchStatus: RequestStatus;
};

const initialPlanState: SubscriptionsInitialState = {
  subscriptionsFetchStatus: 'idle',
  trialFetchStatus: 'idle',
  products: null,
};

const initialState: SubscriptionsInitialState = initialPlanState;

const slice = createSlice({
  name: 'productsSubscriptions',
  initialState,
  reducers: {
    invalidateProductsSubscriptionsFetch: (state) => {
      state.subscriptionsFetchStatus = 'idle';
    },
    clearCustomerLicense: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchSubscriptionsAsyncThunk.pending, (state) => {
        state.subscriptionsFetchStatus = 'loading';
      })
      .addCase(fetchSubscriptionsAsyncThunk.fulfilled, (state, action) => {
        state.subscriptionsFetchStatus = 'success';
        state.products = action.payload;
      })
      .addCase(fetchSubscriptionsAsyncThunk.rejected, (state) => {
        state.subscriptionsFetchStatus = 'error';
      })
      .addCase(claimTrialAsyncThunk.pending, (state) => {
        state.trialFetchStatus = 'loading';
      })
      .addCase(claimTrialAsyncThunk.fulfilled, (state) => {
        state.trialFetchStatus = 'success';
        state.subscriptionsFetchStatus = 'idle';
      }),
});

const { actions, reducer } = slice;

export { actions, reducer };
