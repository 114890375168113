import { Stack, Button, Alert } from '@mui/material';
import { CheckoutDialog } from 'pl.curulis/modules/Checkout';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Product } from 'pl.curulis/models/enums/Product';
import { ProductPlan } from 'pl.curulis/models/Product';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import {
  selectProductState,
  invalidateLicenseFetch,
} from 'pl.curulis/modules/productsSubscriptions';

const forbiddenStatus = ['NonRenewing', 'Active'];

type PriceCardFooterProps = Pick<
  ProductPlan,
  'name' | 'pricePointId' | 'yearlyNetValue' | 'yearlyGrossValue' | 'description'
> & {
  product: Exclude<Product, 'Unknown'>;
};

export const PriceCardFooter: FC<PriceCardFooterProps> = ({
  name,
  pricePointId,
  yearlyGrossValue,
  yearlyNetValue,
  product,
  description,
}) => {
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const productSelector = useSelector(selectProductState(product));

  const getIsBuyNowVisible = () => {
    if (!productSelector?.subscription) return true;
    if (productSelector.subscription.status) {
      return !forbiddenStatus.includes(productSelector.subscription.status);
    }
    return false;
  };

  const handlePurchase = () => {
    dispatch(invalidateLicenseFetch());
  };

  return (
    <>
      <Stack width="100%" alignItems="center">
        {getIsBuyNowVisible() && (
          <Button
            variant="contained"
            sx={{
              width: '100%',
              height: '48px',
              fontSize: '1rem',
              boxShadow: 'none',
            }}
            onClick={() => setDialogOpen(true)}
          >
            Kup teraz
          </Button>
        )}
        {productSelector?.subscription?.planId === pricePointId && (
          <Alert severity="success">Aktywna</Alert>
        )}
      </Stack>
      <CheckoutDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        pricePointId={pricePointId}
        onCheckoutSuccess={handlePurchase}
        name={name}
        description={description}
        yearlyNetValue={yearlyNetValue}
        yearlyGrossValue={yearlyGrossValue}
      />
    </>
  );
};
