import { Stack, TextField } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';
import { OrgUnit } from 'pl.curulis/models/OrganizationUnit';
import { OutlinedChip } from 'pl.curulis/modules/UserManagement/src/components/cells/base/OutlinedChip';
import { selectUserManagementState } from 'pl.curulis/modules/UserManagement/src/slice/selectors';
import { keyCodes } from 'pl.curulis/utils/keyCodes';
import { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

type DepartmentsEditCellProps = GridRenderCellParams<OrgUnit, any, any, GridTreeNodeWithRender> & {
  handleRenameOrg: (originalOrg: OrgUnit, updatedOrg: OrgUnit) => Promise<void>;
  editedOrganization: OrgUnit | null;
  setEditedOrganization: React.Dispatch<React.SetStateAction<OrgUnit | null>>;
};

export const DepartmentsEditCell: FC<DepartmentsEditCellProps> = ({
  formattedValue,
  row,
  api,
  handleRenameOrg,
  editedOrganization,
  setEditedOrganization,
}) => {
  const { organizationUnits } = useSelector(selectUserManagementState);
  const departments = organizationUnits.filter((department) => department.parentId === row.constId);

  const onSubmitChange = useCallback(
    (isEnter = false) => {
      api.setEditCellValue({
        id: row.constId,
        field: 'name',
        value: row.name,
      });

      if (!isEnter) {
        api.stopCellEditMode({
          id: row.constId,
          field: 'name',
        });
      }

      if (!editedOrganization) return;

      const originalOrg = departments.find((dep) => dep.constId === editedOrganization.constId);

      if (!originalOrg) return;

      handleRenameOrg &&
        handleRenameOrg(originalOrg, {
          ...editedOrganization,
          name: editedOrganization.name,
        });

      setEditedOrganization(null);
    },
    [
      api,
      departments,
      editedOrganization,
      handleRenameOrg,
      row.constId,
      row.name,
      setEditedOrganization,
    ]
  );

  const handleKeydown = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === keyCodes.ENTER) {
        onSubmitChange(true);
      }
      if (ev.key === keyCodes.ESCAPE) {
        setEditedOrganization(null);
      }
    },
    [onSubmitChange, setEditedOrganization]
  );

  const handleOnChange = (input: React.ChangeEvent<HTMLInputElement>, department: OrgUnit) => {
    setEditedOrganization((prevState) => {
      if (!prevState) {
        return {
          ...department,
          name: input.target.value,
        };
      }
      return {
        ...prevState,
        name: input.target.value,
      };
    });
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown, true);

    return () => window.removeEventListener('keydown', handleKeydown, true);
  }, [handleKeydown]);

  return (
    <Stack gap={2} maxWidth="100%">
      <OutlinedChip label={formattedValue} />
      {departments.map((department, index) => {
        if (editedOrganization?.constId === department.constId) {
          return (
            <TextField
              sx={{
                marginTop: index === 0 ? 1 : 0,
              }}
              autoFocus
              key={department.constId}
              value={editedOrganization.name}
              onChange={(input: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChange(input, department)
              }
              onBlur={() => onSubmitChange()}
            />
          );
        }
        return (
          <OutlinedChip
            sx={{
              marginTop: index === 0 ? 1 : 0,
            }}
            key={department.constId}
            label={department.name}
          />
        );
      })}
    </Stack>
  );
};
