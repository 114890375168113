import { FC, PropsWithChildren } from 'react';
import { Box, styled } from '@mui/material';
import { NAVBAR } from '../../config';
import SimpleBarReact from 'simplebar-react';

const SimpleBarReactStyle = styled(SimpleBarReact)({
  height: '100%',
  '& .simplebar-content': {
    height: '100%',
  },
});

export const NavbarCompact: FC<PropsWithChildren> = ({ children }) => (
  <Box
    sx={{
      border: 'none',
      bgcolor: (theme) => theme.palette.background.light,
      transition: (theme) =>
        theme.transitions.create('width', {
          duration: theme.transitions.duration.standard,
        }),
      width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
      height: '100%',
      flexShrink: 0,
    }}
  >
    <SimpleBarReactStyle autoHide={false}>{children}</SimpleBarReactStyle>
  </Box>
);
