import { motion } from 'framer-motion';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import { PageNotFoundIllustration } from 'assets';
import { MotionContainer, varBounce } from 'components/animate';
import { FC } from 'react';
import { Page } from 'pl.curulis/components/Page';
import { useNavigateWithQuery } from 'pl.curulis/modules/Navigation';
import { PATH_APP } from '../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

const Page404: FC = () => {
  const navigateWithQuery = useNavigateWithQuery();
  return (
    <RootStyle title="404 Nie znaleziono">
      <Container>
        <MotionContainer initial="initial">
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                Taka strona nie istnieje
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Przepraszamy, coś poszło nie tak.
            </Typography>

            <motion.div variants={varBounce().in}>
              <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
            </motion.div>

            <Button
              onClick={() => navigateWithQuery(PATH_APP.core)}
              size="large"
              variant="contained"
            >
              Powrót
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
};

export { Page404 };
