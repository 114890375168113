import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOrganizationMembersList } from '../api/getOrganizationMembersList';
import { getOrganizationUnits } from '../api/getOrganizationUnits';

export const getOrganizationMembersListAsyncThunk = createAsyncThunk(
  'organizationMembers/getOrganizationMembersList',
  getOrganizationMembersList
);

export const getOrganizationUnitsAsyncThunk = createAsyncThunk(
  'organizationUnits/getOrganizationUnits',
  (organizationId: string) => getOrganizationUnits(organizationId)
);
