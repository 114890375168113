import { Profile } from '../models/Profile';
import { PlikomatLogoExtended } from 'pl.curulis/assets/PlikomatLogoExtended';

export const plikomatProfile: Profile = {
  appType: 'Desktop',
  downloadLink: '/agent/download',
  helpUrl: 'https://best.wiedza.publink.com/knowledge-base/pobieranie-plikomatu/',
  landingUrl: 'https://best.wiedza.publink.com/',
  logo: null,
  logoExtended: <PlikomatLogoExtended />,
  product: 'plikomat',
};
