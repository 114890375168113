import { FC, ReactNode, useEffect } from 'react';
import { Drawer, useTheme } from '@mui/material';
import { NAVBAR } from '../../config';
import { useLocation } from 'react-router';

type NavbarDrawerFlyoutProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export const NavbarDrawerFlyout: FC<NavbarDrawerFlyoutProps> = ({ children, isOpen, onClose }) => {
  const { pathname } = useLocation();
  const theme = useTheme();

  useEffect(() => {
    if (isOpen) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: NAVBAR.DASHBOARD_WIDTH,
          backgroundColor: theme.palette.background.light,
        },
      }}
    >
      {children}
    </Drawer>
  );
};
