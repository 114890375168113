import { Router } from 'pl.curulis/routes';
import ThemeProvider from 'pl.curulis/theme';
import { ProgressBarStyle } from 'components/ProgressBar';
import MotionLazyContainer from 'components/animate/MotionLazyContainer';
import { GlobalStyles } from '@mui/material';
import { RouterProvider } from 'react-router';
import { ImpersonationIndicator } from './pl.curulis/modules/Impersonation/src/components/ImpersonationIndicator';
import { SetupAxiosInterceptors } from 'pl.curulis/modules/Axios';

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ImpersonationIndicator>
          <SetupAxiosInterceptors />
          <GlobalStyles styles={{ '.grecaptcha-badge': { visibility: 'hidden' } }} />
          <RouterProvider router={Router} />
          <ProgressBarStyle />
        </ImpersonationIndicator>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
