// @mui
import { styled } from '@mui/material/styles';
import {
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItemTextProps,
  ListItemButtonProps,
} from '@mui/material';
import { NAVBAR } from 'config';

// ----------------------------------------------------------------------

interface MenuListItemStyleProps extends ListItemButtonProps {
  active: boolean;
  depth: number;
}

export const MenuListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<MenuListItemStyleProps>(({ active, depth, theme }) => ({
  position: 'relative',
  textTransform: 'capitalize',
  padding: depth === 1 ? theme.spacing(3) : theme.spacing(2),
  marginBottom: theme.spacing(0.5),
  color: theme.palette.primary.dark,
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  // Active item
  ...(active && {
    backgroundColor: theme.palette.background.default,
  }),
  // Active sub item
  ...(active &&
    depth !== 1 && {
      backgroundColor: theme.palette.background.default,
    }),
  //Hover item
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
  // Sub item
  ...(depth && {
    ...(depth > 1 && {
      height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
    }),
    ...(depth > 2 && {
      paddingLeft: theme.spacing(depth),
    }),
  }),
}));

// ----------------------------------------------------------------------

interface MenuListItemTextStyleProps extends ListItemTextProps {
  isCollapse?: boolean;
}

export const MenuListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})<MenuListItemTextStyleProps>(({ isCollapse, theme }) => ({
  transition: theme.transitions.create(['width', 'opacity'], {
    duration: theme.transitions.duration.shorter,
  }),
  ...(isCollapse && {
    width: 0,
    opacity: 0,
  }),
}));

// ----------------------------------------------------------------------

export const MenuListItemIconStyle = styled(ListItemIcon)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': { width: '24px', height: '24px' },
  color: theme.palette.primary.dark,
}));
