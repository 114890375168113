import { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useRwd } from 'pl.curulis/utils/useRwd';
import { Navbar, NavbarVariant } from './Navbar';
import { useQuery } from 'pl.curulis/utils/useQuery';
import { UserInfo } from 'pl.curulis/layouts/UserInfo';

/*
min-width: 0 and/or min-height: 0 are used to override default flex
behavior, which may cause flex child items to expand over their parents.
Here are some more detailed explanations:
https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size
*/

const EMBEDDED_QUERY_KEY = 'embedded';

const MainStyle = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 16,
  minWidth: 0,
  transition: theme.transitions.create('margin-left', {
    duration: theme.transitions.duration.shorter,
  }),
}));

const ExpandMenuButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <IconButton sx={{ mb: 1, alignSelf: 'flex-start' }} onClick={onClick}>
    <MenuIcon />
  </IconButton>
);

export const PanelLayout: FC = () => {
  const [isOpen, setOpen] = useState(false);
  const { isMobile } = useRwd();
  const variant: NavbarVariant = isMobile ? 'flyout' : 'compact';
  const query = useQuery();
  const embedded = !!query.get(EMBEDDED_QUERY_KEY);

  return (
    <Box sx={{ height: 1, width: 1, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexGrow: 1, minHeight: 0 }}>
        {!embedded && <Navbar variant={variant} isOpen={isOpen} onClose={() => setOpen(false)} />}
        <MainStyle sx={{ height: '100%', overflow: 'auto' }}>
          {!embedded && <UserInfo />}
          {variant === 'flyout' && !embedded && <ExpandMenuButton onClick={() => setOpen(true)} />}
          <Outlet />
        </MainStyle>
      </Box>
    </Box>
  );
};
