import { actions } from './reducer';
import { AppDispatch } from 'pl.curulis/redux/store';

const invalidatePlansFetch = () => (dispatch: AppDispatch) => {
  dispatch(actions.invalidatePlansFetch());
};

const { clearPlans } = actions;

export { invalidatePlansFetch, clearPlans };
