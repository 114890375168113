import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { deleteOrganizationMember } from '../api/deleteOrganizationMember';
import { OrganizationMemberModel } from 'pl.curulis/models/OrganizationMember';
import { handleError } from 'pl.curulis/utils/api/handleError';
import { useDismissibleDialog } from 'pl.curulis/modules/DismissibleDialog';
import { invalidateOrganizationMembersFetch } from '../slice/reducer';
import { useCallback } from 'react';

export const useDeleteOrganizationMemberRequest = () => {
  const dispatch = useDispatch();
  const { openDialog, closeDialog } = useDismissibleDialog();

  const executeDeleteOrganizationMember = useCallback(
    async (id: OrganizationMemberModel['userId']) => {
      try {
        await deleteOrganizationMember(id);
        dispatch(invalidateOrganizationMembersFetch());
      } catch (err) {
        const error = handleError(err);
        openDialog({ title: error.message, dismissAction: { title: 'Ok', handler: closeDialog } });
      }
    },
    [dispatch, openDialog, closeDialog]
  );

  return { executeDeleteOrganizationMember };
};
