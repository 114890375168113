export function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const PATH_APP = {
  core: '/',
  products: '/produkt',
  agreements: '/umowy',
  settings: `/ustawienia`,
  users: `/uzytkownicy`,
} as const;

export const PATH_EXTERNAL = {
  terms: 'https://publink.com/regulamin',
  policy: 'https://publink.com/polityka-prywatnosci',
} as const;

export const PATH_PRODUCT = {
  pricing: `${PATH_APP.products}/cennik`,
} as const;

export const PATH_AUTH_ERROR = 'oauth-error';
