import { useContext } from 'react';
import { DismissibleDialogOwnProps } from '../components/DismissibleDialog';
import { DismissibleDialogContext } from './DismissibleDialogContext';

const useDismissibleDialog = (commonProps?: DismissibleDialogOwnProps) => {
  const { closeDialog, openDialog } = useContext(DismissibleDialogContext);

  const openDialogWithCommonProps = (props?: DismissibleDialogOwnProps) =>
    openDialog({ ...commonProps, ...props });

  return { closeDialog, openDialog: openDialogWithCommonProps };
};

export { useDismissibleDialog };
