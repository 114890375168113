import { Page404 } from 'pl.curulis/views/Page404';
import { Route } from 'react-router-dom';
import { NavigateWithQuery } from 'pl.curulis/modules/Navigation';
import { PATH_APP, PATH_AUTH_ERROR } from '../paths';

import { Page500 } from 'pl.curulis/views/Page500';

export const globalRoutes = (
  <>
    <Route path={PATH_AUTH_ERROR} element={<Page500 />} />
    <Route path={'/404'} element={<Page404 />} />
    <Route path={'/403'} element={<NavigateWithQuery hrefWithoutQuery={PATH_APP.products} />} />
    <Route path={'*'} element={<NavigateWithQuery hrefWithoutQuery="/404" replace />} />
  </>
);
