import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectConfig } from 'pl.curulis/modules/Configuration';
import { HubContext } from '../utils/HubContext';

const HUB_NAME = 'Notification';

type HubProviderProps = {
  children: ReactNode;
};

export const HubProvider: FC<HubProviderProps> = ({ children }) => {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const { services } = useSelector(selectConfig);
  const tomUrl = services.tom.baseUrl;
  const connectionRunning = useRef(false);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(tomUrl + '/' + HUB_NAME)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, [tomUrl]);

  useEffect(() => {
    if (connection) {
      (async () => {
        if (!connectionRunning.current) {
          await connection.start();
          connectionRunning.current = true;
        }
      })();
    }

    return () => {
      if (connectionRunning.current) {
        connection?.stop();
        connectionRunning.current = false;
      }
    };
  }, [connection]);

  return <HubContext.Provider value={{ connection }}>{children}</HubContext.Provider>;
};
