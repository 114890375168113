export const settingsUrlsConsts = ['', 'bezpieczenstwo', 'powiadomienia', 'rozliczenia'] as const;

export type SettingsUrlsType = (typeof settingsUrlsConsts)[number];

export const settingsUrlsDict: Record<SettingsUrlsType, string> = {
  '': 'Ogólne',
  bezpieczenstwo: 'Bezpieczeństwo',
  powiadomienia: 'Powiadomienia',
  rozliczenia: 'Rozliczenia',
} as const;
