import { Box, Stack, Tab, Tabs } from '@mui/material';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

type TabsWithContentProps<T extends string> = {
  onChangePath: (tab: string) => string;
  currentTab: T;
  urlsConsts: readonly T[];
  urlsDict: Record<T, string>;
  contentDict: Record<T, ReactNode>;
};

export function TabsWithContent<T extends string>({
  onChangePath,
  urlsConsts,
  currentTab,
  urlsDict,
  contentDict,
}: TabsWithContentProps<T>) {
  const navigate = useNavigate();

  return (
    <Stack height="100%">
      <Tabs
        value={currentTab}
        variant="standard"
        onChange={(_ev, tab) => navigate(onChangePath(tab), { replace: true })}
      >
        {urlsConsts.map((tab) => (
          <Tab label={urlsDict[tab]} value={tab} key={tab} />
        ))}
      </Tabs>
      <Box marginTop={3} flexGrow={1} minHeight={0}>
        {contentDict[currentTab]}
      </Box>
    </Stack>
  );
}
