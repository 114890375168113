import { ButtonProps, Button } from '@mui/material';
import { FC } from 'react';

export type DialogAction = {
  title: string;
  handler: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type DialogButtonProps = Pick<ButtonProps, 'variant' | 'autoFocus'> & {
  action: DialogAction;
};

export const DialogButton: FC<DialogButtonProps> = ({ action, variant, autoFocus }) => {
  const { title, handler } = action;
  return (
    <Button variant={variant} onClick={handler} autoFocus={autoFocus}>
      {title}
    </Button>
  );
};
