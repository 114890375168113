import { useQueryParamApplicationId } from 'pl.curulis/modules/Product';
import { useCampaignDataStorage } from './useCampaignDataStorage';

const useCampaignData = () => {
  const applicationId = useQueryParamApplicationId();
  const { getItem } = useCampaignDataStorage(applicationId!);
  const campaignData = getItem();
  return { campaignData };
};

export { useCampaignData };
