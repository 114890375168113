import { useQueryParamApplicationId } from 'pl.curulis/modules/Product';
import { selectProductsDefinitions } from 'pl.curulis/modules/Configuration';
import { useSelector } from 'react-redux';
import { Product } from 'pl.curulis/models/enums/Product';

const useCurrentProduct = (): Product => {
  const currentApplicationId = useQueryParamApplicationId();
  const currentProduct = useSelector(selectProductsDefinitions).find(
    (product) => product.applicationId === currentApplicationId
  );

  return currentProduct?.product || 'Unknown';
};

export { useCurrentProduct };
