import { WpfLogoExtended } from 'pl.curulis/assets/WpfLogoExtended';
import { Profile } from '../models/Profile';
import { WpfLogo } from 'pl.curulis/assets/WpfLogo';

export const wpfProfile: Profile = {
  appType: 'Desktop',
  downloadLink: 'https://wpf.publink.com/pobierz',
  helpUrl: 'https://wpf.wiedza.publink.com/',
  landingUrl: 'https://publink.com/wpf-asystent',
  logo: <WpfLogo />,
  logoExtended: <WpfLogoExtended />,
  product: 'wpf',
  videoUrl: 'https://www.youtube.com/embed/HdkynUqDFX4',
  videoAnnotation: 'Sprawdź, jak łatwo zainstalować i skonfigurować program WPF Asystent.',
};
