import { Theme } from '@mui/material';

// ----------------------------------------------------------------------

export default function Switch(theme: Theme) {
  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 8,
          '& .MuiSwitch-track': {
            borderRadius: 22 / 2,
            '&::before, &::after': {
              content: '""',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              width: 16,
              height: 16,
            },
            '&::before': {
              left: 12,
            },
            '&::after': {
              right: 12,
            },
          },
          '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 16,
            height: 16,
            margin: 2,
          },
        },
        thumb: {
          boxShadow: theme.customShadows.z1,
        },
        track: {
          opacity: 1,
          backgroundColor: theme.palette.background.light,
        },
        switchBase: {
          left: 0,
          right: 'auto',
          color: theme.palette.primary.dark,
          '&.Mui-checked': {
            color: theme.palette.primary.contrastText,
            '&+.MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.primary.dark,
            },
          },
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: theme.palette.background.mediumContrast,
          },
          '&.Mui-disabled+.MuiSwitch-track': {
            opacity: 1,
            backgroundColor: `${theme.palette.action.disabledBackground} !important`,
          },
        },
      },
    },
  };
}
