import { createSelector } from '@reduxjs/toolkit';
import { Product } from 'pl.curulis/models/enums/Product';
import { Known } from 'pl.curulis/models/utils';
import { RootState } from 'pl.curulis/redux/store';

const selectProductsSubscriptions = (rootState: RootState) => rootState.productsSubscriptions;

const selectProductState = (family: Known<Product>) =>
  createSelector(selectProductsSubscriptions, (state) =>
    state.products?.find((product) => product.product.toLowerCase() === family)
  );

const selectProductSubscription = (family: Known<Product>) =>
  createSelector(selectProductState(family), (family) => family?.subscription);

export { selectProductsSubscriptions, selectProductState, selectProductSubscription };
