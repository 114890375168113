import { Box, Button, Tooltip, Typography } from '@mui/material';
import { FC, useState, type MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser, selectIsImpersonating } from 'pl.curulis/modules/Authentication';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { ImpersonationUserIcon } from 'pl.curulis/assets/ImpersonationUserIcon';
import { ImpersonationPopover } from '../modules/Impersonation/src/components/ImpersonationPopover';

export const UserInfo: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const isImpersonating = useSelector(selectIsImpersonating);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!authenticatedUser) return null;

  return (
    <Box position="absolute" top={16} right={16} zIndex={2}>
      <Tooltip title={authenticatedUser.userEmail}>
        <span>
          <Button
            disabled={authenticatedUser.ssoRole !== 'SA'}
            onClick={handleClick}
            sx={(theme) => ({
              backgroundColor: theme.palette.background.light,
              color: theme.palette.text.primary,
              borderRadius: 3,
              paddingX: 2,
              paddingY: 1,
              '&:hover': {
                backgroundColor: theme.palette.background.light,
              },
              '&:disabled': {
                backgroundColor: theme.palette.background.light,
                color: theme.palette.text.primary,
              },
            })}
            startIcon={
              isImpersonating ? (
                <ImpersonationUserIcon width={20} height={20} />
              ) : (
                <AccountCircleOutlinedIcon />
              )
            }
          >
            <Typography variant="subtitle2" noWrap>
              {authenticatedUser.organizationName}
            </Typography>
          </Button>
        </span>
      </Tooltip>
      {!!anchorEl && <ImpersonationPopover anchorEl={anchorEl} onClose={handleClose} />}
    </Box>
  );
};
