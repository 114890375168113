import { AppDispatch } from 'pl.curulis/redux/store';
import { actions } from './reducer';

const invalidateLicenseFetch = () => (dispatch: AppDispatch) => {
  dispatch(actions.invalidateProductsSubscriptionsFetch());
};

const { clearCustomerLicense } = actions;

export { invalidateLicenseFetch, clearCustomerLicense };
