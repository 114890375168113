import { PublinkLogo } from 'pl.curulis/assets';
import { Profile } from '../models/Profile';
import { PublinkLogoExtendedCurulis } from 'pl.curulis/assets/LogoPublinkExtendedCurulis';

export const defaultProfile: Profile = {
  appType: 'NA',
  helpUrl: 'https://wiedza.publink.com/',
  host: 'publink.com',
  landingUrl: 'https://publink.com',
  logo: <PublinkLogo />,
  logoExtended: <PublinkLogoExtendedCurulis />,
  product: 'Unknown',
};
