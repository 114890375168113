import { createArrayMapper } from 'pl.curulis/utils/mapperCreators';
import { ProductSubscriptionModel } from './ProductSubscriptionModel';

export const mapProductSubscriptionDtoToModel = (
  productSubscriptionDto: ProductSubscriptionModel
): ProductSubscriptionModel => {
  const { subscription } = productSubscriptionDto;

  if (!subscription) return productSubscriptionDto;

  if (subscription.status === 'InTrial')
    return {
      ...productSubscriptionDto,
      subscription: {
        ...subscription,
        end: new Date(subscription.end).toISOString(),
        start: new Date(subscription.start).toISOString(),
      },
    };

  return {
    ...productSubscriptionDto,
    subscription: {
      ...subscription,
      end: new Date(subscription.end).toISOString(),
      start: new Date(subscription.start).toISOString(),
      ...(subscription.nextBillingDate && {
        nextBillingDate: new Date(subscription.nextBillingDate).toISOString(),
      }),
    },
  };
};

export const arrayMapSubscriptionDtoToModel = createArrayMapper(mapProductSubscriptionDtoToModel);
