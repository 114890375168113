import { useContext } from 'react';
import { DismissibleSnackbarProps } from '../components/DismissibleSnackbar';
import { DismissibleSnackbarContext } from './DismissibleSnackbarContext';

const useDismissibleSnackbar = () => {
  const { openSnackbar: open, closeSnackbar, isOpen } = useContext(DismissibleSnackbarContext);

  const openSnackbar = (
    message: DismissibleSnackbarProps['message'],
    options?: Omit<DismissibleSnackbarProps, 'message'>
  ) => {
    if (Boolean(isOpen)) return;

    const handleClose = (callback?: () => void) => () => {
      callback?.();
      closeSnackbar();
    };

    const handleConfirm = () => {
      options?.onConfirm?.();
      closeSnackbar();
    };

    const defaultProps: DismissibleSnackbarProps = {
      open: Boolean(message),
      autoHideDuration: 6000,
      confirmTooltip: 'Zatwierdź',
      dismissTooltip: 'Zamknij',
      severity: 'info',
    };

    open({
      ...defaultProps,
      ...options,
      message,
      onDismiss: handleClose(options?.onDismiss),
      onTimeout: handleClose(options?.onTimeout),
      onConfirm: Boolean(options?.onConfirm) ? handleConfirm : undefined,
    });
  };

  return { openSnackbar, closeSnackbar };
};

export { useDismissibleSnackbar };
