import { FC } from 'react';
import { Button } from '@mui/material';
import MenuPopover from 'components/MenuPopover';
import { useSelector } from 'react-redux';
import { selectServicesUrls } from 'pl.curulis/modules/Configuration';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

type ImpersonationPopoverProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
};
const ImpersonationPopover: FC<ImpersonationPopoverProps> = ({ anchorEl, onClose }) => {
  const { tomUrl } = useSelector(selectServicesUrls);

  const isOpen = Boolean(anchorEl);

  return (
    <MenuPopover open={isOpen} anchorEl={anchorEl} onClose={onClose}>
      <Button href={tomUrl + '/impersonacja'} startIcon={<SupervisorAccountIcon />}>
        Przejdź&nbsp;do&nbsp;Impersonacji
      </Button>
    </MenuPopover>
  );
};

export { ImpersonationPopover };
