import * as yup from 'yup';
import {
  UserType,
  userTypeConsts,
} from 'pl.curulis/models/OrganizationMember/src/OrganizationMemberModel';

export type UserFormData = {
  email: string;
  userType: UserType;
  organizationUnitIds: string[];
};

const userFormSchema = yup.object().shape({
  email: yup.string().required('Adres e-mail jest wymagany.').email('Podaj poprawny adres E-Mail.'),
  userType: yup.string().oneOf([...userTypeConsts], 'Rola użytkownika jest wymagana.'),
  organizationUnitIds: yup.array().when('userType', (userType: UserType, schema) => {
    if (userType === 'OrganizationUnitUser') {
      return schema.min(
        1,
        "Użytkownik o roli 'Księgowy' musi mieć przypisaną co najmniej jedną jednostkę organizacyjną."
      );
    }

    return schema;
  }),
});

export const validateUserData = (userData: UserFormData) => userFormSchema.validate(userData);
