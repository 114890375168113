import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import InputMask from 'react-input-mask';
import { TextfieldWithError } from 'pl.curulis/components/TextFieldWithError';
import { UseFormRegister, FieldErrors, Control, Controller } from 'react-hook-form';
import { CustomerAddresses } from 'pl.curulis/modules/Settings/models';

const getAddressContext = (isNipRequired: boolean) => {
  if (isNipRequired) {
    return 'billing';
  } else {
    return 'shipping';
  }
};

type AddressFormProps = {
  displayNip?: boolean;
  formTitle?: string;
  register: UseFormRegister<CustomerAddresses>;
  errors: FieldErrors<CustomerAddresses>;
  control: Control<CustomerAddresses>;
};

const AddressForm: FC<AddressFormProps> = ({
  displayNip,
  formTitle,
  errors,
  control,
  register,
}) => {
  const registerName = getAddressContext(!!displayNip);
  return (
    <Stack spacing={2}>
      <Stack
        sx={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {formTitle && (
          <Typography variant="subtitle1" mb={1}>
            {formTitle}
          </Typography>
        )}
      </Stack>

      <TextfieldWithError
        {...register(`${registerName}.organizationName`)}
        label="Nazwa jednostki"
        errorMessage={errors[registerName]?.organizationName?.message}
      />

      <TextfieldWithError
        {...register(`${registerName}.address`)}
        label="Ulica i nr"
        errorMessage={errors[registerName]?.address?.message}
      />

      <Stack spacing={1} direction={'row'}>
        <TextfieldWithError
          {...register(`${registerName}.city`)}
          label="Miasto"
          errorMessage={errors[registerName]?.city?.message}
        />
        <Controller
          control={control}
          name={`${registerName}.zip`}
          render={({ field }) => (
            <InputMask
              mask="99-999"
              maskPlaceholder={null}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value ?? ''}
            >
              <TextfieldWithError
                label="Kod pocztowy"
                errorMessage={errors[registerName]?.zip?.message}
              />
            </InputMask>
          )}
        />
      </Stack>

      {displayNip && (
        <Controller
          control={control}
          name="billing.nip"
          render={({ field }) => (
            <InputMask
              mask="9999999999"
              maskPlaceholder={null}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value ?? ''}
            >
              <TextfieldWithError label="Nip" errorMessage={errors.billing?.nip?.message} />
            </InputMask>
          )}
        />
      )}
    </Stack>
  );
};

export { AddressForm };
