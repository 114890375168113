import { reactivateOrganizationMember } from '../api/reactivateOrganizationMember';
import { useCallback } from 'react';
import { useUserExistDialog } from './useUserExistDialog';

export const useReactivateOrganizationMemberRequest = () => {
  const userExistsDialog = useUserExistDialog();
  const executeReactivateOrganizationMember = useCallback(
    async (userId: string) => {
      try {
        return await reactivateOrganizationMember(userId);
      } catch (err) {
        userExistsDialog();
      }
    },
    [userExistsDialog]
  );

  return { executeReactivateOrganizationMember };
};
