import { Route } from 'react-router';
import { PATH_APP, PATH_PRODUCT } from '../paths';
import { DashboardLayoutWrapper } from './common/DashboardLayoutWrapper';
import { PricingView } from 'pl.curulis/modules/Pricing';
import { ProductsView } from 'pl.curulis/pages/Products/src/views';

export const productRoutes = (
  <Route path={PATH_APP.products} element={<DashboardLayoutWrapper />}>
    <Route path={PATH_APP.products} element={<ProductsView />} />
    <Route path={PATH_PRODUCT.pricing} element={<PricingView />} />
  </Route>
);
