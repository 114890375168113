import { TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';

type TextEditCellProps = {
  onChange: (input: string) => void;
  focusOnRender?: boolean;
} & Omit<TextFieldProps, 'onChange'>;

export const TextEditCell: FC<TextEditCellProps> = ({
  value,
  onChange,
  focusOnRender = false,
  ...props
}) => (
  <TextField
    {...props}
    autoFocus={focusOnRender}
    fullWidth
    defaultValue={value}
    onChange={(ev) => onChange(ev.target.value)}
  />
);
