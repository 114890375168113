import { useTheme } from '@mui/material';
import { FC } from 'react';

export const SwbLogoExtended: FC = () => {
  const theme = useTheme();
  const paletteColorDark = theme.palette.primary.dark;
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 476 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M216.5 47.72C221.195 47.72 223.874 45.8541 223.874 42.8895C223.874 39.6345 220.987 39.0541 219.097 38.7016L211.287 37.3955C203.041 36.0272 200.008 31.8394 200.008 26.7186C200.008 19.0063 206.364 14.6733 215.981 14.6733C226.097 14.6733 231.954 19.5039 232.183 26.5735H223.21C223.064 22.9661 220.239 21.3075 215.898 21.3075C211.557 21.3075 209.251 23.1112 209.251 25.8478C209.251 29.3101 212.865 29.8076 214.963 30.1808L222.42 31.3418C230.376 32.6479 233.346 36.8979 233.346 42.309C233.346 49.8761 226.554 54.4371 216.5 54.4371C205.575 54.4371 199.302 49.2334 199.302 41.8114H208.275C208.337 45.5639 211.536 47.72 216.521 47.72H216.5Z"
        fill={paletteColorDark}
      />
      <path
        d="M242.215 15.399L249.589 44.548H250.087L256.672 15.399H267.161L273.746 44.548H274.037L281.411 15.399H290.384L279.749 53.7115H268.325L261.886 26.9466H261.449L255.301 53.7115H243.96L233.117 15.399H242.236H242.215Z"
        fill={paletteColorDark}
      />
      <path
        d="M292.71 0.306152H301.891V22.5308H302.473C304.342 17.9075 309.41 14.6734 315.912 14.6734C325.882 14.6734 332.757 22.1783 332.757 34.5967C332.757 47.0151 326.173 54.4371 315.912 54.4371C309.909 54.4371 304.57 51.7627 302.473 46.9322H301.891L300.811 53.7115H292.71V0.306152ZM312.588 46.7871C318.653 46.7871 323.369 42.8273 323.369 34.5967C323.369 25.9308 318.383 22.4064 312.671 22.4064C306.232 22.4064 301.891 26.6564 301.891 32.9382V36.4833C301.891 42.8273 306.523 46.8078 312.588 46.8078V46.7871Z"
        fill={paletteColorDark}
      />
      <path
        d="M214.257 94.9887L224.954 94.2009V92.107C224.954 87.5667 222.129 85.9703 217.58 85.9703C212.741 85.9703 209.77 88.4996 209.916 92.1692H201.233C201.026 84.1667 207.589 78.8178 217.871 78.8178C229.005 78.8178 234.135 83.2959 234.135 91.3814V108.838C234.135 109.999 234.426 111.512 236.295 111.512C236.732 111.512 237.313 111.45 237.666 111.305V117.732C236.15 118.167 234.405 118.457 232.972 118.457C228.34 118.457 225.598 116.218 225.39 111.678H224.892C223.022 115.783 217.726 118.602 211.806 118.602C204.141 118.602 199.011 114.124 199.011 107.283C199.011 99.4253 204.931 95.6729 214.257 95.0095V94.9887ZM214.984 111.512C220.987 111.512 224.954 107.614 224.954 102.141V99.9851L215.836 100.773C210.414 101.208 208.254 103.219 208.254 106.391C208.254 109.853 210.996 111.512 214.984 111.512Z"
        fill={paletteColorDark}
      />
      <path
        d="M256.485 111.864C261.179 111.864 263.859 109.999 263.859 107.034C263.859 103.779 260.971 103.199 259.081 102.846L251.271 101.54C243.025 100.172 239.992 95.9839 239.992 90.8631C239.992 83.1508 246.348 78.8178 255.966 78.8178C266.081 78.8178 271.939 83.6484 272.167 90.718H263.194C263.049 87.1106 260.224 85.452 255.882 85.452C251.541 85.452 249.236 87.2557 249.236 89.9923C249.236 93.4546 252.85 93.9521 254.948 94.3253L262.405 95.4863C270.36 96.7924 273.33 101.042 273.33 106.453C273.33 114.021 266.538 118.582 256.485 118.582C245.559 118.582 239.286 113.378 239.286 105.956H248.259C248.322 109.708 251.52 111.864 256.506 111.864H256.485Z"
        fill={paletteColorDark}
      />
      <path
        d="M282.325 79.5435L291.796 106.744H292.295L301.414 79.5435H310.678L295.847 119.95C293.603 126.087 289.491 131 280.372 131C278.129 131 276.176 130.648 274.951 130.212V122.77C276.322 123.267 277.63 123.495 278.918 123.495C283.758 123.495 285.503 121.194 286.437 118.437L287.31 115.99L272.209 79.5435H282.325Z"
        fill={paletteColorDark}
      />
      <path
        d="M327.689 111.864C332.383 111.864 335.063 109.999 335.063 107.034C335.063 103.779 332.176 103.199 330.285 102.846L322.475 101.54C314.229 100.172 311.196 95.9839 311.196 90.8631C311.196 83.1508 317.552 78.8178 327.17 78.8178C337.285 78.8178 343.143 83.6484 343.371 90.718H334.398C334.253 87.1106 331.428 85.452 327.087 85.452C322.745 85.452 320.44 87.2557 320.44 89.9923C320.44 93.4546 324.054 93.9521 326.152 94.3253L333.609 95.4863C341.564 96.7924 344.535 101.042 344.535 106.453C344.535 114.021 337.742 118.582 327.689 118.582C316.763 118.582 310.49 113.378 310.49 105.956H319.464C319.526 109.708 322.725 111.864 327.71 111.864H327.689Z"
        fill={paletteColorDark}
      />
      <path
        d="M351.93 79.5435V68.9287L361.049 67.3324V79.5227H369.876V87.0277H361.049V105.355C361.049 109.46 362.648 110.973 365.971 110.973C367.135 110.973 368.796 110.828 369.876 110.538V117.69C367.571 118.271 365.618 118.416 363.292 118.416C353.176 118.416 351.951 111.699 351.951 106.433V87.0277H345.221V79.5227H351.951L351.93 79.5435Z"
        fill={paletteColorDark}
      />
      <path
        d="M370.291 98.8034C370.291 86.6752 378.101 78.8178 389.671 78.8178C401.24 78.8178 408.033 85.7423 408.033 96.129C408.033 97.7875 407.887 99.529 407.596 100.752H379.472C379.327 107.614 383.439 111.429 389.733 111.429C394.718 111.429 398.27 109.128 399.142 105.935H408.261C406.953 113.44 399.662 118.561 389.816 118.561C378.184 118.561 370.291 110.766 370.291 98.7827V98.8034ZM398.852 94.5534C398.789 89.0594 395.3 85.8874 389.463 85.8874C383.626 85.8874 379.784 89.3496 379.555 94.5534H398.852Z"
        fill={paletteColorDark}
      />
      <path
        d="M420.392 79.5435L421.555 86.613H422.137C424.733 81.6374 429.511 78.8178 435.431 78.8178C443.884 78.8178 449.306 84.3118 449.306 94.3253V117.856H440.187V95.9838C440.187 89.4118 437.009 86.5301 431.796 86.5301C425.439 86.5301 421.534 91.1533 421.534 98.0778V117.856H412.354V79.5435H420.392Z"
        fill={paletteColorDark}
      />
      <path
        d="M457.697 79.5435V68.9287L466.816 67.3324V79.5227H475.644V87.0277H466.816V105.355C466.816 109.46 468.415 110.973 471.739 110.973C472.902 110.973 474.564 110.828 475.644 110.538V117.69C473.338 118.271 471.386 118.416 469.059 118.416C458.944 118.416 457.718 111.699 457.718 106.433V87.0277H450.988V79.5227H457.718L457.697 79.5435Z"
        fill={paletteColorDark}
      />
      <path
        d="M160.667 15.233C160.667 8.14267 154.913 2.39993 147.809 2.39993H89.6077C85.6196 2.39993 81.6731 3.33287 78.1212 5.11581L63.2696 12.5171C59.697 14.3 55.7712 15.233 51.7831 15.233H12.8575C5.75367 15.233 0 20.9757 0 28.066V117.877C0 124.967 5.75367 130.71 12.8575 130.71H147.809C154.913 130.71 160.667 124.967 160.667 117.877V15.233Z"
        fill="#FA8544"
      />
      <path
        d="M126.768 92.3972C134.121 92.3972 138.4 95.2168 138.4 101.188C138.4 107.158 134.121 109.999 126.768 109.999H122.053L124.94 115.783C125.564 117.047 126.872 117.856 128.305 117.856C139.854 117.856 147.81 111.699 147.81 101.208C147.81 90.7179 139.854 84.5606 128.305 84.5606H109.32L112.208 90.3448C112.831 91.6094 114.139 92.418 115.572 92.418H126.768V92.3972Z"
        fill="white"
      />
      <path
        d="M104.605 102.141C97.2518 102.141 92.9729 99.3219 92.9729 93.3511C92.9729 87.3803 97.2518 84.54 104.605 84.54H109.32L106.433 78.7558C105.789 77.4705 104.48 76.6826 103.068 76.6826C91.5189 76.6826 83.5635 82.84 83.5635 93.3304C83.5635 103.821 91.5189 109.978 103.068 109.978H122.053L119.166 104.194C118.542 102.929 117.234 102.121 115.801 102.121H104.605V102.141Z"
        fill="white"
      />
    </svg>
  );
};
