import {
  CreateOrganizationMemberRequestPayload,
  createOrganizationMember,
} from '../api/createOrganizationMember';
import { useQueryParamApplicationId } from 'pl.curulis/modules/Product';
import { productConsts } from 'pl.curulis/models/Product/src/enums/Product';
import { UserFormData } from './validate';
import { useCallback } from 'react';
import { useUserExistDialog } from './useUserExistDialog';

export const useCreateOrganizationMemberRequest = () => {
  const applicationId = useQueryParamApplicationId();
  const userExistsDialog = useUserExistDialog();

  const executeCreateOrganizationMember = useCallback(
    async (formContent: UserFormData) => {
      const payload: CreateOrganizationMemberRequestPayload = {
        email: formContent.email,
        applicationId: applicationId ?? null,
        productRoles: productConsts.map((product) => ({
          product,
          role: 'User',
        })),
      };

      try {
        return await createOrganizationMember(payload);
      } catch (err) {
        userExistsDialog();
      }
    },
    [applicationId, userExistsDialog]
  );

  return { executeCreateOrganizationMember };
};
