import axios from 'axios';

const createInstance = () =>
  axios.create({
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    withCredentials: true,
  });

export const bolek = createInstance();
export const tom = createInstance();
