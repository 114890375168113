import { FC } from 'react';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';
import { OrgUnit } from 'pl.curulis/models/OrganizationUnit';
import { StyledChip } from '../base/StyledChip';

export const DepartmentTypeCell: FC<
  GridRenderCellParams<OrgUnit, any, any, GridTreeNodeWithRender>
> = ({ row }) => (
  <StyledChip
    color={row.type === 'JP' ? 'default' : 'success'}
    label={row.type === 'JP' ? 'Dysponent' : 'Jednostka Organizacyjna'}
  />
);
