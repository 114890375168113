import { createContext } from 'react';
import { DismissibleDialogOwnProps } from '../components/DismissibleDialog';

type DismissibleDialogContextType = {
  openDialog: (props: DismissibleDialogOwnProps) => void;
  closeDialog: () => void;
};

const DismissibleDialogContext = createContext<DismissibleDialogContextType>({
  closeDialog: () => null,
  openDialog: () => null,
});

export { DismissibleDialogContext };
