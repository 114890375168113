export const UmowyLogoExtended = () => (
  <svg width="100%" height="100%" viewBox="0 0 1217 360" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_1099_2165)">
<path d="M553.904 41.5485H576.139L579.117 60.8319H580.706C586.264 47.91 600.559 39.5605 617.632 39.5605C646.221 39.5605 663.89 60.8319 663.89 94.031C663.89 127.23 645.228 149.098 617.434 149.098C599.169 149.098 585.669 140.152 580.706 127.827H579.117V180.309H553.904V41.5485ZM579.117 99.1998C579.117 116.098 591.228 128.025 608.699 128.025C627.956 128.025 638.081 114.11 638.081 93.8322C638.081 72.1633 626.368 60.8319 609.096 60.8319C591.823 60.8319 579.117 72.9585 579.117 89.6575V99.1998Z" fill="#1A2569"/>
<path d="M775.002 147.11H752.965L749.788 127.628H747.803C741.649 140.749 728.943 149.098 711.273 149.098C687.648 149.098 674.545 133.393 674.545 107.947V41.5488H699.758V104.17C699.758 120.273 707.7 127.827 721.597 127.827C738.869 127.827 749.788 115.303 749.788 97.0133V41.5488H775.002V147.11Z" fill="#1A2569"/>
<path d="M790.686 0H815.899V61.2296H817.488C822.65 48.5066 836.547 39.5607 854.415 39.5607C881.812 39.5607 900.673 60.2356 900.673 94.4288C900.673 128.622 882.606 149.098 854.415 149.098C837.937 149.098 823.245 141.743 817.488 128.423H815.899L812.922 147.11H790.686V0ZM845.282 128.026C861.959 128.026 874.863 117.092 874.863 94.4288C874.863 70.5731 861.165 60.832 845.481 60.832C827.811 60.832 815.899 72.5611 815.899 89.8564V99.5975C815.899 117.092 828.606 128.026 845.282 128.026Z" fill="#1A2569"/>
<path d="M912.187 0H937.202V147.11H912.187V0Z" fill="#1A2569"/>
<path d="M953.063 0H978.078V28.0304H953.063V0ZM953.063 41.5487H978.078V147.11H953.063V41.5487Z" fill="#1A2569"/>
<path d="M993.938 41.5485H1015.98L1019.15 61.0307H1020.74C1027.89 47.3136 1040.99 39.5605 1057.27 39.5605C1080.5 39.5605 1095.39 54.6691 1095.39 82.302V147.11H1070.37V86.8743C1070.37 68.7838 1061.64 60.8319 1047.34 60.8319C1029.87 60.8319 1019.15 73.5549 1019.15 92.6394V147.11H993.938V41.5485Z" fill="#1A2569"/>
<path d="M1110.85 0H1136.07V85.8805L1179.15 41.5487H1211.71L1170.41 82.8985L1212.7 147.11H1183.32L1151.55 99.0011L1136.07 114.706V147.11H1110.85V0Z" fill="#1A2569"/>
<path d="M653.567 323.819H631.53L628.353 304.337H626.368C620.213 317.458 607.507 325.807 589.838 325.807C566.213 325.807 553.11 310.102 553.11 284.656V218.258H578.323V280.879C578.323 296.982 586.264 304.536 600.162 304.536C617.434 304.536 628.353 292.012 628.353 273.722V218.258H653.567V323.819Z" fill="#1A2569"/>
<path d="M669.251 218.257H691.089L694.266 237.74H696.053C702.803 223.227 715.509 216.27 729.406 216.27C744.693 216.27 755.811 225.017 760.774 238.336H762.759C769.311 223.227 783.009 216.27 797.899 216.27C821.922 216.27 834.429 233.565 834.429 260.005V323.819H809.216V262.589C809.216 245.493 801.87 237.541 788.767 237.541C772.09 237.541 764.546 249.469 764.546 265.969V323.819H739.134V262.192C739.134 245.493 731.987 237.541 718.884 237.541C703.597 237.541 694.464 249.071 694.464 265.571V323.819H669.251V218.257Z" fill="#1A2569"/>
<path d="M899.129 216.27C931.688 216.27 953.328 238.734 953.328 271.138C953.328 303.542 931.688 325.807 899.129 325.807C866.569 325.807 844.929 303.542 844.929 271.138C844.929 238.734 866.569 216.27 899.129 216.27ZM898.93 304.337C916.599 304.337 927.519 291.415 927.519 270.939C927.519 250.463 916.996 237.541 898.93 237.541C880.864 237.541 870.54 250.661 870.54 271.138C870.54 291.614 881.261 304.337 898.93 304.337Z" fill="#1A2569"/>
<path d="M976.953 218.258L997.203 298.572H998.593L1016.66 218.258H1045.45L1063.51 298.572H1064.31L1084.56 218.258H1109.18L1079.99 323.819H1048.62L1030.95 250.065H1029.76L1012.89 323.819H981.718L951.938 218.258H976.953Z" fill="#1A2569"/>
<path d="M1139.18 218.258L1165.18 293.204H1166.57L1191.59 218.258H1217L1176.3 329.584C1170.15 346.482 1158.83 360 1133.82 360C1127.66 360 1122.3 359.006 1118.93 357.814V337.337C1122.7 338.729 1126.27 339.325 1129.84 339.325C1143.15 339.325 1147.91 332.964 1150.49 325.41L1152.87 318.65L1111.38 218.258H1139.18Z" fill="#1A2569"/>
<path d="M441.182 323.797V41.0849C441.182 21.5586 425.387 5.74316 405.887 5.74316H246.069C235.106 5.74316 224.297 8.30544 214.503 13.2091L173.715 33.619C163.921 38.5226 153.112 41.0849 142.149 41.0849H35.2945C15.7943 41.0849 0 56.9004 0 76.4267V323.819C0 343.345 15.7943 359.161 35.2945 359.161H405.887C425.387 359.161 441.182 343.323 441.182 323.797Z" fill="#A971F7"/>
<path d="M348.092 253.687C368.299 253.687 380.056 261.441 380.056 277.919C380.056 294.397 368.299 302.172 348.092 302.172H335.122L343.063 318.098C344.806 321.61 348.379 323.819 352.284 323.819C384.005 323.819 405.843 306.855 405.843 277.919C405.843 248.983 383.983 232.019 352.284 232.019H300.114L308.055 247.944C309.798 251.457 313.371 253.665 317.276 253.665H348.026L348.092 253.687Z" fill="white"/>
<path d="M287.231 280.525C267.025 280.525 255.268 272.772 255.268 256.294C255.268 239.816 267.025 232.041 287.231 232.041H300.202L292.261 216.115C290.496 212.581 286.923 210.372 282.996 210.372C251.297 210.372 229.437 227.336 229.437 256.272C229.437 285.208 251.297 302.172 282.996 302.172H335.166L327.225 286.246C325.482 282.734 321.908 280.525 318.004 280.525H287.253H287.276H287.231Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1099_2165">
<rect width="1217" height="360" fill="white"/>
</clipPath>
</defs>
</svg>
);
