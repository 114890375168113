import { useTheme } from '@mui/material';
import { FC } from 'react';

export const BestLogoExtended: FC = () => {
  const theme = useTheme();
  const paletteColorDark = theme.palette.primary.dark;
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 476 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M201.4 0.377441H210.567V22.5899H211.148C213.014 17.9692 218.075 14.7368 224.566 14.7368C234.521 14.7368 241.386 22.2377 241.386 34.6493C241.386 47.0609 234.812 54.4789 224.566 54.4789C218.573 54.4789 213.243 51.8059 211.148 46.978H210.567L209.489 53.7537H201.4V0.377441ZM221.248 46.833C227.304 46.833 232.012 42.8754 232.012 34.6493C232.012 25.9881 227.034 22.4656 221.331 22.4656C214.902 22.4656 210.567 26.7133 210.567 32.9916V36.5349C210.567 42.8754 215.192 46.8537 221.248 46.8537V46.833Z"
        fill={paletteColorDark}
      />
      <path
        d="M243.667 34.7115C243.667 22.5899 251.465 14.7368 263.017 14.7368C274.569 14.7368 281.35 21.6575 281.35 32.0385C281.35 33.6961 281.205 35.4367 280.915 36.6592H252.834C252.689 43.5177 256.795 47.3303 263.079 47.3303C268.056 47.3303 271.603 45.0303 272.474 41.8393H281.579C280.272 49.3402 272.992 54.4582 263.162 54.4582C251.548 54.4582 243.667 46.6672 243.667 34.6907V34.7115ZM272.184 30.4637C272.121 24.9728 268.637 21.8025 262.809 21.8025C256.982 21.8025 253.145 25.2629 252.917 30.4637H272.184Z"
        fill={paletteColorDark}
      />
      <path
        d="M301.011 47.7654C305.698 47.7654 308.374 45.9006 308.374 42.9375C308.374 39.6844 305.491 39.1042 303.603 38.752L295.805 37.4466C287.572 36.079 284.544 31.8935 284.544 26.7755C284.544 19.0674 290.89 14.7368 300.493 14.7368C310.593 14.7368 316.441 19.5647 316.669 26.6304H307.71C307.565 23.025 304.744 21.3674 300.41 21.3674C296.075 21.3674 293.773 23.1701 293.773 25.9052C293.773 29.3655 297.382 29.8628 299.476 30.2358L306.922 31.3962C314.865 32.7016 317.831 36.9493 317.831 42.3573C317.831 49.9204 311.049 54.4789 301.011 54.4789C290.102 54.4789 283.839 49.278 283.839 41.8601H292.798C292.86 45.6105 296.054 47.7654 301.032 47.7654H301.011Z"
        fill={paletteColorDark}
      />
      <path
        d="M325.235 15.462V4.85305L334.34 3.25757V15.4413H343.154V22.9421H334.34V41.2591C334.34 45.3618 335.936 46.8744 339.255 46.8744C340.416 46.8744 342.075 46.7294 343.154 46.4393V53.5879C340.852 54.168 338.902 54.3131 336.579 54.3131C326.479 54.3131 325.256 47.5996 325.256 42.3366V22.9421H318.536V15.4413H325.256L325.235 15.462Z"
        fill={paletteColorDark}
      />
      <path
        d="M214.694 95.0084L225.375 94.221V92.1282C225.375 87.5904 222.555 85.9949 218.013 85.9949C213.18 85.9949 210.215 88.5228 210.36 92.1904H201.691C201.483 84.1922 208.037 78.8463 218.303 78.8463C229.419 78.8463 234.542 83.322 234.542 91.403V108.85C234.542 110.01 234.832 111.523 236.699 111.523C237.134 111.523 237.715 111.461 238.068 111.315V117.739C236.554 118.174 234.812 118.464 233.381 118.464C228.756 118.464 226.018 116.226 225.811 111.688H225.313C223.446 115.791 218.158 118.609 212.247 118.609C204.594 118.609 199.472 114.133 199.472 107.296C199.472 99.4426 205.382 95.6921 214.694 95.0291V95.0084ZM215.42 111.523C221.414 111.523 225.375 107.627 225.375 102.157V100.002L216.271 100.789C210.858 101.225 208.701 103.234 208.701 106.405C208.701 109.865 211.438 111.523 215.42 111.523Z"
        fill={paletteColorDark}
      />
      <path
        d="M256.857 111.875C261.544 111.875 264.219 110.01 264.219 107.047C264.219 103.794 261.336 103.214 259.449 102.861L251.651 101.556C243.418 100.189 240.39 96.003 240.39 90.885C240.39 83.1769 246.736 78.8463 256.338 78.8463C266.438 78.8463 272.287 83.6742 272.515 90.7399H263.556C263.41 87.1345 260.59 85.4769 256.255 85.4769C251.921 85.4769 249.619 87.2796 249.619 90.0147C249.619 93.475 253.227 93.9723 255.322 94.3453L262.767 95.5057C270.711 96.8111 273.676 101.059 273.676 106.467C273.676 114.03 266.895 118.588 256.857 118.588C245.948 118.588 239.685 113.388 239.685 105.97H248.644C248.706 109.72 251.9 111.875 256.878 111.875H256.857Z"
        fill={paletteColorDark}
      />
      <path
        d="M282.636 79.5715L292.093 106.757H292.591L301.696 79.5715H310.945L296.138 119.956C293.898 126.089 289.791 131 280.687 131C278.447 131 276.497 130.648 275.274 130.213V122.774C276.643 123.271 277.949 123.499 279.235 123.499C284.067 123.499 285.809 121.199 286.743 118.443L287.614 115.998L272.536 79.5715H282.636Z"
        fill={paletteColorDark}
      />
      <path
        d="M327.931 111.875C332.618 111.875 335.293 110.01 335.293 107.047C335.293 103.794 332.411 103.214 330.523 102.861L322.725 101.556C314.492 100.189 311.464 96.003 311.464 90.885C311.464 83.1769 317.81 78.8463 327.413 78.8463C337.513 78.8463 343.361 83.6742 343.589 90.7399H334.63C334.485 87.1345 331.664 85.4769 327.33 85.4769C322.995 85.4769 320.693 87.2796 320.693 90.0147C320.693 93.475 324.302 93.9723 326.396 94.3453L333.842 95.5057C341.785 96.8111 344.751 101.059 344.751 106.467C344.751 114.03 337.969 118.588 327.931 118.588C317.022 118.588 310.759 113.388 310.759 105.97H319.718C319.78 109.72 322.974 111.875 327.952 111.875H327.931Z"
        fill={paletteColorDark}
      />
      <path
        d="M352.155 79.5715V68.9626L361.259 67.3671V79.5508H370.074V87.0516H361.259V105.369C361.259 109.471 362.856 110.984 366.175 110.984C367.336 110.984 368.995 110.839 370.074 110.549V117.697C367.772 118.278 365.822 118.423 363.499 118.423C353.399 118.423 352.176 111.709 352.176 106.446V87.0516H345.456V79.5508H352.176L352.155 79.5715Z"
        fill={paletteColorDark}
      />
      <path
        d="M370.488 98.821C370.488 86.6994 378.286 78.8463 389.838 78.8463C401.39 78.8463 408.172 85.767 408.172 96.148C408.172 97.8056 408.027 99.5462 407.736 100.769H379.655C379.51 107.627 383.616 111.44 389.9 111.44C394.878 111.44 398.424 109.14 399.295 105.949H408.4C407.093 113.45 399.814 118.568 389.983 118.568C378.369 118.568 370.488 110.777 370.488 98.8002V98.821ZM399.005 94.5732C398.943 89.0823 395.458 85.912 389.631 85.912C383.803 85.912 379.966 89.3724 379.738 94.5732H399.005Z"
        fill={paletteColorDark}
      />
      <path
        d="M420.491 79.5715L421.652 86.6372H422.233C424.825 81.6643 429.596 78.8463 435.506 78.8463C443.947 78.8463 449.36 84.3373 449.36 94.3453V117.863H440.256V96.003C440.256 89.4345 437.082 86.5544 431.877 86.5544C425.531 86.5544 421.632 91.1751 421.632 98.0957V117.863H412.465V79.5715H420.491Z"
        fill={paletteColorDark}
      />
      <path
        d="M457.759 79.5715V68.9626L466.864 67.3671V79.5508H475.678V87.0516H466.864V105.369C466.864 109.471 468.461 110.984 471.779 110.984C472.941 110.984 474.6 110.839 475.678 110.549V117.697C473.376 118.278 471.427 118.423 469.104 118.423C459.004 118.423 457.78 111.709 457.78 106.446V87.0516H451.061V79.5508H457.78L457.759 79.5715Z"
        fill={paletteColorDark}
      />
      <path
        d="M160.419 15.2963C160.419 8.20982 154.674 2.47021 147.581 2.47021H89.4696C85.4877 2.47021 81.5472 3.40264 78.0008 5.18461L63.1721 12.5819C59.6049 14.3638 55.6852 15.2963 51.7032 15.2963H12.8377C5.7448 15.2963 0 21.0359 0 28.1223V117.884C0 124.97 5.7448 130.71 12.8377 130.71H147.581C154.674 130.71 160.419 124.97 160.419 117.884V15.2963Z"
        fill="#0FBFF1"
      />
      <path
        d="M126.572 92.4183C133.914 92.4183 138.186 95.2363 138.186 101.204C138.186 107.171 133.914 110.01 126.572 110.01H121.865L124.747 115.791C125.37 117.055 126.676 117.863 128.107 117.863C139.638 117.863 147.581 111.709 147.581 101.225C147.581 90.7399 139.638 84.5859 128.107 84.5859H109.151L112.034 90.367C112.656 91.6309 113.963 92.439 115.394 92.439H126.572V92.4183Z"
        fill="white"
      />
      <path
        d="M104.444 102.157C97.1018 102.157 92.8295 99.339 92.8295 93.3715C92.8295 87.404 97.1018 84.5653 104.444 84.5653H109.151L106.269 78.7842C105.626 77.4995 104.319 76.7122 102.909 76.7122C91.3777 76.7122 83.4346 82.8662 83.4346 93.3508C83.4346 103.835 91.3777 109.989 102.909 109.989H121.865L118.982 104.208C118.36 102.944 117.053 102.136 115.622 102.136H104.444V102.157Z"
        fill="white"
      />
    </svg>
  );
};
