type StorageObject<ItemType> = {
  item: ItemType;
  expiryDate: Date | null;
};

const calculateExpiryDate = (lifespanInDays: number) => {
  const date = new Date();
  date.setDate(date.getDate() + lifespanInDays);
  return date;
};

const useBrowserStorage = <ItemType>(storage: Storage, key: string) => {
  const setItem = (newItem: ItemType | null, lifespanInDays?: number) => {
    const expiryDate = Boolean(lifespanInDays) ? calculateExpiryDate(lifespanInDays!) : null;

    const newStorageObject: StorageObject<ItemType | null> = {
      item: newItem,
      expiryDate,
    };

    const newObjectStringified = JSON.stringify(newStorageObject);
    storage.setItem(key, newObjectStringified);
  };

  const getItem = () => {
    const storageItemRaw = storage.getItem(key);
    if (!storageItemRaw) {
      return null;
    }

    let storageItem: StorageObject<ItemType>;
    try {
      storageItem = JSON.parse(storageItemRaw!) as StorageObject<ItemType>;
    } catch (ex) {
      console.log(ex);
      storage.removeItem(key);
      return null;
    }

    const isExpired = !!storageItem?.expiryDate && new Date(storageItem!.expiryDate!) < new Date();
    if (isExpired) {
      storage.removeItem(key);
      return null;
    }

    return storageItem?.item;
  };

  return { getItem, setItem };
};

export { useBrowserStorage };
