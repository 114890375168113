const productConsts = ['wpf', 'swb', 'best', 'plikomat', 'umowy', 'Unknown'] as const;

type Product = (typeof productConsts)[number];

const productDisplayValueDict: Record<Product, string> = {
  best: 'BEST Asystent',
  swb: 'SWB Asystent',
  wpf: 'WPF Asystent',
  plikomat: 'plikomat',
  umowy: 'Umowy',
  Unknown: 'Nieznany',
};

export { productConsts, productDisplayValueDict };
export type { Product };
