import { OrganizationUnitDto } from './OrganizationUnitDto';

export const orgUnitTypeConsts = [
  'G',
  'M',
  'P',
  'W',
  'Z',
  'JB',
  'ZB',
  'GP',
  'FC',
  'RDW',
  'ORG',
  'SS',
  'WRSJO',
  'JOP',
  'JP',
  'DEP',
] as const;

export type OrgUnitType = (typeof orgUnitTypeConsts)[number];
export type OrgUnit = Omit<OrganizationUnitDto, 'type'> & {
  type?: OrgUnitType;
};
export type OrgUnitCollection = OrgUnit[];
