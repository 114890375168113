import { createArrayMapper } from 'pl.curulis/utils/mapperCreators';
import { OrganizationMemberModel, AccessibleProductsObject } from './OrganizationMemberModel';
import { OrganizationMemberDto, ProductRoleEntity } from './OrganizationMemberDto';

const mapArrayToTreeStructure = (array: ProductRoleEntity[]): AccessibleProductsObject =>
  array.reduce<AccessibleProductsObject>(
    (acc, curr) => ({ ...acc, [curr.product.toLowerCase()]: curr.role }),
    {}
  );

const mapOrganizationMemberDtoToModel = ({
  productRoles,
  createdAt,
  email,
  isDisabled,
  userId,
  organizationUnitIds,
  userType,
}: OrganizationMemberDto): OrganizationMemberModel => ({
  productRoles: mapArrayToTreeStructure(productRoles),
  createdAt: new Date(createdAt).toISOString(),
  email,
  isDisabled,
  userId,
  organizationUnitIds,
  userType,
});

const arrayMapOrganizationMemberDtoToModel = createArrayMapper(mapOrganizationMemberDtoToModel);

export { mapOrganizationMemberDtoToModel, arrayMapOrganizationMemberDtoToModel };
