import {
  OrganizationMemberDto,
  OrganizationMemberModel,
  mapOrganizationMemberDtoToModel,
} from 'pl.curulis/models/OrganizationMember';
import { tom } from 'pl.curulis/modules/Axios';

export const reactivateOrganizationMember = async (id: OrganizationMemberModel['userId']) => {
  const response = await tom.patch<OrganizationMemberDto>(`Users/${id}/reactivate`);
  return mapOrganizationMemberDtoToModel(response.data);
};
