import { ChipProps, useTheme } from '@mui/material';
import { FC } from 'react';
import { StyledChip } from './StyledChip';

export const OutlinedChip: FC<ChipProps> = ({ label, sx, ...restProps }) => {
  const theme = useTheme();

  return (
    <StyledChip
      sx={{
        width: '100%',
        background: theme.palette.background.default,
        padding: '0 16px',
        ...sx,
      }}
      label={label}
      variant="outlined"
      {...restProps}
    />
  );
};
