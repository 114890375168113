import { FC } from 'react';

export const WpfLogo: FC = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 163 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M162.895 116.838V12.9867C162.895 5.81149 157.062 0 149.86 0H90.8507C86.8073 0 82.806 0.944105 79.2048 2.7484L64.1473 10.2383C60.525 12.0426 56.5448 12.9867 52.5014 12.9867H13.0358C5.83349 12.9867 0 18.7982 0 25.9734V116.859C0 124.034 5.83349 129.846 13.0358 129.846H149.86C157.062 129.846 162.895 124.034 162.895 116.859V116.838Z"
      fill="#06C89B"
    />
    <path
      d="M128.526 91.0956C135.981 91.0956 140.32 93.9489 140.32 99.9912C140.32 106.033 135.981 108.908 128.526 108.908H123.746L126.673 114.761C127.305 116.041 128.632 116.859 130.085 116.859C141.794 116.859 149.86 110.628 149.86 100.012C149.86 89.3963 141.794 83.1652 130.085 83.1652H110.836L113.764 89.0186C114.395 90.2984 115.722 91.1166 117.175 91.1166H128.526V91.0956Z"
      fill="white"
    />
    <path
      d="M106.056 100.935C98.6009 100.935 94.2626 98.082 94.2626 92.0398C94.2626 85.9975 98.6009 83.1232 106.056 83.1232H110.836L107.909 77.2698C107.256 75.969 105.93 75.1718 104.498 75.1718C92.7884 75.1718 84.7227 81.4029 84.7227 92.0188C84.7227 102.635 92.7884 108.866 104.498 108.866H123.746L120.819 103.012C120.187 101.733 118.86 100.914 117.407 100.914H106.056V100.935Z"
      fill="white"
    />
  </svg>
);
