import { ConfigModel } from './ConfigModel';

export const nullConfig: ConfigModel = {
  appUrl: '',
  services: {
    tom: {
      baseUrl: '',
    },
    bolek: {
      baseUrl: '',
    },
  },
  products: {
    best: { applicationId: '', url: '' },
    swb: { applicationId: '', url: '' },
    wpf: { applicationId: '', url: '' },
    umowy: { applicationId: '', url: '' },
    plikomat: { applicationId: '', url: '' },
    Unknown: { applicationId: '', url: '' },
  },
  amplitudeKey: '',
};
