import { tom } from 'pl.curulis/modules/Axios';

type FetchCouponValidityResponse = {
  discountType: 'Percentage' | 'Value';
  value: number;
};
const fetchCouponValidity = async (couponId: string) => {
  const response = await tom.get<FetchCouponValidityResponse>(`checkout/coupon/${couponId}`);
  return response.data;
};

export { fetchCouponValidity };
