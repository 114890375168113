import { IconButton, Tooltip } from '@mui/material';
import { GridRenderCellParams } from 'pl.curulis/modules/DataGrid';
import { OrganizationMemberModel } from 'pl.curulis/models/OrganizationMember';
import { FC } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDismissibleDialog } from 'pl.curulis/modules/DismissibleDialog';
import { useDeleteOrganizationMemberRequest } from '../../../utils/useDeleteOrganizationMemberRequest';
import { useSelector } from 'react-redux';
import { RequireAdmin, selectAuthenticatedUser } from 'pl.curulis/modules/Authentication';
import { RowDeletionAllower, RowDeletionRejectionReason } from '../../../utils/RowDeletionAllower';

type ExistingUserActionsCellProps = Pick<GridRenderCellParams<OrganizationMemberModel>, 'row'>;

export const ExistingUserActionsCell: FC<ExistingUserActionsCellProps> = ({ row }) => {
  const { openDialog, closeDialog } = useDismissibleDialog();
  const { executeDeleteOrganizationMember } = useDeleteOrganizationMemberRequest();
  const authUser = useSelector(selectAuthenticatedUser);

  if (!authUser) return null;

  const rowDeletionAllower = new RowDeletionAllower(authUser.userGuid);
  const rowDeletionDisallowedReason = rowDeletionAllower.checkIfRowDeleteDisabled(row);

  const rowDeletionDisallowed = !!rowDeletionDisallowedReason;

  const deleteUser = async () => {
    await executeDeleteOrganizationMember(row.userId);
    closeDialog();
  };

  const handleDeleteUserClick = () => {
    openDialog({
      content: 'Czy chcesz usunąć tego użytkownika?',
      confirmAction: {
        title: 'Potwierdź',
        handler: deleteUser,
      },
      dismissAction: {
        title: 'Anuluj',
        handler: closeDialog,
      },
    });
  };

  return (
    <RequireAdmin>
      <Tooltip
        title={
          rowDeletionDisallowed
            ? rowDeletionDisallowedReasonDisplayDict[rowDeletionDisallowedReason]
            : 'Usuń użytkownika'
        }
      >
        <span>
          <IconButton disabled={rowDeletionDisallowed} onClick={handleDeleteUserClick}>
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </RequireAdmin>
  );
};

const rowDeletionDisallowedReasonDisplayDict: Record<RowDeletionRejectionReason, string> = {
  AttemptingToDeleteAuthenticatedUser: 'Nie można usunąć samego siebie',
  MemberAlreadyDeleted: 'Użytkownik został już usunięty',
};
