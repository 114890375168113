import { AuthenticatedUserModel } from 'pl.curulis/models/AuthenticatedUser';
import { tom } from 'pl.curulis/modules/Axios';

type UserResponseModel = {
  isAuthenticated: boolean;
  claims?: AuthenticatedUserModel & {
    sid: string;
  };
};

export const getUser = async () => {
  const response = await tom.get<UserResponseModel>('auth/user');

  return response.data;
};
