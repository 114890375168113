import { FC, useState } from 'react';
import { flags } from './flags';
import ScienceIcon from '@mui/icons-material/Science';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { selectFeatureFlags, setFeatureFlag } from './slice';
import { useRootSelector } from 'utils/useRootSelector';
import { selectAuthenticatedUser } from 'pl.curulis/modules/Authentication';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { NavbarVariant } from 'pl.curulis/layouts/Menu/Navbar';
import { NavItemComponent } from 'pl.curulis/components/NavItemComponent';

const PUBLINK_DOMAIN = 'publink.com';
const YOPMAIL_DOMAIN = 'yopmail.com';

export const FeatureFlags: FC<{ variant: NavbarVariant }> = ({ variant }) => {
  const authenticatedUser = useRootSelector(selectAuthenticatedUser);
  const featureFlags = useRootSelector(selectFeatureFlags);
  const dispatch = useDispatch();
  const isInternalUser = [PUBLINK_DOMAIN, YOPMAIL_DOMAIN].some((domain) =>
    authenticatedUser?.userEmail.includes(domain)
  );
  const [isOpen, setIsOpen] = useState(false);

  if (!isInternalUser || !flags.length) {
    return null;
  }

  return (
    <>
      <NavItemComponent
        variant={variant}
        item={{
          title: 'Flagi',
          onClick: () => setIsOpen(true),
          icon: <ScienceIcon />,
        }}
      />
      <Dialog open={isOpen}>
        <DialogTitle>Feature flagi</DialogTitle>
        <DialogContent>
          {flags.map((flag) => (
            <FormControlLabel
              key={flag}
              label={flag}
              control={
                <Switch
                  checked={featureFlags[flag]}
                  onChange={(e) =>
                    dispatch(
                      setFeatureFlag({
                        flag,
                        value: e.target.checked,
                      })
                    )
                  }
                />
              }
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setIsOpen(false)}>
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
