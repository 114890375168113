import { createDisplayValueGetter } from 'pl.curulis/utils/mapperCreators';
import { productDisplayValueDict } from 'pl.curulis/models/enums/Product';
import { subscriptionPlanDisplayValueDict } from 'pl.curulis/models/Product/src/enums/SubscriptionPlan';
import { StatusDisplayValueDict } from 'pl.curulis/models/Product/src/enums/SubscriptionStatus';

const getLicenseProductFamilyDisplayValue = createDisplayValueGetter(productDisplayValueDict);
const getLicensePlanDisplayValue = createDisplayValueGetter(subscriptionPlanDisplayValueDict);
const getLicenseStatusDisplayValue = createDisplayValueGetter(StatusDisplayValueDict);

export {
  getLicenseStatusDisplayValue,
  getLicensePlanDisplayValue,
  getLicenseProductFamilyDisplayValue,
};
