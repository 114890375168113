import { createSlice } from '@reduxjs/toolkit';
import { AuthenticatedUserModel } from 'pl.curulis/models/AuthenticatedUser';
import { RequestStatus } from 'pl.curulis/utils/api/requestStatus';
import { getUserThunk } from './actions';

type InitialState = {
  getUserRequestStatus: RequestStatus;
  isAuthenticated: boolean;
  isLoggingOut: boolean;
  authenticatedUser: null | AuthenticatedUserModel;
  sid: null | string;
};

const initialState: InitialState = {
  getUserRequestStatus: 'idle',
  isAuthenticated: false,
  isLoggingOut: false,
  authenticatedUser: null,
  sid: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.isAuthenticated = false;
      state.authenticatedUser = null;
      state.isLoggingOut = true;
    },
    invalidateGetUser: (state) => {
      state.getUserRequestStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserThunk.pending, (state) => {
        state.getUserRequestStatus = 'loading';
      })
      .addCase(getUserThunk.rejected, (state) => {
        state.getUserRequestStatus = 'error';
      })
      .addCase(getUserThunk.fulfilled, (state, action) => {
        const { isAuthenticated, claims } = action.payload;

        state.getUserRequestStatus = 'success';
        state.isAuthenticated = isAuthenticated;

        if (claims) {
          const { sid, ...authenticatedUser } = claims;

          state.sid = sid;
          state.authenticatedUser = authenticatedUser;
        }
      });
  },
});

export const {
  reducer,
  actions: { invalidateGetUser, logoutUser },
} = slice;
