import { Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type SettingsSectionProps = PropsWithChildren<{
  title: string;
  description?: string;
}>;

export const SettingsSection: FC<SettingsSectionProps> = ({ children, title, description }) => (
  <Stack>
    <Stack marginBottom={4} gap={1}>
      <Typography variant="h5">{title}</Typography>
      {description && <Typography>{description}</Typography>}
    </Stack>
    {children}
  </Stack>
);
