import { useSelector } from 'react-redux';
import { selectSettingsState } from '../../../slice/selectors';
import { useEffect } from 'react';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { getCustomerAddressesAsyncThunk } from '../../../slice/thunks';

export const FetchCustomerAddresses = () => {
  const dispatch = useDispatch();
  const { customerAddressesFetchStatus } = useSelector(selectSettingsState);

  useEffect(() => {
    if (customerAddressesFetchStatus === 'idle') {
      dispatch(getCustomerAddressesAsyncThunk());
    }
  });

  return null;
};
