import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import { selectAuthState } from '../slice/selectors';
import { getUserThunk } from '../slice/actions';
import { useAuthMethods } from '../utils/useAuthMethods';

export const CheckAuth = () => {
  const { getUserRequestStatus, isAuthenticated, isLoggingOut } = useSelector(selectAuthState);
  const dispatch = useDispatch();
  const { login, openAuthErrorPage } = useAuthMethods();

  useEffect(() => {
    if (getUserRequestStatus === 'idle') {
      dispatch(getUserThunk());
    }
  }, [getUserRequestStatus, dispatch]);

  useEffect(() => {
    if (getUserRequestStatus === 'success' && !isAuthenticated && !isLoggingOut) {
      login();
    }
  }, [isAuthenticated, isLoggingOut, getUserRequestStatus, login]);

  useEffect(() => {
    if (getUserRequestStatus === 'error') {
      openAuthErrorPage();
    }
  }, [getUserRequestStatus, openAuthErrorPage]);

  return null;
};
