import { FC, ReactNode, useEffect } from 'react';
import { useCampaignDataStorage } from '../hooks/useCampaignDataStorage';
import { useCampaignDataFromUrl } from '../hooks/useCampaignDataFromUrl';
import { useQueryParamApplicationId } from 'pl.curulis/modules/Product';

type CampaignDataStoreProps = {
  children: ReactNode;
};

const CampaignDataStore: FC<CampaignDataStoreProps> = ({ children }) => {
  const campaignData = useCampaignDataFromUrl();
  const applicationId = useQueryParamApplicationId();
  const { setItem } = useCampaignDataStorage(applicationId!);

  useEffect(() => {
    const hasData = Boolean(campaignData) && Boolean(applicationId);
    if (!hasData) return;
    setItem(campaignData, 7);
  }, [applicationId, campaignData, setItem]);

  return <>{children}</>;
};

export { CampaignDataStore };
