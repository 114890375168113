import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'pl.curulis/redux/store';
import { Product } from 'pl.curulis/models/enums/Product';
import { selectPricePoints } from 'pl.curulis/modules/Plans/src/slice/selectors/selectPricePoints';

export const selectProductPricePoints = createSelector(
  [(state: RootState) => selectPricePoints(state), (_, product: Product) => product],
  (pricePointsDict, product) =>
    pricePointsDict.filter((prod) => prod.productFamily === product) ?? []
);
