import { useSelector } from 'react-redux';
import { selectSettingsState } from '../slice/selectors';
import { useEffect } from 'react';
import { useDispatch } from 'pl.curulis/utils/useDispatch';
import {
  getDocumentSettingsAsyncThunk,
  getNotificationsAsyncThunk,
  getUnitDeclensionAsyncThunk,
} from '../slice/thunks';

export const useSettingsFetch = () => {
  const dispatch = useDispatch();
  const { unitDeclensionFetchStatus, documentSettingsFetchStatus, notificationsFetchStatus } =
    useSelector(selectSettingsState);

  useEffect(() => {
    if (unitDeclensionFetchStatus === 'idle') {
      dispatch(getUnitDeclensionAsyncThunk());
    }
  }, [unitDeclensionFetchStatus, dispatch]);

  useEffect(() => {
    if (documentSettingsFetchStatus === 'idle') {
      dispatch(getDocumentSettingsAsyncThunk());
    }
  }, [documentSettingsFetchStatus, dispatch]);

  useEffect(() => {
    if (notificationsFetchStatus === 'idle') {
      dispatch(getNotificationsAsyncThunk());
    }
  }, [notificationsFetchStatus, dispatch]);
};
