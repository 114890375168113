import { Role } from 'pl.curulis/models/enums/Role';
import { Product } from 'pl.curulis/models/enums/Product';
import { Known } from 'pl.curulis/models/utils';

export type AccessibleProductsObject = Partial<Record<Known<Product>, Role>>;

export const userTypeConsts = ['MainUnitUser', 'OrganizationUnitUser'] as const;

export type UserType = (typeof userTypeConsts)[number];

export const userTypeLabels: Record<UserType, string> = {
  MainUnitUser: 'Współpracownik',
  OrganizationUnitUser: 'Jednostka organizacyjna',
};

export type OrganizationMemberModel = {
  createdAt: string;
  email: string;
  isDisabled: boolean;
  userId: string;
  productRoles: AccessibleProductsObject;
  organizationUnitIds: string[];
  userType: UserType;
};
