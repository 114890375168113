import { Card, Typography, Stack, useTheme } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Product } from 'pl.curulis/models/enums/Product';
import { ProductPlan } from 'pl.curulis/models/Product';
import { LicenseDescription } from '../licenseDescriptions';
import { selectProductsSubscriptions } from 'pl.curulis/modules/productsSubscriptions/src/slice/selectors';
import { Price } from './Price';
import { BulletPoints } from './BulletPoints';
import { PriceCardFooter } from './PriceCardFooter';

type PricePointProps = ProductPlan & {
  product: Exclude<Product, 'Unknown'>;
  licenseDescription?: LicenseDescription;
};

export const PricingCard: FC<PricePointProps> = ({
  licenseDescription,
  monthlyNetValue,
  name,
  pricePointId,
  yearlyGrossValue,
  yearlyNetValue,
  product,
  description,
}) => {
  const { isHighlighted, generalInfo, bulletPointsInfo } = licenseDescription ?? {};
  const theme = useTheme();
  const { subscriptionsFetchStatus } = useSelector(selectProductsSubscriptions);

  return (
    <Card
      sx={{
        width: '340px',
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        border: `solid 1px ${theme.palette.border.lighter}`,
        backgroundColor: isHighlighted
          ? theme.palette.background.light
          : theme.palette.background.default,
      }}
    >
      <Stack
        sx={{
          height: '100%',
          justifyContent: 'space-between',
          padding: 2,
          paddingBottom: 3,
        }}
      >
        <Stack marginBottom={4}>
          <Typography fontSize="1.5rem" fontWeight="500" lineHeight="1">
            {name}
          </Typography>
          {generalInfo && (
            <Typography variant="body2" paddingTop={1}>
              {generalInfo}
            </Typography>
          )}
          <Price
            pricePointId={pricePointId}
            yearlyNetValue={yearlyNetValue}
            monthlyNetValue={monthlyNetValue}
          />
          {bulletPointsInfo && <BulletPoints content={bulletPointsInfo} />}
        </Stack>
        {subscriptionsFetchStatus !== 'loading' && (
          <PriceCardFooter
            pricePointId={pricePointId}
            name={name}
            yearlyNetValue={yearlyNetValue}
            yearlyGrossValue={yearlyGrossValue}
            product={product}
            description={description}
          />
        )}
      </Stack>
    </Card>
  );
};
